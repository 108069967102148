import React from "react";

import PropTypes from "prop-types";

import Images from "../../images";

import "./Logo.scss";

const Logo = ({ brand }) => {
  if (brand === "dubidoc") {
    return <img src={Images.logoDubidoc} alt="dubivax" className="logo" />;
  }

  if (brand === "noventi-dubidoc") {
    return <img src={Images.logoNoventiDubidoc} alt="dubivax" className="logo" />;
  }

  if (brand === "dubivax") {
    return (
      <div className="logo">
        <img src={Images.logoDubidocWhite} alt="dubivax" className="logo__image" />
      </div>
    );
  }

  return (
    <div className="logo">
      <img src={Images.logoNoventiWhite} alt="Dubidoc" className="logo__image mh-20" />
      <span className="mx-8">powered by</span>
      <img src={Images.logoDubidocWhite} alt="Dubidoc" className="logo__image" />
    </div>
  );
};

Logo.propTypes = {
  brand: PropTypes.oneOf(["dubidoc", "noventi", "dubivax", "noventi-dubidoc"]),
};

Logo.defaultProps = {
  brand: "noventi",
};

export default Logo;
