import React, { memo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./Panel.scss";

const Panel = (props) => {
  const {
    className,
    children,
    headline,
    small,
    large,
  } = props;

  return (
    <div
      className={classNames("panel", className, { "panel--small": small, "panel--large": large })}
    >
      {headline && (
        <div className="panel-header table-header">
          {headline}
        </div>
      )}
      <div className="panel-body">
        {children}
      </div>
    </div>
  );
};

Panel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  headline: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
};

Panel.defaultProps = {
  className: null,
  children: null,
  headline: null,
  small: false,
  large: false,
};

export default memo(Panel);
