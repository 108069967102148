import React, { useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import Button from "react-bootstrap/Button";

import AppointmentBookingCard from "../../components/AppointmentBookingCard/AppointmentBookingCard";
import { resetBookingData } from "../../state/features/AppointmentBookingSlice";

const AppointmentSuccess = (props) => {
  const {
    client,
    resetBookingDataDispatch,
    dateTime,
    appointmentType,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Row>
      <Col md={{ span: 6, offset: 3 }} className="pt-40">
        <h1 className="mb-24">
          <FormattedMessage id="appointment_booking.headline" />
        </h1>

        <AppointmentBookingCard
          clientName={client.name}
          appointmentDateTime={moment(dateTime)}
          appointmentType={appointmentType}
          success
          className="mb-32"
        />

        <h3 className="mb-16">So geht es weiter</h3>
        <p className="mb-16">
          In Kürze erhalten Sie Ihre Teminbestätigung per E-Mail.
          Bitte überprüfen Sie gegebenenfalls auch den Spam/Junk-Ordner Ihres E-Mail-Postfachs.
        </p>
        <p className="mb-32">
          Wir danken Ihnen für Ihr Vertrauen!
        </p>

        <Button
          variant="secondary"
          onClick={resetBookingDataDispatch}
        >
          Weiteren Termin buchen
        </Button>
      </Col>
    </Row>
  );
};

AppointmentSuccess.propTypes = {
  client: PropTypes.oneOfType([PropTypes.object]),
  resetBookingDataDispatch: PropTypes.func.isRequired,
  dateTime: PropTypes.string.isRequired,
  appointmentType: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

AppointmentSuccess.defaultProps = {
  client: null,
};

const mapStateToProps = ({ appointmentBooking }) => ({
  dateTime: appointmentBooking.dateTime,
  appointmentType: appointmentBooking.appointmentType,
});

const mapDispatch = {
  resetBookingDataDispatch: resetBookingData,
};

export default connect(mapStateToProps, mapDispatch)(AppointmentSuccess);
