import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appointment: null,
  appointmentLoading: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,

  changeStateLoading: false,
  changeStateSuccess: false,
  changeStateFail: false,

  removeLoading: false,
  removeSuccess: false,
  removeFail: false,

  moveLoading: true,
  moveSuccess: false,
  moveFail: false,

  copyLoading: true,
  copySuccess: false,
  copyFail: false,
};

const AppointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    fetch: (state) => ({
      ...state,
      appointmentLoading: true,
      appointment: null,
    }),
    fetch_SUCCESS: (state, { payload }) => ({
      ...state,
      appointmentLoading: false,
      appointment: payload.data.appointment,
    }),
    fetch_FAIL: (state) => ({
      ...state,
      appointmentLoading: false,
      appointment: null,
    }),
    edit: (state) => ({
      ...state,
      editLoading: true,
      editSuccess: false,
      editFail: false,
    }),
    edit_SUCCESS: (state) => ({
      ...state,
      editLoading: false,
      editSuccess: true,
      editFail: false,
    }),
    edit_FAIL: (state) => ({
      ...state,
      editLoading: false,
      editSuccess: false,
      editFail: true,
    }),
    changeState: (state) => ({
      ...state,
      changeStateLoading: true,
      changeStateSuccess: false,
      changeStateFail: false,
    }),
    changeState_SUCCESS: (state, { payload }) => ({
      ...state,
      changeStateLoading: false,
      changeStateSuccess: true,
      changeStateFail: false,
      appointment: payload.data.appointment,
    }),
    changeState_FAIL: (state) => ({
      ...state,
      changeStateLoading: false,
      changeStateSuccess: false,
      changeStateFail: true,
    }),
    remove: (state) => ({
      ...state,
      removeLoading: true,
      removeSuccess: false,
      removeFail: false,
    }),
    remove_SUCCESS: (state) => ({
      ...state,
      removeLoading: false,
      removeSuccess: true,
      removeFail: false,
    }),
    remove_FAIL: (state) => ({
      ...state,
      removeLoading: false,
      removeSuccess: false,
      removeFail: true,
    }),
    move: (state) => ({
      ...state,
      moveLoading: true,
      moveSuccess: false,
      moveFail: false,
    }),
    move_SUCCESS: (state) => ({
      ...state,
      moveLoading: false,
      moveSuccess: true,
      moveFail: false,
    }),
    move_FAIL: (state) => ({
      ...state,
      moveLoading: false,
      moveSuccess: false,
      moveFail: true,
    }),
    copy: (state) => ({
      ...state,
      copyLoading: true,
      copySuccess: false,
      copyFail: false,
    }),
    copy_SUCCESS: (state) => ({
      ...state,
      copyLoading: false,
      copySuccess: true,
      copyFail: false,
    }),
    copy_FAIL: (state) => ({
      ...state,
      copyLoading: false,
      copySuccess: false,
      copyFail: true,
    }),
    reset: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const { reset } = AppointmentSlice.actions;

export default AppointmentSlice.reducer;

export const fetch = (ulid) => ({
  type: "appointment/fetch",
  payload: {
    client: "default",
    request: {
      url: `portal/appointment/details/${ulid}`,
    },
  },
});

export const edit = (ulid, data) => ({
  type: "appointment/edit",
  payload: {
    client: "default",
    request: {
      method: "post",
      data,
      url: ulid ? `portal/appointment/edit/${ulid}` : "portal/appointment/new",
    },
  },
});

export const changeState = (ulid, state) => ({
  type: "appointment/changeState",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        state,
      },
      url: `portal/appointment/state/${ulid}`,
    },
  },
});

export const remove = (ulid) => ({
  type: "appointment/remove",
  payload: {
    client: "default",
    request: {
      method: "delete",
      url: `portal/appointment/remove/${ulid}`,
    },
  },
});

export const move = (ulid, dateTime) => ({
  type: "appointment/move",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        dateTime,
      },
      url: `portal/appointment/move/${ulid}`,
    },
  },
});

export const copy = (ulid, dateTime) => ({
  type: "appointment/copy",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        dateTime,
      },
      url: `portal/appointment/copy/${ulid}`,
    },
  },
});
