import React, { memo } from "react";

import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import "./BreadCrumbs.scss";

// const breadcrumbs = useReactRouterBreadcrumbs();
//
// return (
//   <div className="breadcrumbs">
//     <FormattedMessage id="breadcrumbs.you_are_here" />
//     {breadcrumbs.map(({ match, breadcrumb }, i) => (
//       <React.Fragment key={match.pathname}>
//         <span key={match.pathname}>
//           <NavLink to={match.pathname}>{breadcrumb}</NavLink>
//         </span>
//         {i + 1 < breadcrumbs.length && (
//           <span>&gt;</span>
//         )}
//       </React.Fragment>
//     ))}
//   </div>
// );

const BreadCrumbs = ({ crumbs }) => (
  <div className="breadcrumbs">
    <FormattedMessage id="breadcrumbs.you_are_here" />
    {crumbs.map(({ name, to }, i) => (
      <React.Fragment key={to}>
        <span>
          <NavLink to={to}>
            <FormattedMessage id={name} />
          </NavLink>
        </span>
        {i + 1 < crumbs.length && (
        <span>&gt;</span>
        )}
      </React.Fragment>
    ))}
  </div>
);

BreadCrumbs.propTypes = {
  crumbs: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
};

export default memo(BreadCrumbs);
