import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";
import {
  faPen,
  faPlus,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

import { fetchAll, remove, toggle } from "../../../state/features/AppointmentTypeSlice";
import Dot from "../../../components/Dot/Dot";
import IconButton from "../../../components/IconButton/IconButton";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import ConfirmButton from "../../../components/ConfirmButton/ConfirmButton";

const AppointmentTypes = (props) => {
  const {
    toggleDispatch,
    fetchAllDispatch,
    removeDispatch,
    appointmentTypes,
    appointmentTypesLoading,
  } = props;

  useEffect(() => {
    fetchAllDispatch();
  }, [fetchAllDispatch]);

  const managedAppointmentTypes = [...appointmentTypes]
    .filter(((appointmentType) => appointmentType.managed))
    .sort((a, b) => a.index - b.index);

  const unmanagedAppointmentTypes = [...appointmentTypes]
    .filter(((appointmentType) => !appointmentType.managed))
    .sort((a, b) => a.index - b.index);

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/appointment-type", name: "client_navigation.appointment_types" },
        ]}
      />

      <h3 className="d-flex align-items-center justify-content-between mb-16">
        Terminartenverwaltung
        <IconButton
          size="sm"
          variant="secondary"
          as={NavLink}
          to="/portal/appointment-type/new"
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth />
          Neue Terminart
        </IconButton>
      </h3>

      <table className="table mb-20">
        <thead>
          <tr>
            <th scope="col" className="w-100">Von Ihrer Apotheke angelegte Terminarten</th>
            <th scope="col" className="pe-16">Termindauer</th>
            <th scope="col">buchbar</th>
            <td />
          </tr>
        </thead>
        <tbody>
          {appointmentTypesLoading && (
            <tr>
              <td colSpan={4} className="text-center py-24">
                <FontAwesomeIcon icon={faSpinner} spin />
              </td>
            </tr>
          )}
          {!appointmentTypesLoading && unmanagedAppointmentTypes.length === 0 && (
            <tr>
              <td colSpan={3} className="text-center py-24">
                Es wurde noch keine Terminart angelegt
              </td>
            </tr>
          )}
          {unmanagedAppointmentTypes.map((appointmentType) => (
            <tr key={appointmentType.ulid}>
              <td className="d-flex align-items-center">
                <Dot color={appointmentType.color} className="me-16" />
                <div>
                  <FormattedMessage id={appointmentType.name} />
                  {appointmentType.publicName && (
                    <>
                      <br />
                      <small className="text-muted">{appointmentType.publicName}</small>
                    </>
                  )}
                </div>
              </td>
              <td>{appointmentType.duration} Min.</td>
              <td>
                <Form.Check
                  type="switch"
                  defaultChecked={appointmentType.active}
                  onChange={(event) => {
                    toggleDispatch(appointmentType.ulid, event.target.checked);
                  }}
                />
              </td>
              <td className="text-nowrap">
                <IconButton
                  as={NavLink}
                  size="sm"
                  variant="secondary"
                  to={`/portal/appointment-type/edit/${appointmentType.ulid}`}
                  className="me-8"
                >
                  <FontAwesomeIcon icon={faPen} fixedWidth />
                </IconButton>

                <ConfirmButton
                  onClick={() => {
                    removeDispatch(appointmentType.ulid)
                      .then((action) => {
                        if (action.type.endsWith("SUCCESS")) {
                          fetchAllDispatch();
                        }
                      });
                  }}
                >
                  <IconButton size="sm" variant="danger">
                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                  </IconButton>
                </ConfirmButton>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3} />
          </tr>
        </tfoot>
      </table>

      <table className="table">
        <thead>
          <tr>
            <th scope="col" className="w-100">Von dubidoc bereit gestellte Terminarten (nicht löschbar)</th>
            <th scope="col" className="pe-16">Termindauer</th>
            <th scope="col">buchbar</th>
            <td />
          </tr>
        </thead>
        <tbody>
          {appointmentTypesLoading && (
            <tr>
              <td colSpan={4} className="text-center py-24">
                <FontAwesomeIcon icon={faSpinner} spin />
              </td>
            </tr>
          )}
          {managedAppointmentTypes.map((appointmentType) => (
            <tr key={appointmentType.ulid}>
              <td className="d-flex align-items-center">
                <Dot color={appointmentType.color} className="me-16" />
                <div>
                  <FormattedMessage id={appointmentType.name} />
                  {appointmentType.publicName && (
                    <>
                      <br />
                      <small className="text-muted">{appointmentType.publicName}</small>
                    </>
                  )}
                </div>
              </td>
              <td>{appointmentType.duration} Min.</td>
              <td>
                <Form.Check
                  type="switch"
                  defaultChecked={appointmentType.active}
                  onChange={(event) => {
                    toggleDispatch(appointmentType.ulid, event.target.checked);
                  }}
                />
              </td>
              <td className="text-nowrap">
                <IconButton
                  as={NavLink}
                  size="sm"
                  variant="secondary"
                  to={`/portal/appointment-type/edit/${appointmentType.ulid}`}
                  className="me-8"
                >
                  <FontAwesomeIcon icon={faPen} fixedWidth />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3} />
          </tr>
        </tfoot>
      </table>
    </>
  );
};

AppointmentTypes.propTypes = {
  toggleDispatch: PropTypes.func.isRequired,
  fetchAllDispatch: PropTypes.func.isRequired,
  removeDispatch: PropTypes.func.isRequired,
  appointmentTypes: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  appointmentTypesLoading: PropTypes.bool,
};

AppointmentTypes.defaultProps = {
  appointmentTypes: [],
  appointmentTypesLoading: false,
};

const mapStateToProps = ({ appointmentType }) => ({
  appointmentTypes: appointmentType.appointmentTypes,
  appointmentTypesLoading: appointmentType.loading,
});

const mapDispatch = {
  fetchAllDispatch: fetchAll,
  toggleDispatch: toggle,
  removeDispatch: remove,
};

export default connect(mapStateToProps, mapDispatch)(AppointmentTypes);
