import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faDotCircle } from "@fortawesome/pro-regular-svg-icons";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { fetchDay } from "../../../state/features/AppointmentStatisticSlice";
import IconButton from "../../../components/IconButton/IconButton";
import Dot from "../../../components/Dot/Dot";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

const AppointmentStatistic = (props) => {
  const {
    fetchDayDispatch,
    appointmentStatistics,
    appointmentStatisticsLoading,
  } = props;

  const [date, setDate] = useState(moment());

  useEffect(() => {
    fetchDayDispatch(date.format("YYYY-MM-DD"));
  }, [fetchDayDispatch, date]);

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/appointment-statistics", name: "client_navigation.appointment_statistic" },
        ]}
      />

      <h3 className="mb-16">Termin-Statistik</h3>

      <div className="mb-8 d-flex">
        <IconButton
          variant="white"
          className="me-4"
          onClick={() => {
            setDate(
              moment(date.subtract(1, "day")),
            );
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </IconButton>
        <Button
          variant="white"
          className="me-4"
          style={{ width: "230px" }}
        >
          {date.format("L")}
        </Button>
        <IconButton
          variant="white"
          className="me-8"
          onClick={() => {
            setDate(
              moment(date.add(1, "day")),
            );
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </IconButton>
        <IconButton
          variant="white"
          onClick={() => {
            setDate(moment());
          }}
        >
          <FontAwesomeIcon icon={faDotCircle} />
          Heute
        </IconButton>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Patient</th>
            <th scope="col">Geb.-Datum</th>
            <th scope="col">Handynr.</th>
            <th scope="col">Termin</th>
            <th scope="col">Impfstoff</th>
          </tr>
        </thead>
        <tbody>
          {appointmentStatisticsLoading && (
            <tr>
              <td colSpan={5} className="text-center py-24">
                <FontAwesomeIcon icon={faSpinner} spin />
              </td>
            </tr>
          )}
          {appointmentStatistics.map((appointmentStatistic) => (
            <tr key={appointmentStatistic.ulid}>
              <td>
                {appointmentStatistic.lastname}, {appointmentStatistic.firstname}
              </td>
              <td>
                {moment(appointmentStatistic.birthdate).format("L")}
              </td>
              <td>
                {appointmentStatistic.mobilePhone}
              </td>
              <td>
                {moment(appointmentStatistic.dateTime).format("LT")}
              </td>
              <td className="d-flex align-items-center">
                <Dot color={appointmentStatistic.color} className="me-16" />
                <FormattedMessage id={appointmentStatistic.name} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={5} />
          </tr>
        </tfoot>
      </table>
    </>
  );
};

AppointmentStatistic.propTypes = {
  fetchDayDispatch: PropTypes.func.isRequired,
  appointmentStatistics: PropTypes.oneOfType([PropTypes.array]),
  appointmentStatisticsLoading: PropTypes.bool,
};

AppointmentStatistic.defaultProps = {
  appointmentStatistics: [],
  appointmentStatisticsLoading: false,
};

const mapStateToProps = ({ appointmentStatistic }) => ({
  appointmentStatistics: appointmentStatistic.appointmentStatistics,
  appointmentStatisticsLoading: appointmentStatistic.appointmentStatisticsLoading,
});

const mapDispatch = {
  fetchDayDispatch: fetchDay,
};

export default connect(mapStateToProps, mapDispatch)(AppointmentStatistic);
