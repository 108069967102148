import React, { useEffect } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import Loading from "../../components/Loading/Loading";
import { fetch } from "../../state/features/ClientSlice";
import AppointmentSearch from "./AppointmentSearch";
import AppointmentBooking from "./AppointmentBooking";
import AppointmentConfirm from "./AppointmentConfirm";
import AppointmentSuccess from "./AppointmentSuccess";

const Appointment = (props) => {
  const {
    fetchDispatch,
    client,
    loading,
    appointmentTypes,
    appointmentReserved,
    appointmentBooked,
    appointmentConfirmed,
    appointmentExpired,
  } = props;

  const {
    ulid,
  } = useParams();

  useEffect(() => {
    fetchDispatch(ulid);
  }, [fetchDispatch, ulid]);

  if (loading || client === null) {
    return <Loading />;
  }

  if (appointmentExpired) {
    return (
      <AppointmentSuccess
        client={client}
      />
    );
  }

  if (appointmentConfirmed) {
    return (
      <AppointmentSuccess
        client={client}
      />
    );
  }

  if (appointmentBooked) {
    return (
      <AppointmentConfirm
        client={client}
      />
    );
  }

  if (appointmentReserved) {
    return (
      <AppointmentBooking
        client={client}
      />
    );
  }

  return (
    <AppointmentSearch
      client={client}
      appointmentTypes={appointmentTypes}
    />
  );
};

Appointment.propTypes = {
  fetchDispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  client: PropTypes.oneOfType([PropTypes.object]),
  appointmentTypes: PropTypes.oneOfType([PropTypes.array]),
  appointmentReserved: PropTypes.bool,
  appointmentBooked: PropTypes.bool,
  appointmentConfirmed: PropTypes.bool,
  appointmentExpired: PropTypes.bool,
};

Appointment.defaultProps = {
  loading: false,
  client: null,
  appointmentTypes: [],
  appointmentReserved: false,
  appointmentBooked: false,
  appointmentConfirmed: false,
  appointmentExpired: false,
};

const mapStateToProps = ({ client, appointmentBooking }) => ({
  client: client.client,
  appointmentTypes: client.appointmentTypes,
  loading: client.loading,
  appointmentReserved: appointmentBooking.appointmentReserved,
  appointmentBooked: appointmentBooking.appointmentBooked,
  appointmentConfirmed: appointmentBooking.appointmentConfirmed,
  appointmentExpired: appointmentBooking.appointmentExpired,
});

const mapDispatch = {
  fetchDispatch: fetch,
};

export default connect(mapStateToProps, mapDispatch)(Appointment);
