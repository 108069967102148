import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { Formik } from "formik";
import * as yup from "yup";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";

import Panel from "../../../components/Panel/Panel";
import IconButton from "../../../components/IconButton/IconButton";
import Loading from "../../../components/Loading/Loading";
import {
  checkEmail,
  edit,
  fetch,
  reset,
} from "../../../state/features/UserSlice";
import FormRowText from "../../../components/FormRow/FormRowText";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { notificationService } from "../../../services/NotificationService";

const UserEdit = (props) => {
  const {
    fetchDispatch,
    editDispatch,
    resetDispatch,
    user,
    userLoading,
    editLoading,
    editSuccess,
    editFail,
  } = props;

  const {
    ulid,
  } = useParams();

  const dispatch = useDispatch();

  const intl = useIntl();

  const navigate = useNavigate();

  useEffect(() => {
    resetDispatch();

    if (ulid) {
      fetchDispatch(ulid);
    }
  }, [resetDispatch, fetchDispatch, ulid]);

  if (userLoading) {
    return <Loading />;
  }

  const validationSchema = yup.object().shape({
    email: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .email(intl.formatMessage({ id: "form.error.email" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    firstname: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    lastname: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
  });

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/user", name: "client_navigation.user" },
          user
            ? { to: `/portal/user/edit/${user.ulid}`, name: "client_navigation.user_edit" }
            : { to: "/portal/user/new", name: "client_navigation.user_new" },
        ]}
      />

      <h3 className="mb-16">{user ? "Benutzer bearbeiten" : "Neuer Benutzer"}</h3>

      <Formik
        initialValues={{
          email: (ulid && user?.email) || "",
          firstname: (ulid && user?.firstname) || "",
          lastname: (ulid && user?.lastname) || "",
          password: "",
          passwordRepeat: "",
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur
        validate={async (values) => {
          const errors = {};

          const {
            email,
            password,
            passwordRepeat,
          } = values;

          if (email) {
            const isEmailValid = await new Promise((resolve, reject) => {
              dispatch(checkEmail(email, ulid))
                .then(({ payload: { data: { valid } } }) => {
                  resolve(valid);
                })
                .catch(() => {
                  resolve(true);
                });
            }).then();

            if (!isEmailValid) {
              errors.email = intl.formatMessage({ id: "form.error.email_unique" });
            }
          }

          if (!ulid && password === "") {
            errors.password = intl.formatMessage({ id: "form.error.required" });
          }

          if (password !== "" && /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*\-_"§/()=?;,:.<>'+]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(password) === false) {
            errors.password = intl.formatMessage({ id: "form.error.password_complexity" });
          }

          if (password !== "" && password !== passwordRepeat) {
            errors.passwordRepeat = intl.formatMessage({ id: "form.error.password_repeat" });
          }

          return errors;
        }}
        onSubmit={(values) => {
          editDispatch(ulid, {
            email: values.email,
            firstname: values.firstname,
            lastname: values.lastname,
            password: values.password,
          }).then((action) => {
            if (action.type.endsWith("SUCCESS")) {
              notificationService.success("Benutzer erfolgreich gespeichert.");
              navigate("/portal/user", { replace: true });
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Ihre E-Mail-Adresse ist gleichzeitig Ihr Benutzername
                mit dem Sie sich in die Software einloggen.
              </Col>
              <Col md={9}>
                <Panel headline="Allgemeines">
                  <FormRowText
                    id="email"
                    name="email"
                    label="E-Mailadresse*"
                    className="mb-24"
                    type="email"
                    defaultValue={values.email}
                    errors={errors.email}
                    touched={touched.email}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="firstname"
                    name="firstname"
                    label="Vorname*"
                    className="mb-24"
                    defaultValue={values.firstname}
                    errors={errors.firstname}
                    touched={touched.firstname}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="lastname"
                    name="lastname"
                    label="Nachname*"
                    defaultValue={values.lastname}
                    errors={errors.lastname}
                    touched={touched.lastname}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Bitte wählen Sie ein sicheres Passwort. Das Passwort muss
                mindestens acht Zeichen haben und sollte aus Groß- und
                Kleinbuchstaben, Ziffern und Sonderzeichen bestehen.
              </Col>
              <Col md={9}>
                <Panel headline="Passwort">
                  <FormRowText
                    id="password"
                    name="password"
                    label="Passwort"
                    className="mb-24"
                    type="password"
                    defaultValue={values.password}
                    errors={errors.password}
                    touched={touched.password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="passwordRepeat"
                    name="passwordRepeat"
                    label="Passwort wiederholen"
                    type="password"
                    defaultValue={values.passwordRepeat}
                    errors={errors.passwordRepeat}
                    touched={touched.passwordRepeat}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={{ span: 9, offset: 3 }}>
                <Panel small className="text-end">
                  <IconButton
                    disabled={editLoading}
                    type="submit"
                  >
                    Speichern
                    {editLoading && (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                    {editSuccess && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    {editFail && (
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    )}
                  </IconButton>
                </Panel>
              </Col>
            </Row>

          </form>
        )}
      </Formik>
    </>
  );
};

UserEdit.propTypes = {
  fetchDispatch: PropTypes.func.isRequired,
  editDispatch: PropTypes.func.isRequired,
  resetDispatch: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]),
  userLoading: PropTypes.bool,
  editLoading: PropTypes.bool,
  editSuccess: PropTypes.bool,
  editFail: PropTypes.bool,
};

UserEdit.defaultProps = {
  user: null,
  userLoading: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
  userLoading: user.userLoading,
  editLoading: user.editLoading,
  editSuccess: user.editSuccess,
  editFail: user.editFail,
});

const mapDispatch = {
  fetchDispatch: fetch,
  editDispatch: edit,
  resetDispatch: reset,
};

export default connect(mapStateToProps, mapDispatch)(UserEdit);
