import React, { useEffect } from "react";

import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sum } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCalendarTimes,
  faCalendarCheck,
} from "@fortawesome/pro-regular-svg-icons";

import Panel from "../../../components/Panel/Panel";
import CircleBadge from "../../../components/CircleBadge/CircleBadge";
import { fetch } from "../../../state/features/DashboardSlice";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

const Dashboard = (props) => {
  const {
    fetchDispatch,
    appointmentTypeStatistics,
    appointmentTypeBudgetings,
  } = props;

  useEffect(() => {
    fetchDispatch();
  }, [fetchDispatch]);

  const vaccineInfoBox = (boxProps) => {
    const {
      headline,
      total,
      details,
      remainingVaccine,
      done,
      expired,
    } = boxProps;

    return (
      <Panel headline={headline}>
        <div className="d-flex justify-content-between align-items-center mb-8">
          Termine heute
          <CircleBadge variant="info">{total}</CircleBadge>
        </div>

        {details.map(({ text, count }) => (
          <div className="d-flex justify-content-between align-items-center ps-24 mb-8" key={text}>
            {text}
            <CircleBadge>{count}</CircleBadge>
          </div>
        ))}
        <div className="d-flex justify-content-between align-items-center mb-8">
          Impfstoff noch verfügbar
          <CircleBadge variant="success">{isNaN(remainingVaccine) ? <>&#8734;</> : remainingVaccine}</CircleBadge>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-8">
          Nicht wahrgenommene Termine
          <CircleBadge variant="danger">{expired}</CircleBadge>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          Durchgeführte Termine
          <CircleBadge>{done}</CircleBadge>
        </div>
      </Panel>
    );
  };

  const stats = [];
  const budgetings = [];

  appointmentTypeStatistics.forEach((statistic) => {
    stats[statistic.name] = statistic;
  });

  appointmentTypeBudgetings.forEach(({ appointmentType, count }) => {
    budgetings[appointmentType.name] = appointmentType.appointmentTypeBudgetings.length > 0
      ? appointmentType.appointmentTypeBudgetings[0].amount - count : null;
  });

  if (appointmentTypeStatistics.length === 0) {
    return null;
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
        ]}
      />

      <h1 className="mb-24">Dashboard für {moment().format("dddd")}, den {moment().format("L")}</h1>
      <Row>
        <Col md={4}>
          <h3 className="mb-16">Gesamtübersicht</h3>

          <Panel large className="mb-24">
            <div className="d-flex align-items-center">
              <CircleBadge large variant="info" className="me-24">
                <FontAwesomeIcon icon={faCalendarAlt} />
              </CircleBadge>
              <div>
                <div className="h1">
                  {sum(appointmentTypeStatistics.map(({ total }) => parseInt(total, 10)))}
                </div>
                <div>Termine gesamt heute</div>
              </div>
            </div>
          </Panel>

          <Panel large className="mb-24">
            <div className="d-flex align-items-center">
              <CircleBadge large variant="danger" className="me-24">
                <FontAwesomeIcon icon={faCalendarTimes} />
              </CircleBadge>
              <div>
                <div className="h1">
                  {sum(appointmentTypeStatistics.map(({ expired }) => parseInt(expired, 10)))}
                </div>
                <div>nicht wahrgenommene Termine</div>
              </div>
            </div>
          </Panel>

          <Panel large className="mb-24">
            <div className="d-flex align-items-center">
              <CircleBadge large className="me-24">
                <FontAwesomeIcon icon={faCalendarCheck} />
              </CircleBadge>
              <div>
                <div className="h1">
                  {sum(appointmentTypeStatistics.map(({ done }) => parseInt(done, 10)))}
                </div>
                <div>durchgeführte Termine</div>
              </div>
            </div>
          </Panel>

        </Col>
        <Col md={8}>
          <h3 className="mb-16">Impfstoffübersicht</h3>
          <Row>
            <Col md={6} className="mb-24">
              {vaccineInfoBox({
                headline: "Biontech",
                total: sum([
                  parseInt(stats["appointmenttype.biontec_pfizer_1"].total, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_2"].total, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_booster"].total, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_4"].total, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_omikron_1"].total, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_omikron_4_5"].total, 10),
                ]),
                details: [
                  { text: "davon 1. Impfung", count: stats["appointmenttype.biontec_pfizer_1"].total },
                  { text: "davon 2. Impfung", count: stats["appointmenttype.biontec_pfizer_2"].total },
                  { text: "davon 3. Impfung", count: stats["appointmenttype.biontec_pfizer_booster"].total },
                  { text: "davon 4. Impfung", count: stats["appointmenttype.biontec_pfizer_4"].total },
                  { text: "davon angepasster Impfstoff (BA.1)", count: stats["appointmenttype.biontec_pfizer_omikron_1"].total },
                  { text: "davon angepasster Impfstoff (BA.4-5)", count: stats["appointmenttype.biontec_pfizer_omikron_4_5"].total },
                ],
                remainingVaccine: sum([
                  parseInt(budgetings["appointmenttype.biontec_pfizer_1"], 10),
                  parseInt(budgetings["appointmenttype.biontec_pfizer_2"], 10),
                  parseInt(budgetings["appointmenttype.biontec_pfizer_booster"], 10),
                  parseInt(budgetings["appointmenttype.biontec_pfizer_4"], 10),
                  parseInt(budgetings["appointmenttype.biontec_pfizer_omikron_1"], 10),
                  parseInt(budgetings["appointmenttype.biontec_pfizer_omikron_4_5"], 10),
                ]),
                done: sum([
                  parseInt(stats["appointmenttype.biontec_pfizer_1"].done, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_2"].done, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_booster"].done, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_4"].done, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_omikron_1"].done, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_omikron_4_5"].done, 10),
                ]),
                expired: sum([
                  parseInt(stats["appointmenttype.biontec_pfizer_1"].expired, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_2"].expired, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_booster"].expired, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_4"].expired, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_omikron_1"].expired, 10),
                  parseInt(stats["appointmenttype.biontec_pfizer_omikron_4_5"].expired, 10),
                ]),
              })}
            </Col>
            <Col md={6} className="mb-24">
              {vaccineInfoBox({
                headline: "Moderna",
                total: sum([
                  parseInt(stats["appointmenttype.moderna_1"].total, 10),
                  parseInt(stats["appointmenttype.moderna_2"].total, 10),
                  parseInt(stats["appointmenttype.moderna_booster"].total, 10),
                  parseInt(stats["appointmenttype.moderna_4"].total, 10),
                  parseInt(stats["appointmenttype.moderna_omikron_1"].total, 10),
                ]),
                details: [
                  { text: "davon 1. Impfung", count: stats["appointmenttype.moderna_1"].total },
                  { text: "davon 2. Impfung", count: stats["appointmenttype.moderna_2"].total },
                  { text: "davon 3. Impfung", count: stats["appointmenttype.moderna_booster"].total },
                  { text: "davon 4. Impfung", count: stats["appointmenttype.moderna_4"].total },
                  { text: "davon angepasster Impfstoff (BA.1)", count: stats["appointmenttype.moderna_omikron_1"].total },
                ],
                remainingVaccine: sum([
                  parseInt(budgetings["appointmenttype.moderna_1"], 10),
                  parseInt(budgetings["appointmenttype.moderna_2"], 10),
                  parseInt(budgetings["appointmenttype.moderna_booster"], 10),
                  parseInt(budgetings["appointmenttype.moderna_4"], 10),
                  parseInt(budgetings["appointmenttype.moderna_omikron_1"], 10),
                ]),
                done: sum([
                  parseInt(stats["appointmenttype.moderna_1"].done, 10),
                  parseInt(stats["appointmenttype.moderna_2"].done, 10),
                  parseInt(stats["appointmenttype.moderna_booster"].done, 10),
                  parseInt(stats["appointmenttype.moderna_4"].done, 10),
                  parseInt(stats["appointmenttype.moderna_omikron_1"].done, 10),
                ]),
                expired: sum([
                  parseInt(stats["appointmenttype.moderna_1"].expired, 10),
                  parseInt(stats["appointmenttype.moderna_2"].expired, 10),
                  parseInt(stats["appointmenttype.moderna_booster"].expired, 10),
                  parseInt(stats["appointmenttype.moderna_4"].expired, 10),
                  parseInt(stats["appointmenttype.moderna_omikron_1"].expired, 10),
                ]),
              })}
            </Col>
            <Col md={6} className="mb-24">
              {vaccineInfoBox({
                headline: "Novavax",
                total: sum([
                  parseInt(stats["appointmenttype.astra_zeneca_1"].total, 10),
                  parseInt(stats["appointmenttype.astra_zeneca_2"].total, 10),
                ]),
                details: [
                  { text: "davon 1. Impfung", count: stats["appointmenttype.astra_zeneca_1"].total },
                  { text: "davon 2. Impfung", count: stats["appointmenttype.astra_zeneca_2"].total },
                ],
                remainingVaccine: sum([
                  parseInt(budgetings["appointmenttype.astra_zeneca_1"], 10),
                  parseInt(budgetings["appointmenttype.astra_zeneca_2"], 10),
                ]),
                done: sum([
                  parseInt(stats["appointmenttype.astra_zeneca_1"].done, 10),
                  parseInt(stats["appointmenttype.astra_zeneca_2"].done, 10),
                ]),
                expired: sum([
                  parseInt(stats["appointmenttype.astra_zeneca_1"].expired, 10),
                  parseInt(stats["appointmenttype.astra_zeneca_2"].expired, 10),
                ]),
              })}
            </Col>
            <Col md={6} className="mb-24">
              {vaccineInfoBox({
                headline: "JOHNSON & JOHNSON",
                total: sum([
                  parseInt(stats["appointmenttype.astra_johnson_johnson"].total, 10),
                ]),
                details: [],
                remainingVaccine: sum([
                  parseInt(budgetings["appointmenttype.astra_johnson_johnson"], 10),
                ]),
                done: sum([
                  parseInt(stats["appointmenttype.astra_johnson_johnson"].done, 10),
                ]),
                expired: sum([
                  parseInt(stats["appointmenttype.astra_johnson_johnson"].expired, 10),
                ]),
              })}
            </Col>
            <Col md={6} className="mb-24">
              {vaccineInfoBox({
                headline: "Biontech Kinderimpfung 12-17 Jahre",
                total: sum([
                  parseInt(stats["appointmenttype.biontec_pfizer_children"]?.total || 0, 10),
                ]),
                details: [],
                remainingVaccine: sum([
                  parseInt(budgetings["appointmenttype.biontec_pfizer_children"], 10),
                ]),
                done: sum([
                  parseInt(stats["appointmenttype.biontec_pfizer_children"]?.done || 0, 10),
                ]),
                expired: sum([
                  parseInt(stats["appointmenttype.biontec_pfizer_children"]?.expired || 0, 10),
                ]),
              })}
            </Col>
            <Col md={6} className="mb-24">
              {vaccineInfoBox({
                headline: "Biontech Kinderimpfung 5-11 Jahre",
                total: sum([
                  parseInt(stats["appointmenttype.biontec_pfizer_children_5_11"]?.total || 0, 10),
                ]),
                details: [],
                remainingVaccine: sum([
                  parseInt(budgetings["appointmenttype.biontec_pfizer_children_5_11"], 10),
                ]),
                done: sum([
                  parseInt(stats["appointmenttype.biontec_pfizer_children_5_11"]?.done || 0, 10),
                ]),
                expired: sum([
                  parseInt(stats["appointmenttype.biontec_pfizer_children_5_11"]?.expired || 0, 10),
                ]),
              })}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

Dashboard.propTypes = {
  fetchDispatch: PropTypes.func.isRequired,
  appointmentTypeStatistics: PropTypes.oneOfType([PropTypes.array]),
  appointmentTypeBudgetings: PropTypes.oneOfType([PropTypes.array]),
};

Dashboard.defaultProps = {
  appointmentTypeStatistics: [],
  appointmentTypeBudgetings: [],
};

const mapStateToProps = ({ dashboard }) => ({
  appointmentTypeStatistics: dashboard.appointmentTypeStatistics,
  appointmentTypeBudgetings: dashboard.appointmentTypeBudgetings,
  loading: dashboard.loading,
});

const mapDispatch = {
  fetchDispatch: fetch,
};

export default connect(mapStateToProps, mapDispatch)(Dashboard);
