import React, { memo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import "./IconButton.scss";

const IconButton = (props) => {
  const {
    children,
    className,
    ...otherProps
  } = props;

  return (
    <Button
      className={classNames("icon-btn", className)}
      {...otherProps}
    >
      {React.Children.map(children, (child) => {
        if (child === " " || !child) {
          return null;
        }

        if (typeof child !== "object" || (typeof child === "object" && child.type?.displayName === "MemoizedFormattedMessage")) {
          return <span>{child}</span>;
        }

        return child;
      })}
    </Button>
  );
};

IconButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  className: "",
};

export default memo(IconButton);
