import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import { useIntl } from "react-intl";

import { findSlots } from "../../state/features/AppointmentSearchSlice";
import Loading from "../Loading/Loading";
import IconButton from "../IconButton/IconButton";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import "./AppointmentSlots.scss";

const AppointmentSlots = (props) => {
  const {
    appointmentType,
    findSlotsDispatch,
    searchDay,
    slots,
    slotsLoading,
    onSelect,
  } = props;

  const intl = useIntl();

  const [firstSearchDay, setFirstSearchDay] = useState(searchDay);
  const [short, setShort] = useState(true);

  let hasRederedSlots = false;

  const { width } = useWindowDimensions();

  const showDays = (width >= 768) ? 5 : 3;

  useEffect(() => {
    setFirstSearchDay(searchDay);
  }, [searchDay]);

  useEffect(() => {
    findSlotsDispatch(
      appointmentType.ulid,
      firstSearchDay.format("YYYY-MM-DD"),
    );
  }, [appointmentType.ulid, findSlotsDispatch, firstSearchDay]);

  useEffect(() => {
    if (
      firstSearchDay === searchDay
      && typeof slots === "object"
      && Object.keys(slots)[0]
      && Object.keys(slots)[0] !== firstSearchDay.format("YYYYMMDD")
    ) {
      setFirstSearchDay(moment(Object.keys(slots)[0], "YYYYMMDD").set({ hour: 0, minute: 0, second: 0 }));
    }
  }, [slots]); // eslint-disable-line react-hooks/exhaustive-deps

  if (slotsLoading) {
    return <div className="mt-32"><Loading /></div>;
  }

  const days = [firstSearchDay.clone()];

  for (let i = 1; i < showDays; i += 1) {
    const day = firstSearchDay.clone().add(i, "days");
    days.push(day);
  }

  const daySlots = days.map((day) => (
    <div className="appointment-slots__day" key={day.format("YYYYMMDD")}>
      <div className="appointment-slots__header">
        {day.format("dd")}.<br />
        <span>{day.format("DD.MM.")}</span>
      </div>
      <div className="appointment-slots__slots">
        {slots[day.format("YYYYMMDD")]?.map((slot, index) => {
          if (short && index > 5) {
            return null;
          }

          hasRederedSlots = true;

          return (
            <Button
              size="sm"
              variant="secondary"
              key={index.toString()}
              className="my-4"
              onClick={() => {
                onSelect(moment(slot));
              }}
            >
              {moment(slot).format("HH:mm")}
            </Button>
          );
        })}
      </div>
    </div>
  ));

  return (
    <>
      <div className="appointment-slots mt-32">
        <div className="appointment-slots__navigation">
          <div className="appointment-slots__header">
            <Button
              variant="link"
              onClick={() => {
                setFirstSearchDay(firstSearchDay.clone().subtract(showDays, "days"));
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
          </div>
        </div>
        {daySlots}
        <div className="appointment-slots__navigation">
          <div className="appointment-slots__header">
            <Button
              variant="link"
              onClick={() => {
                setFirstSearchDay(firstSearchDay.clone().add(showDays, "days"));
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
        </div>
      </div>
      {!hasRederedSlots ? (
        <div className="text-center">Keine Termine gefunden.</div>
      ) : (
        <div className="appointment-slots__more mt-8">
          <IconButton
            variant="link"
            onClick={() => {
              setShort(!short);
            }}
          >
            {short
              ? intl.formatMessage({ id: "appointment_slots.more_appointments" })
              : intl.formatMessage({ id: "appointment_slots.less_appointments" })}
            <FontAwesomeIcon icon={short ? faChevronDown : faChevronUp} />
          </IconButton>
        </div>
      )}
    </>
  );
};

AppointmentSlots.propTypes = {
  appointmentType: PropTypes.oneOfType([PropTypes.object]).isRequired,
  searchDay: PropTypes.oneOfType([PropTypes.object]).isRequired,
  findSlotsDispatch: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  slots: PropTypes.oneOfType([PropTypes.array]),
  slotsLoading: PropTypes.bool,
};

AppointmentSlots.defaultProps = {
  slots: [],
  slotsLoading: false,
  onSelect: () => {},
};

const mapStateToProps = ({ appointmentSearch }) => ({
  slots: appointmentSearch.slots,
  slotsLoading: appointmentSearch.loading,
});

const mapDispatch = {
  findSlotsDispatch: findSlots,
};

export default connect(mapStateToProps, mapDispatch)(AppointmentSlots);
