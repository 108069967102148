import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { Formik } from "formik";
import * as yup from "yup";
import { useIntl } from "react-intl";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import Panel from "../../../components/Panel/Panel";
import IconButton from "../../../components/IconButton/IconButton";
import { wizard, reset } from "../../../state/features/BlockingTimeSlice";
import FormRowText from "../../../components/FormRow/FormRowText";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import FormRowTime from "../../../components/FormRow/FormRowTime";
import FormRowDate from "../../../components/FormRow/FormRowDate";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { notificationService } from "../../../services/NotificationService";

const BlockingTimeWizard = (props) => {
  const {
    wizardDispatch,
    resetDispatch,
    wizardLoading,
    wizardSuccess,
    wizardFail,
  } = props;

  useEffect(() => {
    resetDispatch();
  }, [resetDispatch]);

  const intl = useIntl();

  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    title: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    dateFrom: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    dateTo: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    days: yup.array()
      .min(1, intl.formatMessage({ id: "form.error.required" })),
    startTime: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    endTime: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
  });

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/working-time", name: "client_navigation.working_time_calendar" },
          { to: "/portal/blocking-time/wizard", name: "client_navigation.blocking_time_new" },
        ]}
      />

      <h3 className="mb-16">Sperrzeit anlegen</h3>

      <Formik
        initialValues={{
          title: "",
          dateFrom: "",
          dateTo: "",
          days: [],
          startTime: "",
          endTime: "",
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          const {
            dateFrom,
            dateTo,
            startTime,
            endTime,
          } = values;

          if (moment(dateFrom).isAfter(dateTo)) {
            errors.dateTo = intl.formatMessage({ id: "form.error.date_after" });
          }

          if (moment(startTime).isSameOrAfter(endTime)) {
            errors.endTime = intl.formatMessage({ id: "form.error.time_after" });
          }

          return errors;
        }}
        onSubmit={(values) => {
          wizardDispatch(values)
            .then((action) => {
              if (action.type.endsWith("SUCCESS")) {
                notificationService.success(`Es wurde(n) ${action.payload.data.count} Sperrzeit(en) erfolgreich angelegt.`);
                navigate("/portal/working-time", { replace: true });
              }
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Die Bezeichnung wird nicht öffentlich angezeigt.
              </Col>
              <Col md={9}>
                <Panel headline="Name der Sperrzeit">
                  <FormRowText
                    id="title"
                    name="title"
                    label="Bezeichnung*"
                    className="mb-24"
                    defaultValue={values.title}
                    errors={errors.title}
                    touched={touched.title}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Wählen Sie den ersten und den letzten Tag an denen die
                Sperrzeit angelegt werden soll.
              </Col>
              <Col md={9}>
                <Panel headline="Welchen Zeitraum soll die Sperrzeit abdecken?">
                  <FormRowDate
                    id="dateFrom"
                    name="dateFrom"
                    label="Erstmalig*"
                    className="mb-24"
                    defaultValue={values.dateFrom}
                    errors={errors.dateFrom}
                    touched={touched.dateFrom}
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setFieldValue("dateFrom", value);
                    }}
                  />

                  <FormRowDate
                    id="dateTo"
                    name="dateTo"
                    label="Letztmalig*"
                    defaultValue={values.dateTo}
                    errors={errors.dateTo}
                    touched={touched.dateTo}
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setFieldValue("dateTo", value);
                    }}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Mehrfachauswahl möglich.
              </Col>
              <Col md={9}>
                <Panel headline="An welchen Wochentagen soll die Sperrzeit gelten?">
                  <MultiSelect
                    onChange={(options) => { setFieldValue("days", options); }}
                    options={[
                      { label: "Montag", value: 1 },
                      { label: "Dienstag", value: 2 },
                      { label: "Mittwoch", value: 3 },
                      { label: "Donnerstag", value: 4 },
                      { label: "Freitag", value: 5 },
                      { label: "Samstag", value: 6 },
                      { label: "Sonntag", value: 7 },
                    ]}
                  />

                  {errors.days && touched.days && (
                    <Form.Control.Feedback type="invalid">
                      {errors.days}
                    </Form.Control.Feedback>
                  )}
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Wählen die Startzeit und die Endzeit der geplanten Sperrzeit.
              </Col>
              <Col md={9}>
                <Panel headline="An welchen Stunden soll die Sperrzeit gelten?">
                  <FormRowTime
                    id="startTime"
                    name="startTime"
                    label="Beginn*"
                    className="mb-24"
                    defaultValue={values.startTime}
                    errors={errors.startTime}
                    touched={touched.startTime}
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setFieldValue("startTime", value);
                    }}
                  />

                  <FormRowTime
                    id="endTime"
                    name="endTime"
                    label="Ende*"
                    defaultValue={values.endTime}
                    errors={errors.endTime}
                    touched={touched.endTime}
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setFieldValue("endTime", value);
                    }}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={{ span: 9, offset: 3 }}>
                <Panel small className="text-end">
                  <IconButton
                    disabled={wizardLoading}
                    type="submit"
                  >
                    Speichern
                    {wizardLoading && (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                    {wizardSuccess && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    {wizardFail && (
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    )}
                  </IconButton>
                </Panel>
              </Col>
            </Row>

          </form>
        )}
      </Formik>
    </>
  );
};

BlockingTimeWizard.propTypes = {
  wizardDispatch: PropTypes.func.isRequired,
  resetDispatch: PropTypes.func.isRequired,
  wizardLoading: PropTypes.bool,
  wizardSuccess: PropTypes.bool,
  wizardFail: PropTypes.bool,
};

BlockingTimeWizard.defaultProps = {
  wizardLoading: false,
  wizardSuccess: false,
  wizardFail: false,
};

const mapStateToProps = ({ blockingTime }) => ({
  wizardLoading: blockingTime.wizardLoading,
  wizardSuccess: blockingTime.wizardSuccess,
  wizardFail: blockingTime.wizardFail,
});

const mapDispatch = {
  wizardDispatch: wizard,
  resetDispatch: reset,
};

export default connect(mapStateToProps, mapDispatch)(BlockingTimeWizard);
