import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const TermsAndConditions = () => (
  <Row>
    <Col md={{ span: 10, offset: 1 }}>
      <h1>Allgemeine Geschäftsbedingungen</h1>

      <p><b>1. Nutzungsumfang</b></p>
      <p>
        1.1. Dubidoc wird als webbasierte Software auf der IT Infrastruktur von Takuta bzw. dessen
        Subunternehmern betrieben (Software-as-a-Service) und bietet insbesondere die Möglichkeit,
        Termine von Patienten zentral zu verwalten.
      </p>
      <p>
        1.2. Takuta stellt ausschließlich eine Plattform zur internetbasierten Verwaltung und
        Kommunikation zur Verfügung. Über die Plattform ist eine fachärztliche Beratung nicht
        zulässig. Insbesondere die Richtigkeit der Inhalte der Kommunikation, die Zuverlässigkeit,
        Pünktlichkeit oder Bonität von Patienten sind nicht Vertragsgegenstand.
      </p>
      <p>
        1.3. Takuta behält sich das Recht vor, den Nutzungsumfang und die Nutzungsbedingungen
        jederzeit zu verändern. Die jeweilige Änderung wird dem Kunden rechtzeitig vorab bekannt
        gegeben. Schweigt der Kunde oder widerspricht er nicht innerhalb von 2 Wochen nach Kenntnis
        der Änderung schriftlich oder in elektronischer Form, so werden die Änderungen wirksam.
      </p>

      <p><b>2. Nutzungsrechte</b></p>
      <p>
        2.1. Der Kunde erhält das nicht-ausschließliche, zeitlich auf die Laufzeit des Vertrages
        beschränkte, nicht übertragbare und nicht unterlizensierbare Recht zur Nutzung der Software.
      </p>
      <p>
        2.2. Der Kunde ist nicht berechtigt, die ihm übergegeben Zugangsdaten Dritten zu überlassen.
        Insbesondere ist es ihm nicht gestattet, die Software zu veräußern, zu verleihen, zu
        vermieten oder in sonstiger Weise unterzulizensieren oder die Software öffentlich
        wiederzugeben oder zugänglich zu machen.
      </p>

      <p><b>3. Verfügbarkeit und Support</b></p>
      <p>
        3.1.Takuta arbeitet kontinuierlich an der technischen und organisatorischen Verbesserung, um
        eine möglichst hohe Sicherheit, Verfügbarkeit und Integrität der Software zu gewährleisten.
      </p>
      <p>
        3.2. Takuta weist den Kunden darauf hin, dass es dennoch zu Einschränkungen oder
        Beeinträchtigungen der Leistungen (Störungen) kommen kann, die außerhalb des
        Einflussbereichs von Takuta liegen. Hierunter zählen insbesondere Handlungen von Dritten,
        die nicht im Auftrag von Takuta handeln oder nicht beinflussbare technische Bedingungen des
        Internets, Cyberangriffe oder höhere Gewalt. Soweit derartige Umstände die Verfügbarkeit
        oder den Funktionsumfang der Software nur vorübergehend einschränken, hat dies keine
        Auswirkung auf die Vertragsgemäßheit der erbrachten Leistungen.
      </p>
      <p>
        3.3. Ferner kann nicht ausgeschlossen werden, dass es zu sonstigen unvorhersehbaren
        Problemen und Einschränkungen kommt. Bei der Nutzung sollte sich der Kunde daher bewusst
        sein und beachten, dass
        <ul>
          <li>
            es zu einer eingeschränkten Verfügbarkeit oder zum Verlust von Daten kommen kann
          </li>
          <li>
            bestimmte Funktionalitäten nur eingeschränkt zur Verfügung stehen können
          </li>
          <li>
            Verfügbarkeitsunterbrechungen auftreten können, insbesondere durch Wartungs- und
            Entwicklungsarbeiten, wobei sich Takuta darum bemühen wird, vorhersehbare und planbare
            Wartungen außerhalb der Öffnungszeiten der Kunden durchzuführen
          </li>
        </ul>
      </p>
      <p>
        3.4. Takuta weist den Kunden darauf hin, dass für seinen Geschäftsbetrieb essentielle Daten
        nicht ausschließlich in der Software vorgehalten werden, sondern solche Daten regelmäßig
        anderweitig abgespeichert oder aufbewahrt werden sollten. Dies kann beispielsweise durch
        Ausdrucken wichtiger Daten aus der Software erfolgen.
      </p>
      <p>
        3.5. Der Kunde ist für die Qualität der Internetverbindung und die benötigte Hardware
        ausschließlich selbst verantwortlich.
      </p>
      <p>
        3.6. Der Kunde meldet Störungen unter ausreichender Schilderung des Sachverhalts umgehend
        per Email an team@dubidoc.de. Takuta wird während der Supportzeiten innerhalb von 24 Stunden
        nach Eingang der Mail reagieren. Der Support steht von Montag bis Freitag zwischen
        08.00-17.00 Uhr zur Verfügung.
      </p>
      <p>
        3.7. Takuta ist berechtigt, dubidoc jederzeit nach seinem Ermessen zu optimieren, anzupassen
        oder zu verändern.
      </p>

      <p><b>4. Wartung und Beratung</b></p>
      <p>
        Soweit über die Wiederherstellung der Funktionsfähigkeit von Dubidoc (Störungsbeseitigung)
        hinausgehend Wartungsleistungen oder Beratung vom Kunden angefragt werden, wird Takuta diese
        nach Aufwand berechnen. Soweit nicht abweichend zwischen den Parteien vereinbart wird dies
        auf Stundenbasis mit 80,00 Euro / Stunde (netto) abgerechnet.
      </p>

      <p><b>5. Implementierung & Einführungsschulung</b></p>
      <p>
        5.1. Takuta wird dubidoc bei Neukunden so installieren, dass die volle Funktionsfähigkeit
        und die Einbindung in die Praxis gewährleistet ist, soweit der Kunde die
        Systemvoraussetzungen und die Anforderung an die technische Umgebung erfüllt.
      </p>
      <p>
        5.2. Takuta bietet dem Kunden bzw. dessen Mitarbeitern eine einmalige Einführungsschulung
        (maximal ein Tag) vor Ort an. Soweit nicht anders vereinbart ist, fallen für diese einmalige
        Schulung keine weiteren Kosten an.
      </p>
      <p><b>6. Datenherausgabe</b></p>
      <p>
        Nach Vertragsbeendigung ist der Kunde berechtigt, seinen Zugang zu dubidoc für maximal drei
        Monate weiterhin zu nutzen. Dieser Zugang wird jedoch dahingehend beschränkt, dass der Kunde
        nur noch über solche Leserechte verfügt, die erforderlich sind, um seine in dubidoc
        verwendeten Patientendaten zu transferieren. Darüber hinaus schuldet dubidoc keine weitere
        Unterstützung zum Transfer von Daten.
      </p>

      <p><b>7. Datenschutz</b></p>
      <p>
        Soweit personenbezogene Daten im Auftrag des Kunden gemäß Art. 28 DSGVO verarbeitet werden,
        müssen aus gesetzlichen Gründen die folgenden Regelungen zusätzlich gelten:
        <ul>
          <li>
            Der Kunde beauftragt Takuta mit der Verwaltung von Patientendaten entsprechend der
            Regelungen des zu Grunde liegenden Vertrags.
          </li>
          <li>
            Es werden personenbezogene Daten über Patienten und Beschäftigte des Kunden verarbeitet,
            insbesondere Name, Vorname, Geschlecht, Titel, Wohnanschrift, E-Mail-Adresse,
            Telefonnummer, Terminwünsche. Takuta weist den Kunden ausdrücklich darauf hin, dass es
            sich bei bestimmten personenbezogenen Daten um besondere Kategorien personenbezogener
            Daten gemäß Art. 9 Abs. 1 DSGVO handeln kann, die einem erhöhten Schutz unterliegen.
            Solche Daten werden nur mit der ausdrücklichen Einwilligung der betroffenen Person gem.
            Art. 9 Abs. 2 S. 1 lit. a) DSGVO und nur zum Zwecke der Durchführung unserer
            Dienstleistung verarbeitet. Eine darüber hinaus gehende Verarbeitung besonderer
            Kategorien personenbezogener Daten ist durch Takuta nicht vorgesehen.
          </li>
          <li>
            Alle weiteren datenschutzrechtlichen Bestimmungen regelt der Vertrag über die
            Auftragsverarbeitung, dessen Vertragsdauer sich nach der Laufzeit des zu Grunde
            liegenden Vertrags richtet.
          </li>
        </ul>
      </p>

      <p><b>8. Haftung</b></p>
      <p>
        8.1. Takuta übernimmt in seiner Rolle als Vermittler keine Haftung für das Verhältnis
        zwischen dem Kunden und seinen Patienten. Dies erfasst insbesondere Terminausfälle, welche
        über die Plattform vereinbart wurden.
      </p>
      <p>
        8.2. Takuta haftet unbeschränkt nur bei Vorsatz und grober Fahrlässigkeit.
      </p>
      <p>
        8.3. Bei fahrlässig verursachten Schäden haften Takuta und seine Erfüllungsgehilfen nur bei
        der Verletzung einer wesentlichen Vertragspflicht, jedoch der Höhe nach beschränkt auf die
        bei Vertragsschluss vorhersehbaren und vertragstypischen Schäden. Wesentliche
        Verpflichtungen sind Verpflichtungen, welche für die ordnungsgemäße Durchführung dieses
        Vertrags erfüllt werden müssen, deren Erfüllung die Verwirklichung der Vertragsziele dieses
        Vertrags erst ermöglicht und solche Verpflichtungen, auf deren Einhaltung die Nutzer
        grundsätzlich nach dem Sinn und Inhalt dieses Vertrags vertrauen können.
      </p>
      <p>
        8.4. Die Haftung für Datenverlust wird auf den typischen Wiederherstellungsaufwand
        beschränkt, der bei regelmäßiger und gefahrentsprechender Anfertigung von Sicherungskopien
        eingetreten wäre.
      </p>
      <p>
        Keine der genannten Einschränkungen gilt im Falle einer Verletzung des Lebens, des Körpers
        oder der Gesundheit einer Person.
      </p>

      <p><b>9. Freistellung von Ansprüchen Dritter</b></p>
      <p>
        9.1. Der Kunde ist für die von ihm in der Software verwendeten Daten selbst verantwortlich.
        Er verpflichtet sich insbesondere keine strafbaren, schädlichen, unbilligen oder solche
        Inhalte zu verwenden, die Rechte Dritter verletzen. Eine Prüfung der Inhalte durch Takuta
        erfolgt ausdrücklich nicht.
      </p>
      <p>
        9.2. Der Kunde wird in diesem Zusammenhang Takuta von sämtlichen Ansprüchen Dritter
        freistellen, soweit Takuta von diesen in Anspruch genommen wird.
      </p>

      <p><b>10. Vertraulichkeit</b></p>
      <p>
        10.1. Der Kunde und Takuta verpflichten sich, alle im Rahmen des Vertragsverhältnisses
        erlangten Kenntnisse von Betriebs- und Geschäftsgeheimnissen vertraulich zu behandeln. Dies
        gilt auch über die Beendigung der Geschäftsbeziehung hinaus.
      </p>

      <p><b>11. Laufzeit, Unterbrechung, Kündigung, Vertragsende</b></p>
      <p>
        11.1. Der vorliegende Vertrag wird auf unbestimmte Zeit geschlossen.
      </p>
      <p>
        11.2. Die Vertragspartner können das Vertragsverhältnis, mit einer Frist von 14 Tagen zum
        Monatsende kündigen. Sofern die Frist überschritten wird, endet das Vertragsverhältnis zum
        nächstmöglichen Zeitpunkt.
      </p>
      <p>
        11.3. Beide Parteien können das Vertragsverhältnis entsprechend den gesetzlichen
        Bestimmungen aus wichtigem Grund ohne Einhaltung einer Frist kündigen. Als ein wichtiger
        Grund für Takuta gilt insbesondere ein Verstoß gegen die Nutzungsbedingungen aus Punkt 2 des
        Vertrages oder wenn der Kunde trotz Mahnung mehr als zwei Monate mit der Zahlung einer
        fälligen Vergütung in Verzug ist
      </p>
      <p>
        11.4. Kündigungen bedürfen zu ihrer Wirksamkeit der Schriftform.
      </p>
      <p>
        11.5. Takuta erwächst aus einer der zuvor genannten Kündigungen keine Entschädigungspflicht
        gegenüber dem Kunden.
      </p>

      <p><b>12. Nutzungsentgelt, Zahlungsmodalitäten</b></p>
      <p>
        12.1. Für die Bereitstellung ist ein monatliches Entgelt zu entrichten. Das Entgelt ist
        zahlbar bis zum 5. Tag des Monats.
      </p>
      <p>
        12.2. Die Zahlungspflicht besteht unabhängig der tatsächlichen Nutzung durch den Kunden.
      </p>
      <p>
        12.3. Das Nutzungsentgelt wird zwischen den Parteien individuell vereinbart und wird als
        Nettopreis ausgewiesen, zuzüglich der jeweils geltenden gesetzlichen Mehrwertsteuer Der
        Kunde verpflichtet sich zur Auskunft über die Anzahl der Nutzer gegenüber Takuta. Er ist
        verpflichtet, Änderungen der Nutzerzahl unmittelbar an Takuta in Textform zu melden. Der
        Kunde trägt die alleinige Sorge dafür, dass die benannten Ärzte ihr Einverständnis hierzu
        erteilt haben.
      </p>

      <p><b>13. Sonstiges</b></p>
      <p>
        13.1. Änderungen und Ergänzungen des Vertrags bedürfen der Schriftform. Dies gilt auch für
        die Änderung oder Aufhebung dieser Klausel.
      </p>
      <p>
        13.2. Als Gerichtsstand wird Essen vereinbart.
      </p>
      <p>
        13.3. Sollten einzelne Bestimmungen dieses Vertrags unwirksam sein, berührt dies die
        Gültigkeit der übrigen Bestimmungen grundsätzlich nicht. Die Vertragsparteien werden sich
        bemühen, anstelle der unwirksamen Bestimmung eine solche zu finden, die dem Vertragsziel
        rechtlich und wirtschaftlich am ehesten gerecht wird.
      </p>
    </Col>
  </Row>
);

export default TermsAndConditions;
