// Appointment states
export const APPOINTMENT_STATE_ABSENT = 0;
export const APPOINTMENT_STATE_WAITING = 1;
export const APPOINTMENT_STATE_STARTED = 2;
export const APPOINTMENT_STATE_DONE = 3;
export const APPOINTMENT_STATE_RESERVED = 4;
export const APPOINTMENT_STATE_EXPIRED = 10;
export const APPOINTMENT_STATE_CANCELED = 11;
export const APPOINTMENT_STATE_DELETED = 12;
export const APPOINTMENT_STATE_DELETED_PATIENT = 13;

// Countries
export const COUNTRIES = [
  { value: "de", label: "country.germany" },
  { value: "nl", label: "country.netherlands" },
  { value: "be", label: "country.belgium" },
  { value: "lu", label: "country.luxembourg" },
  { value: "fr", label: "country.france" },
  { value: "ch", label: "country.switzerland" },
  { value: "at", label: "country.austria" },
  { value: "cz", label: "country.czechia" },
  { value: "pl", label: "country.poland" },
  { value: "dk", label: "country.denmark" },
];

// Genders
export const GENDERS = [
  { value: 0, label: "gender.female" },
  { value: 1, label: "gender.male" },
  { value: 2, label: "gender.diverse" },
];
