import { createSlice } from "@reduxjs/toolkit";

const AppointmentTypeStatisticSlice = createSlice({
  name: "appointmentTypeStatistic",
  initialState: {
    appointmentTypeStatistics: [],
    appointmentTypeStatisticsLoading: false,
  },
  reducers: {
    fetchDay: (state) => ({
      ...state,
      appointmentTypeStatistics: [],
      appointmentTypeStatisticsLoading: true,
    }),
    fetchDay_SUCCESS: (state, { payload }) => ({
      ...state,
      appointmentTypeStatistics: payload.data.appointmentTypeStatistics,
      appointmentTypeStatisticsLoading: false,
    }),
    fetchDay_FAIL: (state) => ({
      ...state,
      appointmentTypeStatistics: [],
      appointmentTypeStatisticsLoading: false,
    }),
  },
});

export default AppointmentTypeStatisticSlice.reducer;

export const fetchDay = (date) => ({
  type: "appointmentTypeStatistic/fetchDay",
  payload: {
    client: "default",
    request: {
      url: `portal/appointment-type-statistic/day-details/${date}`,
    },
  },
});
