import React, { Component } from "react";

import axios from "axios";
import {
  faChevronLeft,
  faChevronRight,
  faDotCircle,
  faPen,
} from "@fortawesome/pro-regular-svg-icons";
import FullCalendar from "@fullcalendar/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeLocale from "@fullcalendar/core/locales/de";
import DayGridPlugin from "@fullcalendar/daygrid";
import ListPlugin from "@fullcalendar/list";
import TimeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import axiosInstance from "../../../utils/AxiosHelper";
import IconButton from "../../../components/IconButton/IconButton";
import ConfirmButton from "../../../components/ConfirmButton/ConfirmButton";
import { remove as removeWorkingTime } from "../../../state/features/WorkingTimeSlice";
import { remove as removeBlockingTime } from "../../../state/features/BlockingTimeSlice";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import CircleBadge from "../../../components/CircleBadge/CircleBadge";

class WorkingTimes extends Component {
  constructor(props) {
    super(props);

    this.calendarRef = React.createRef();
    this.calendarApi = null;

    this.state = {
      title: null,
      showModal: false,
      currentEvent: {
        ulid: null,
        type: "",
      },
    };
  }

  componentDidMount() {
    this.calendarApi = this.calendarRef.current.getApi();

    this.updateTitle();
  }

  updateTitle = () => {
    if (this.calendarApi === null) {
      return;
    }

    const {
      title,
    } = this.state;

    const {
      view,
    } = this.calendarApi;

    if (title !== view.title) {
      this.setState({
        title: view.title,
      });
    }
  }

  fetchEvents = (fetchInfo, onSuccess, onFailure) => {
    const axiosClient = axiosInstance();

    if (axiosClient) {
      const workingTimes = axiosClient.get(`/portal/working-time/calendar/${fetchInfo.startStr}/${fetchInfo.endStr}`);
      const lockingTimes = axiosClient.get(`/portal/blocking-time/calendar/${fetchInfo.startStr}/${fetchInfo.endStr}`);

      axios.all([workingTimes, lockingTimes])
        .then(axios.spread((...responses) => {
          onSuccess([
            // Working Times
            ...(responses[0].data.workingTimes.map((workingTime) => ({
              id: workingTime.ulid,
              title: "",
              start: workingTime.startTime,
              end: workingTime.endTime,
              backgroundColor: "#D5EECD",
              borderColor: "#206D2A",
              textColor: "#212121",
              extendedProps: {
                firstDate: workingTime.firstDate,
                lastDate: workingTime.lastDate,
                set: workingTime.set !== null,
                type: "Arbeitszeit",
              },
            }))),
            // Blocking Times
            ...(responses[1].data.blockingTimes.map((blockingTime) => ({
              id: blockingTime.ulid,
              title: blockingTime.title,
              start: blockingTime.startTime,
              end: blockingTime.endTime,
              backgroundColor: "#FCCDC5",
              borderColor: "#BB260A",
              textColor: "#212121",
              extendedProps: {
                firstDate: blockingTime.firstDate,
                lastDate: blockingTime.lastDate,
                set: blockingTime.set !== null,
                type: "Sperrzeit",
              },
            }))),
          ]);
        }))
        .catch((errors) => {
          onFailure(errors);
        });
    }
  };

  dateChanged = () => {
    this.updateTitle();
  }

  renderEventContent = (arg) => (
    <div className="p-10">
      {arg.event?.title && (<>{arg.event.title} <br /></>)}
      {arg.timeText} Uhr <br />
      {arg.event?.extendedProps?.lastDate && (
        <>Ende: {moment(arg.event.extendedProps.lastDate).format("DD.MM.YYYY")}</>
      )}
    </div>
  );

  modalToggle = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  modalHandleEventClick = (event) => {
    this.setState({
      currentEvent: event,
      showModal: true,
    });
  };

  render() {
    const {
      removeBlockingTimeDispatch,
      removeWorkingTimeDispatch,
    } = this.props;

    const {
      title,
      showModal,
      currentEvent,
    } = this.state;

    return (
      <>
        <BreadCrumbs
          crumbs={[
            { to: "/portal/dashboard", name: "client_navigation.dashboard" },
            { to: "/portal/working-time", name: "client_navigation.working_time_calendar" },
          ]}
        />

        <div className="mb-8 d-flex">
          <IconButton
            variant="white"
            className="me-4"
            onClick={() => {
              this.calendarApi.prev();
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
          <Button
            variant="white"
            className="me-4"
            style={{ width: "230px" }}
          >
            {title}
          </Button>
          <IconButton
            variant="white"
            className="me-8"
            onClick={() => {
              this.calendarApi.next();
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </IconButton>
          <IconButton
            variant="white"
            onClick={() => {
              this.calendarApi.today();
            }}
          >
            <FontAwesomeIcon icon={faDotCircle} />
            Heute
          </IconButton>

          <div className="ms-auto">
            <Button
              as={NavLink}
              variant="white"
              className="me-8"
              to="/portal/working-time/wizard"
            >
              Arbeitszeit anlegen
            </Button>
            <Button
              as={NavLink}
              variant="white"
              className="me-8"
              to="/portal/blocking-time/wizard"
            >
              Sperrzeit anlegen
            </Button>
          </div>

        </div>

        <FullCalendar
          ref={this.calendarRef}
          plugins={[DayGridPlugin, TimeGridPlugin, ListPlugin]}
          initialView="timeGridWeek"
          themeSystem="bootstrap"
          locales={[DeLocale]}
          locale="de"
          firstDay={1}
          allDaySlot={false}
          slotMinTime="06:00:00"
          slotMaxTime="22:00:00"
          slotDuration="00:10:00"
          slotMinWidth={100}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: "short",
          }}
          slotEventOverlap={false}
          nowIndicator
          headerToolbar={false}
          events={this.fetchEvents}
          eventContent={this.renderEventContent}
          eventClick={({ event }) => {
            this.modalHandleEventClick({
              ulid: event.id,
              start: event.start,
              end: event.end,
              ...event.extendedProps,
            });
          }}
          datesSet={this.dateChanged}
        />

        <Modal
          show={showModal}
          onHide={this.modalToggle}
          centered
          size="lg"
        >
          <Modal.Header toggle={this.modalToggle} closeButton>
            <Modal.Title>{currentEvent.type} {currentEvent.set && "(Serie)"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={3} className="d-flex justify-content-center align-items-center">
                <CircleBadge xlarge variant="secondary">
                  <FontAwesomeIcon icon={faPen} />
                </CircleBadge>
              </Col>
              <Col md={9}>
                <Row>
                  <Col md={12} className="mb-8">
                    Datum: {moment(currentEvent.start).format("L")}
                  </Col>
                  {currentEvent.set && (
                    <>
                      <Col md={6} className="mb-8">
                        Erstmalig: {moment(currentEvent.firstDate).format("L")}
                      </Col>
                      <Col md={6} className="mb-8">
                        Letztmalig: {moment(currentEvent.lastDate).format("L")}
                      </Col>
                    </>
                  )}
                  <Col md={6} className="mb-8">
                    Begin: {moment(currentEvent.start).format("HH:mm")} Uhr
                  </Col>
                  <Col md={6} className="mb-8">
                    Ende: {moment(currentEvent.end).format("HH:mm")} Uhr
                  </Col>
                </Row>

                <div className="d-flex justify-content-between flex-wrap mt-8">
                  {currentEvent.type === "Arbeitszeit" ? (
                    <>
                      <Button
                        variant="secondary"
                        as={NavLink}
                        to={`/portal/working-time/edit/${currentEvent.ulid}`}
                        className="mb-8"
                      >
                        Bearbeiten
                      </Button>
                      {currentEvent.set && (
                        <Button
                          variant="secondary"
                          as={NavLink}
                          to={`/portal/working-time/edit/set/${currentEvent.ulid}`}
                          className="mb-8"
                        >
                          Serie Bearbeiten
                        </Button>
                      )}
                      <ConfirmButton
                        className="mb-8"
                        variant="secondary"
                        onClick={() => {
                          removeWorkingTimeDispatch(currentEvent.ulid)
                            .then((action) => {
                              this.setState({
                                showModal: false,
                              });

                              if (action.type.endsWith("SUCCESS")) {
                                this.calendarApi?.refetchEvents();
                              }
                            });
                        }}
                      >
                        <Button>
                          Löschen
                        </Button>
                      </ConfirmButton>
                      {currentEvent.set && (
                        <ConfirmButton
                          className="mb-8"
                          onClick={() => {
                            removeWorkingTimeDispatch(currentEvent.ulid, true)
                              .then((action) => {
                                this.setState({
                                  showModal: false,
                                });

                                if (action.type.endsWith("SUCCESS")) {
                                  this.calendarApi?.refetchEvents();
                                }
                              });
                          }}
                        >
                          <Button>
                            Serie löschen
                          </Button>
                        </ConfirmButton>
                      )}
                    </>
                  ) : (
                    <>
                      <Button
                        variant="secondary"
                        as={NavLink}
                        to={`/portal/blocking-time/edit/${currentEvent.ulid}`}
                      >
                        Bearbeiten
                      </Button>
                      {currentEvent.set && (
                        <Button
                          variant="secondary"
                          as={NavLink}
                          to={`/portal/blocking-time/edit/set/${currentEvent.ulid}`}
                        >
                          Serie Bearbeiten
                        </Button>
                      )}
                      <ConfirmButton
                        onClick={() => {
                          removeBlockingTimeDispatch(currentEvent.ulid)
                            .then((action) => {
                              this.setState({
                                showModal: false,
                              });

                              if (action.type.endsWith("SUCCESS")) {
                                this.calendarApi?.refetchEvents();
                              }
                            });
                        }}
                      >
                        <Button>
                          Löschen
                        </Button>
                      </ConfirmButton>
                      {currentEvent.set && (
                        <ConfirmButton
                          variant="secondary"
                          onClick={() => {
                            removeBlockingTimeDispatch(currentEvent.ulid, true)
                              .then((action) => {
                                this.setState({
                                  showModal: false,
                                });

                                if (action.type.endsWith("SUCCESS")) {
                                  this.calendarApi?.refetchEvents();
                                }
                              });
                          }}
                        >
                          <Button>
                            Serie löschen
                          </Button>
                        </ConfirmButton>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

WorkingTimes.propTypes = {
  removeWorkingTimeDispatch: PropTypes.func.isRequired,
  removeBlockingTimeDispatch: PropTypes.func.isRequired,
};

const mapDispatch = {
  removeWorkingTimeDispatch: removeWorkingTime,
  removeBlockingTimeDispatch: removeBlockingTime,
};

export default connect(null, mapDispatch)(WorkingTimes);
