import React, { memo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./CircleBadge.scss";

const CircleBadge = (props) => {
  const {
    children,
    className,
    variant,
    large,
    xlarge,
    ...otherProps
  } = props;

  return (
    <span
      className={classNames("circle-badge", className, {
        "circle-badge--large": large,
        "circle-badge--xlarge": xlarge,
        [`circle-badge--${variant}`]: variant !== null,
      })}
      {...otherProps}
    >
      {children}
    </span>
  );
};

CircleBadge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["info", "success", "danger"]),
  large: PropTypes.bool,
  xlarge: PropTypes.bool,
};

CircleBadge.defaultProps = {
  className: "",
  variant: null,
  large: false,
  xlarge: false,
};

export default memo(CircleBadge);
