import React,
{
  memo,
  useEffect,
  useRef,
  useState,
} from "react";

import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Select from "react-select";

import {
  IconFlagAustria,
  IconFlagBelgium,
  IconFlagCzechRepublic,
  IconFlagDenmark,
  IconFlagFrance,
  IconFlagGermany,
  IconFlagLuxembourg,
  IconFlagNetherlands,
  IconFlagPoland,
  IconFlagSwiss,
} from "../../icons/flags";

import "./MobilePhoneInput.scss";

const countryCodes = [
  {
    value: "+31",
    label: "+31",
    icon: <img src={IconFlagNetherlands} alt="Flag Netherlands" />,
  },
  {
    value: "+32",
    label: "+32",
    icon: <img src={IconFlagBelgium} alt="Flag Belgium" />,
  },
  {
    value: "+33",
    label: "+33",
    icon: <img src={IconFlagFrance} alt="Flag France" />,
  },
  {
    value: "+352",
    label: "+352",
    icon: <img src={IconFlagLuxembourg} alt="Flag Luxembourg" />,
  },
  {
    value: "+41",
    label: "+41",
    icon: <img src={IconFlagSwiss} alt="Flag Swiss" />,
  },
  {
    value: "+420",
    label: "+420",
    icon: <img src={IconFlagCzechRepublic} alt="Flag Czech Republic" />,
  },
  {
    value: "+43",
    label: "+43",
    icon: <img src={IconFlagAustria} alt="Flag Austria" />,
  },
  {
    value: "+45",
    label: "+45",
    icon: <img src={IconFlagDenmark} alt="Flag Denmark" />,
  },
  {
    value: "+48",
    label: "+48",
    icon: <img src={IconFlagPoland} alt="Flag Poland" />,
  },
  {
    value: "+49",
    label: "+49",
    icon: <img src={IconFlagGermany} alt="Flag Germany" />,
  },
];

const MobilePhoneInput = (props) => {
  const {
    name,
    defaultValue,
    handleChange,
    handleBlur,
    className,
    placeholder,
    ...otherProps
  } = props;

  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    let found = false;

    if (defaultValue) {
      countryCodes.forEach((item, index) => {
        if (defaultValue.startsWith(item.value)) {
          setDefaultValues({
            prefix: countryCodes[index],
            mobileNumber: defaultValue.replace(item.value, "").replace(/^0/, ""),
          });

          found = true;
        }
      });
    }

    if (found) {
      return;
    }

    setDefaultValues({
      prefix: countryCodes[9],
      mobileNumber: defaultValue.replace(/^0/, ""),
    });
  }, [defaultValue]);

  const prefix = useRef(null);
  const mobileNumber = useRef(null);

  const customOnChange = (e) => {
    const prefixValue = e.value ?? prefix.current?.getValue()[0]?.value;
    let mobileNumberValue = mobileNumber.current?.value;

    let phoneValue = "";

    if (prefixValue && mobileNumberValue && /^\d+$/.test(mobileNumberValue)) {
      countryCodes.forEach((item) => {
        mobileNumberValue = mobileNumberValue.replace(item.value, "");
        mobileNumberValue = mobileNumberValue.replace(`00${parseInt(item.value, 10)}`, "");
      });

      phoneValue = prefixValue + mobileNumberValue.replace(/^0/, "");
    }

    handleChange({
      target: {
        type: "change",
        id: name,
        name,
        value: phoneValue,
      },
    });
  };

  const formatOptionLabel = ({ value, label, icon }) => (
    <div className="mobile-phone-input__prefix">
      {label}
      {icon}
    </div>
  );

  if (defaultValues === null) {
    return null;
  }

  return (
    <div className="mobile-phone-input" {...otherProps}>
      <Select
        className="mobile-phone-input__select"
        classNamePrefix="mobile-phone-input"
        defaultValue={defaultValues.prefix}
        options={countryCodes}
        formatOptionLabel={formatOptionLabel}
        isSearchable={false}
        ref={prefix}
        components={{
          IndicatorSeparator: () => null,
        }}
        onChange={customOnChange}
      />
      <Form.Control
        name={name}
        type="text"
        placeholder={placeholder}
        onChange={customOnChange}
        onBlur={handleBlur}
        ref={mobileNumber}
        defaultValue={defaultValues.mobileNumber}
      />
    </div>
  );
};

MobilePhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.any]).isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

MobilePhoneInput.defaultProps = {
  className: "",
  placeholder: null,
};

export default memo(MobilePhoneInput);
