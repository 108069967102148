import { useState, useEffect } from "react";

const getWindowDimensions = () => {
  let height = 0;
  let width = 0;
  let pageYOffset = 0;

  if (typeof window !== "undefined") {
    width = window.innerWidth;
    height = window.innerHeight;
    pageYOffset = window.pageYOffset;
  }

  return {
    width,
    height,
    pageYOffset,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);

    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    handleResize();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }

    return () => {};
  }, []);

  if (!hasMounted) {
    return { height: 0, width: 0, pageYOffset: 0 };
  }

  return windowDimensions;
};

export default useWindowDimensions;
