import React, { memo } from "react";

import PropTypes from "prop-types";
import Datetime from "react-datetime";

import FormRow from "./FormRow";

const FormRowDate = (props) => {
  const {
    id,
    label,
    defaultValue,
    handleChange,
    handleBlur,
    errors,
    touched,
    hint,
    className,
    initialViewMode,
    initialViewDate,
    size,
    labelSize,
    ...otherProps
  } = props;

  return (
    <FormRow
      id={id}
      label={label}
      errors={errors}
      touched={touched}
      hint={hint}
      size={size}
      labelSize={labelSize}
      className={className}
    >
      <Datetime
        timeFormat={false}
        initialValue={new Date(defaultValue)}
        initialViewDate={initialViewDate}
        initialViewMode={initialViewMode}
        closeOnSelect
        onChange={(moment) => {
          handleChange(typeof moment === "object"
            ? moment.format("YYYY-MM-DD")
            : "");
        }}
        inputProps={{
          onBlur: handleBlur,
          name: id,
          autoComplete: "off",
          className: errors && touched ? "form-control is-invalid" : "form-control",
          ...otherProps,
        }}
      />
    </FormRow>
  );
};

FormRowDate.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.any]).isRequired,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  hint: PropTypes.string,
  className: PropTypes.string,
  initialViewMode: PropTypes.string,
  initialViewDate: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  labelSize: PropTypes.number,
  size: PropTypes.number,
};

FormRowDate.defaultProps = {
  errors: null,
  touched: false,
  hint: null,
  className: "",
  initialViewMode: "days",
  initialViewDate: new Date(),
  labelSize: 4,
  size: 7,
};

export default memo(FormRowDate);
