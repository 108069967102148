import React, { useState } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import Datetime from "react-datetime";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { deleteAppointment } from "../../state/features/AppointmentDeleteSlice";

const AppointmentDelete = (props) => {
  const {
    deleteAppointmentDispatch,
    deleteAppointmentSuccess,
    deleteAppointmentFail,
  } = props;

  const [birthdate, setBirthdate] = useState(null);

  const {
    ulid,
  } = useParams();

  return (
    <Row>
      <Col md={{ offset: 3, span: 6 }}>
        <h1 className="mb-24">
          Ihren Termin stornieren
        </h1>

        {deleteAppointmentSuccess && (
          <p className="mb-40">
            Ihr Termin wurde erfolgreich storniert.
          </p>
        )}

        {!deleteAppointmentSuccess && (
          <>
            <p className="mb-40">
              Zu Ihrer Sicherheit geben Sie bitte das Geburtsdatum der Person ein für
              die der Termin vereinbart wurde.
            </p>
            <h3 className="mb-16">
              Geburtsdatum
            </h3>

            <Form.Group className="mb-40">
              <Datetime
                timeFormat={false}
                initialViewDate={new Date("1990-01-01")}
                initialViewMode="years"
                closeOnSelect
                onChange={(value) => {
                  setBirthdate(typeof value === "object"
                    ? value.format("YYYY-MM-DD")
                    : null);
                }}
                inputProps={{
                  placeholder: "Ihr Geburtsdatum*",
                  autoComplete: "off",
                  className: deleteAppointmentFail ? "form-control is-invalid" : "form-control",
                }}
                isValidDate={(current) => (moment().isSameOrAfter(current))}
              />

              {deleteAppointmentFail && (
                <Form.Control.Feedback type="invalid">
                  Bitte überprüfen Sie Ihre Eingabe!
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Button
              variant="secondary"
              disabled={birthdate === null}
              onClick={() => {
                deleteAppointmentDispatch(ulid, birthdate);
              }}
            >
              Termin endgültig stornieren
            </Button>
          </>
        )}
      </Col>
    </Row>
  );
};

AppointmentDelete.propTypes = {
  deleteAppointmentDispatch: PropTypes.func.isRequired,
  deleteAppointmentSuccess: PropTypes.bool,
  deleteAppointmentFail: PropTypes.bool,
};

AppointmentDelete.defaultProps = {
  deleteAppointmentSuccess: false,
  deleteAppointmentFail: false,
};

const mapStateToProps = ({ appointmentDelete }) => ({
  deleteAppointmentSuccess: appointmentDelete.deleteAppointmentSuccess,
  deleteAppointmentFail: appointmentDelete.deleteAppointmentFail,
});

const mapDispatch = {
  deleteAppointmentDispatch: deleteAppointment,
};

export default connect(mapStateToProps, mapDispatch)(AppointmentDelete);
