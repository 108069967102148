import React, { useContext } from "react";

import {
  faCalendarAlt,
  faClinicMedical,
  faCog,
  faQuestionCircle,
  faHome,
  faNotesMedical,
  faSyringe,
  faUserClock,
  faUsers,
  faKey,
  faSignOut,
} from "@fortawesome/pro-light-svg-icons";
import {
  faChartLine,
  faUserChart,
  faAnalytics,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Logo from "../Logo/Logo";
import { logout } from "../../state/features/AuthenticationSlice";
import { ThemeContext } from "../ThemeContext/ThemeContext";

import "./PortalNavigation.scss";

const PortalNavigation = (props) => {
  const {
    logoutDispatch,
    firstname,
    lastname,
    clientName,
  } = props;

  const {
    theme,
  } = useContext(ThemeContext);

  return (
    <Navbar bg="secondary" variant="dark" expand="md" fixed="top" className="portal-navigation">
      <Container fluid="xxl">
        <Navbar.Brand as={NavLink} to="/portal/dashboard">
          <Logo brand={theme} />
          <span className="navigation__client-name">
            {clientName}
          </span>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="ms-auto">
            <NavLink className="nav-link" to="/portal/dashboard">
              <FontAwesomeIcon icon={faHome} fixedWidth />
              <FormattedMessage id="client_navigation.dashboard" />
            </NavLink>
            <NavLink className="nav-link" to="/portal/calendar">
              <FontAwesomeIcon icon={faCalendarAlt} fixedWidth />
              <FormattedMessage id="client_navigation.calendar" />
            </NavLink>

            <NavDropdown title={<FontAwesomeIcon icon={faChartLine} fixedWidth className="me-0" />} align="end">
              <NavDropdown.Item as={NavLink} className="nav-link" to="/portal/appointment-statistics">
                <FontAwesomeIcon icon={faUserChart} fixedWidth />
                <FormattedMessage id="client_navigation.appointment_statistic" />
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} className="nav-link" to="/portal/appointment-type-statistics">
                <FontAwesomeIcon icon={faAnalytics} fixedWidth />
                <FormattedMessage id="client_navigation.appointment_type_statistic" />
              </NavDropdown.Item>
            </NavDropdown>

            <NavLink className="nav-link" to="/portal/help">
              <FontAwesomeIcon icon={faQuestionCircle} fixedWidth className="me-0" />
            </NavLink>

            <NavDropdown title={<FontAwesomeIcon icon={faCog} fixedWidth className="me-0" />} align="end">
              <NavDropdown.Item as={NavLink} className="nav-link" to="/portal/base-data">
                <FontAwesomeIcon icon={faClinicMedical} fixedWidth />
                <FormattedMessage id="client_navigation.base_data" />
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} className="nav-link" to="/portal/user">
                <FontAwesomeIcon icon={faUsers} fixedWidth />
                <FormattedMessage id="client_navigation.user" />
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} className="nav-link" to="/portal/working-time">
                <FontAwesomeIcon icon={faUserClock} fixedWidth />
                <FormattedMessage id="client_navigation.working_time_calendar" />
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} className="nav-link" to="/portal/appointment-type">
                <FontAwesomeIcon icon={faNotesMedical} fixedWidth />
                <FormattedMessage id="client_navigation.appointment_types" />
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} className="nav-link" to="/portal/appointment-type-budgeting">
                <FontAwesomeIcon icon={faSyringe} fixedWidth />
                <FormattedMessage id="client_navigation.appointment_type_budgetings" />
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={`${firstname} ${lastname.charAt(0)}.`} align="end">
              <NavDropdown.Item as={NavLink} className="nav-link" to="/portal/profile">
                <FontAwesomeIcon icon={faKey} fixedWidth />
                <FormattedMessage id="client_navigation.profile" />
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  logoutDispatch();
                }}
              >
                <FontAwesomeIcon icon={faSignOut} fixedWidth />
                <FormattedMessage id="client_navigation.logout" />
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

PortalNavigation.propTypes = {
  logoutDispatch: PropTypes.func.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
};

const mapStateToProps = ({ authentication }) => ({
  firstname: authentication.firstname,
  lastname: authentication.lastname,
  clientName: authentication.clientName,
});

const mapDispatch = {
  logoutDispatch: logout,
};

export default connect(mapStateToProps, mapDispatch)(PortalNavigation);
