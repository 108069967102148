import React from "react";

import Container from "react-bootstrap/Container";
import { Outlet } from "react-router-dom";

import Navigation from "../Navigation/Navigation";
import Notification from "../Notification/Notification";
import Footer from "../Footer/Footer";

import "./Layout.scss";

const Layout = () => (
  <div className="layout">
    <Navigation />

    <Container fluid="xxl" className="main">
      <Outlet />
    </Container>

    <Footer />

    <Notification />
  </div>
);

export default Layout;
