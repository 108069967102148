import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: null,
  unregisteredClient: null,
  appointmentTypes: null,
  loading: false,
  registerLoading: false,
  registerSuccess: false,
  registerFail: false,
};

const ClientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    fetch: (state) => ({
      ...state,
      loading: true,
      client: null,
      appointmentTypes: null,
    }),
    fetch_SUCCESS: (state, { payload }) => ({
      ...state,
      loading: false,
      client: payload.data.client,
      appointmentTypes: payload.data.appointmentTypes,
    }),
    fetch_FAIL: (state) => ({
      ...state,
      loading: false,
      client: null,
      appointmentTypes: null,
    }),
    fetchUnregistered: (state) => ({
      ...state,
      loading: true,
      unregisteredClient: null,
    }),
    fetchUnregistered_SUCCESS: (state, { payload }) => ({
      ...state,
      loading: false,
      unregisteredClient: payload.data.client,
    }),
    fetchUnregistered_FAIL: (state) => ({
      ...state,
      loading: false,
      unregisteredClient: null,
    }),
    register: (state) => ({
      ...state,
      registerLoading: true,
      registerSuccess: false,
      registerFail: false,
    }),
    register_SUCCESS: (state) => ({
      ...state,
      registerLoading: false,
      registerSuccess: true,
      registerFail: false,
    }),
    register_FAIL: (state) => ({
      ...state,
      registerLoading: false,
      registerSuccess: false,
      registerFail: false,
    }),
    reset: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const { reset } = ClientSlice.actions;

export default ClientSlice.reducer;

export const fetch = (ulid) => ({
  type: "client/fetch",
  payload: {
    client: "default",
    request: {
      url: `client/details/${ulid}`,
    },
  },
});

export const fetchUnregistered = (ulid) => ({
  type: "client/fetchUnregistered",
  payload: {
    client: "default",
    request: {
      url: `client/unregistered/details/${ulid}`,
    },
  },
});

export const register = (ulid, data) => {
  const formData = new FormData();

  if (data.approve !== null) {
    Array.from(data.approve).forEach((file) => {
      formData.append("approve[]", file);
    });
    // eslint-disable-next-line no-param-reassign
    delete data.approve;
  }

  formData.append("data", JSON.stringify(data));

  return {
    type: "client/register",
    payload: {
      client: "default",
      request: {
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
        url: `client/register/${ulid}`,
      },
    },
  };
};
