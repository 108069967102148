import React, { memo } from "react";

import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSyringe,
  faHourglassStart,
  faClock,
  faUserSlash,
} from "@fortawesome/pro-regular-svg-icons";

import Dot from "../Dot/Dot";
import {
  APPOINTMENT_STATE_ABSENT,
  APPOINTMENT_STATE_STARTED,
  APPOINTMENT_STATE_DONE,
  APPOINTMENT_STATE_WAITING,
  APPOINTMENT_STATE_EXPIRED,
} from "../../constants";

import "./Appointment.scss";

const Appointment = (props) => {
  const {
    lastname,
    firstname,
    color,
    state,
  } = props;

  const getState = () => {
    switch (state) {
      case APPOINTMENT_STATE_ABSENT:
        return <FontAwesomeIcon icon={faClock} />;

      case APPOINTMENT_STATE_WAITING:
        return <FontAwesomeIcon icon={faHourglassStart} />;

      case APPOINTMENT_STATE_STARTED:
        return <FontAwesomeIcon icon={faSyringe} />;

      case APPOINTMENT_STATE_DONE:
        return <FontAwesomeIcon icon={faCheck} />;

      case APPOINTMENT_STATE_EXPIRED:
        return <FontAwesomeIcon icon={faUserSlash} />;

      default:
        return null;
    }
  };

  return (
    <div className="appointment">
      <div>
        <Dot small color={color} />
      </div>
      <div className="appointment__name">
        {lastname}, {firstname}
      </div>
      <div className="appointment__state">
        {getState()}
      </div>
    </div>
  );
};

Appointment.propTypes = {
  lastname: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  state: PropTypes.number.isRequired,
};

export default memo(Appointment);
