import React, { memo } from "react";

import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import Dot from "../Dot/Dot";

import "./AppointmentOverbookModal.scss";

const AppointmentOverbookModal = (props) => {
  const {
    show,
    onHide,
    appointmentTypeBudgetings,
  } = props;

  const intl = useIntl();

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      className="appointment-overbook-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body className="d-flex flex-column align-items-center text-center">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <h3 className="mt-24 mb-16">
          {intl.formatMessage({ id: "appointment_overbook_modal.title" })}
        </h3>
        {intl.formatMessage({ id: "appointment_overbook_modal.text" })}

        <table className="table mt-24 mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                colSpan={2}
                className="text-start"
              >
                {intl.formatMessage({ id: "appointment_overbook_modal.table_header" })}
              </th>
            </tr>
          </thead>
          <tbody>
            {appointmentTypeBudgetings.map(({ appointmentType, count }) => (
              <tr key={appointmentType.ulid}>
                <td>
                  <div className="d-flex align-items-center h-100">
                    <Dot color={appointmentType.color} className="me-8" />
                    <FormattedMessage id={appointmentType.name} />
                  </div>
                </td>
                <td className="text-end navbar-button">
                  <span className="appointment-overbook-modal__ellipse">{count - appointmentType.appointmentTypeBudgetings[0].amount}</span>
                  {intl.formatMessage({ id: "appointment_overbook_modal.overbookings" })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="primary"
          onClick={onHide}
        >
          {intl.formatMessage({ id: "appointment_overbook_modal.close_button" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AppointmentOverbookModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  appointmentTypeBudgetings: PropTypes.oneOfType([
    PropTypes.array,
  ]),
};

AppointmentOverbookModal.defaultProps = {
  show: false,
  onHide: () => {},
  appointmentTypeBudgetings: [],
};

export default memo(AppointmentOverbookModal);
