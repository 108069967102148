import React from "react";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { reset } from "../../state/features/ForgotPasswordSlice";
import IconButton from "../../components/IconButton/IconButton";
import { notificationService } from "../../services/NotificationService";

const ResetPassword = (props) => {
  const {
    resetDispatch,
    resetLoading,
    resetSuccess,
    resetFail,
  } = props;

  const intl = useIntl();
  const navigate = useNavigate();
  const {
    ulid,
    resetToken,
  } = useParams();

  const validationSchema = yup.object().shape({
    password: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    passwordRepeat: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
  });

  return (
    <div className="login">
      <Row>
        <Col md={{ span: 4, offset: 4 }}>
          {resetSuccess ? (
            <>
              <h1 className="mb-24">
                <FormattedMessage id="forgot_password.success_title" />
              </h1>
              <p className="mb-24">
                <FormattedMessage id="forgot_password.success_text" />
              </p>
            </>
          ) : (
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              validate={(values) => {
                const errors = {};

                const {
                  password,
                  passwordRepeat,
                } = values;

                if (password !== "" && /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*\-_"§/()=?;,:.<>'+]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(password) === false) {
                  errors.password = intl.formatMessage({ id: "form.error.password_complexity" });
                }

                if (password !== "" && password !== passwordRepeat) {
                  errors.passwordRepeat = intl.formatMessage({ id: "form.error.password_repeat" });
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                resetDispatch(ulid, resetToken, values.password)
                  .then((action) => {
                    if (action.type.endsWith("SUCCESS")) {
                      notificationService.success(
                        intl.formatMessage({ id: "reset_password.success_notification" }),
                        {
                          autohide: false,
                        },
                      );
                      navigate("/login", { replace: true });
                    } else {
                      notificationService.error(
                        intl.formatMessage({ id: "reset_password.error_notification" }),
                        {
                          autohide: false,
                        },
                      );
                    }

                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <h1 className="mb-24">
                    <FormattedMessage id="reset_password.title" />
                  </h1>
                  {/* eslint-disable-next-line react/no-danger */}
                  <p className="mb-24" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "reset_password.text" }) }} />

                  <Form.Group className="mb-24" controlId="password">
                    <Form.Label className="h5">
                      <FormattedMessage id="reset_password.password_label" />
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder={intl.formatMessage({ id: "reset_password.password_placeholder" })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      autoFocus
                    />

                    {errors.password && touched.password && (
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-24" controlId="passwordRepeat">
                    <Form.Label className="h5">
                      <FormattedMessage id="reset_password.password_repeat_label" />
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="passwordRepeat"
                      placeholder={intl.formatMessage({ id: "reset_password.password_repeat_label" })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.passwordRepeat}
                    />

                    {errors.passwordRepeat && touched.passwordRepeat && (
                      <Form.Control.Feedback type="invalid">
                        {errors.passwordRepeat}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <IconButton
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {intl.formatMessage({ id: "reset_password.save" })}
                    {resetLoading && (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                    {resetSuccess && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    {resetFail && (
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    )}
                  </IconButton>
                </form>
              )}
            </Formik>
          )}
        </Col>
      </Row>
    </div>
  );
};

ResetPassword.propTypes = {
  resetDispatch: PropTypes.func.isRequired,
  resetLoading: PropTypes.bool,
  resetSuccess: PropTypes.bool,
  resetFail: PropTypes.bool,
};

ResetPassword.defaultProps = {
  resetLoading: false,
  resetSuccess: false,
  resetFail: false,
};

const mapStateToProps = ({ forgotPassword }) => ({
  resetLoading: forgotPassword.resetLoading,
  resetSuccess: forgotPassword.resetSuccess,
  resetFail: forgotPassword.resetFail,
});

const mapDispatch = {
  resetDispatch: reset,
};

export default connect(mapStateToProps, mapDispatch)(ResetPassword);
