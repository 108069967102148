import React from "react";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { Formik } from "formik";
import * as yup from "yup";

import { request } from "../../state/features/ForgotPasswordSlice";
import IconButton from "../../components/IconButton/IconButton";

const ForgotPassword = (props) => {
  const {
    requestDispatch,
    requestLoading,
    requestSuccess,
    requestFail,
  } = props;

  const intl = useIntl();

  const validationSchema = yup.object().shape({
    email: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .email(intl.formatMessage({ id: "form.error.email" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
  });

  return (
    <div className="login">
      <Row>
        <Col md={{ span: 4, offset: 4 }}>
          {requestSuccess ? (
            <>
              <h1 className="mb-24">
                <FormattedMessage id="forgot_password.success_title" />
              </h1>
              <p className="mb-24">
                <FormattedMessage id="forgot_password.success_text" />
              </p>
            </>
          ) : (
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                requestDispatch(values.email)
                  .then(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <h1 className="mb-24">
                    <FormattedMessage id="forgot_password.title" />
                  </h1>
                  <p className="mb-24">
                    <FormattedMessage id="forgot_password.text" />
                  </p>

                  <Form.Group className="mb-24" controlId="email">
                    <Form.Label className="h5">
                      <FormattedMessage id="forgot_password.email_label" />
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder={intl.formatMessage({ id: "forgot_password.email_placeholder" })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      autoFocus
                    />

                    {errors.email && touched.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <IconButton
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {intl.formatMessage({ id: "forgot_password.request" })}
                    {requestLoading && (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                    {requestSuccess && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    {requestFail && (
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    )}
                  </IconButton>
                </form>
              )}
            </Formik>
          )}
        </Col>
      </Row>
    </div>
  );
};

ForgotPassword.propTypes = {
  requestDispatch: PropTypes.func.isRequired,
  requestLoading: PropTypes.bool,
  requestSuccess: PropTypes.bool,
  requestFail: PropTypes.bool,
};

ForgotPassword.defaultProps = {
  requestLoading: false,
  requestSuccess: false,
  requestFail: false,
};

const mapStateToProps = ({ forgotPassword }) => ({
  requestLoading: forgotPassword.requestLoading,
  requestSuccess: forgotPassword.requestSuccess,
  requestFail: forgotPassword.requestFail,
});

const mapDispatch = {
  requestDispatch: request,
};

export default connect(mapStateToProps, mapDispatch)(ForgotPassword);
