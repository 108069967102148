import React, { memo } from "react";

import PropTypes from "prop-types";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import "./Hint.scss";

const Hint = ({ text }) => (
  <OverlayTrigger
    placement="top"
    overlay={(
      <Tooltip>
        {text}
      </Tooltip>
    )}
  >
    <span className="hint">
      <FontAwesomeIcon icon={faQuestionCircle} />
    </span>
  </OverlayTrigger>
);

Hint.propTypes = {
  text: PropTypes.string.isRequired,
};

export default memo(Hint);
