import React, { useEffect, useState } from "react";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as yup from "yup";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";

import IconButton from "../../components/IconButton/IconButton";
import { login, resetLogin } from "../../state/features/AuthenticationSlice";

const Login = (props) => {
  const {
    loginDispatch,
    resetLoginDispatch,
    loginLoading,
    loginSuccess,
    loginFail,
  } = props;

  const navigate = useNavigate();
  const [loginFailed, setLoginFailed] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);
  const [totp, setTotp] = useState("");
  const [totpFailed, setTotpFailed] = useState(false);
  const [credentials, setCredentials] = useState({});

  const intl = useIntl();

  useEffect(() => {
    resetLoginDispatch();
  }, [resetLoginDispatch]);

  const hideTotpModal = () => {
    setShowTotpModal(false);
  };

  const submitLogin = (values, onSuccess, onFailure) => {
    loginDispatch(values)
      .then((action) => {
        if (action.type.endsWith("SUCCESS")) {
          setLoginFailed(false);
          navigate("/portal/calendar", { replace: true });
        } else if (action.error.response.data.message === "totp required") {
          setLoginFailed(false);
          setShowTotpModal(true);
          resetLoginDispatch();
        } else if (action.error.response.data.message === "totp failed") {
          setLoginFailed(true);
          setTotpFailed(true);
        } else {
          setLoginFailed(true);
          onSuccess();
        }
      })
      .catch(() => {
        onFailure();
      });
  };

  const validationSchema = yup.object().shape({
    username: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    password: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
  });

  return (
    <div className="login">
      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setCredentials(values);

          submitLogin(values, () => {
            setSubmitting(false);
          }, () => {
            setSubmitting(false);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>

            <Row>
              <Col md={{ span: 4, offset: 4 }}>
                <h1 className="mb-24">Login für Mitarbeiter</h1>

                <Form.Group className="mb-24" controlId="username">
                  <Form.Label className="h5">E-Mail Adresse</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="Ihre E-Mail Adresse"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    autoFocus
                  />

                  {errors.username && touched.username && (
                    <Form.Control.Feedback type="invalid">
                      {errors.username}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-24" controlId="password">
                  <Form.Label className="h5">Passwort</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Ihr Passwort"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />

                  {errors.password && touched.password && (
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                {loginFailed && (
                  <Alert variant="warning" className="mb-24">
                    <FormattedMessage id="login.wrong_credentials" />
                  </Alert>
                )}

                <IconButton
                  variant="primary"
                  type="submit"
                  disabled={loginLoading}
                >
                  {intl.formatMessage({ id: "login.login" })}
                  {loginLoading && (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  )}
                  {loginSuccess && (
                    <FontAwesomeIcon icon={faCheck} />
                  )}
                  {loginFail && (
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  )}
                </IconButton>
                <br />
                <NavLink className="text-secondary d-inline-block mt-8" to="/forgot-password">
                  Passwort vergessen?
                </NavLink>
              </Col>
            </Row>

          </form>
        )}
      </Formik>

      {showTotpModal && (
        <Modal show onHide={hideTotpModal} centered>
          <Modal.Header closeButton>
            <Modal.Title><FormattedMessage id="login.totp_title" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <FormattedMessage id="login.totp_text" />
            </p>

            <Form.Control
              type="text"
              name="totp"
              placeholder={intl.formatMessage({ id: "login.totp_placeholder" })}
              className="mb-24"
              onChange={(event) => {
                setTotp(event.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  submitLogin(
                    { totp, ...credentials },
                    () => {},
                    () => {},
                  );
                }
              }}
              autoFocus
            />

            {totpFailed && (
              <Alert variant="warning">
                <FormattedMessage id="login.totp_failed" />
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <IconButton
              variant="primary"
              onClick={() => {
                submitLogin(
                  { totp, ...credentials },
                  () => {},
                  () => {},
                );
              }}
              disabled={totp === ""}
            >
              {intl.formatMessage({ id: "login.totp_login" })}
              {loginLoading && (
                <FontAwesomeIcon icon={faSpinner} spin />
              )}
              {loginSuccess && (
                <FontAwesomeIcon icon={faCheck} />
              )}
              {loginFail && (
                <FontAwesomeIcon icon={faExclamationTriangle} />
              )}
            </IconButton>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

Login.propTypes = {
  loginDispatch: PropTypes.func.isRequired,
  resetLoginDispatch: PropTypes.func.isRequired,
  loginLoading: PropTypes.bool,
  loginSuccess: PropTypes.bool,
  loginFail: PropTypes.bool,
};

Login.defaultProps = {
  loginLoading: false,
  loginSuccess: false,
  loginFail: false,
};

const mapStateToProps = ({ authentication }) => ({
  loginLoading: authentication.loginLoading,
  loginSuccess: authentication.loginSuccess,
  loginFail: authentication.loginFail,
});

const mapDispatch = {
  loginDispatch: login,
  resetLoginDispatch: resetLogin,
};

export default connect(mapStateToProps, mapDispatch)(Login);
