import { createSlice } from "@reduxjs/toolkit";

const AppointmentStatisticSlice = createSlice({
  name: "appointmentStatistic",
  initialState: {
    appointmentStatistics: [],
    appointmentStatisticsLoading: false,
  },
  reducers: {
    fetchDay: (state) => ({
      ...state,
      appointmentStatistics: [],
      appointmentStatisticsLoading: true,
    }),
    fetchDay_SUCCESS: (state, { payload }) => ({
      ...state,
      appointmentStatistics: payload.data.appointmentStatistics,
      appointmentStatisticsLoading: false,
    }),
    fetchDay_FAIL: (state) => ({
      ...state,
      appointmentStatistics: [],
      appointmentStatisticsLoading: false,
    }),
  },
});

export default AppointmentStatisticSlice.reducer;

export const fetchDay = (date) => ({
  type: "appointmentStatistic/fetchDay",
  payload: {
    client: "default",
    request: {
      url: `portal/appointment-statistic/day-details/${date}`,
    },
  },
});
