import logoDubidoc from "./logo-dubidoc.svg";
import logoDubidocWhite from "./logo-dubidoc-white.png";
import logoNoventiWhite from "./logo-noventi-white.png";
import logoNoventiDubidoc from "./logo-noventi-dubidoc.png";
import mapMarker from "./map-marker.png";
import placeholderLogo from "./placeholder-logo.png";

const Images = {
  logoDubidoc,
  logoDubidocWhite,
  logoNoventiWhite,
  logoNoventiDubidoc,
  mapMarker,
  placeholderLogo,
};

export default Images;
