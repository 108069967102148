import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Imprint = () => (
  <Row>
    <Col md={{ span: 10, offset: 1 }}>
      <h1>Impressum</h1>

      <p>
        Takuta GmbH<br />
        Rellinghauser Str. 111<br />
        45128 Essen<br />
        Deutschland
      </p>

      <p>
        HR-Nummer HRB 26990<br />
        Amtsgericht Essen
      </p>

      <p>
        Umsatzsteuer ID-Nr.: DE119260415
      </p>

      <p>
        Geschäftsführer: Dr. med. Shabnam Fahimi-Weber
      </p>

      <p>
        Tel: 0201 / 83 88 60-31<br />
        Mail: office@dubidoc.de
      </p>

      <p>
        Alle Inhalte, sowie Texte, Bilder, Layout-, Schrift- und Farbgestaltung
        auf unserer Seiten unterliegen dem Schutz des Urheberrechts.
      </p>

      <p>
        Trotz sorgfältiger inhaltlicher Kontrolle wird keine Haftung für die
        Inhalte externer Links übernommen. Für den Inhalt der verlinkten Seiten
        sind ausschließlich deren Betreiber verantwortlich.
      </p>
    </Col>
  </Row>
);

export default Imprint;
