import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import PortalLayout from "../../components/PortalLayout/PortalLayout";
import Calendar from "./Calendar/Calendar";
import Dashboard from "./Dashboard/Dashboard";
import AppointmentTypes from "./AppointmentTypes/AppointmentTypes";
import AppointmentTypesBudgeting from "./AppointmentTypesBudgeting/AppointmentTypesBudgeting";
import AppointmentTypeEdit from "./AppointmentTypes/AppointmentTypeEdit";
import BaseDataEdit from "./BaseData/BaseDataEdit";
import BlockingTimeWizard from "./BlockingTimes/BlockingTimeWizard";
import WorkingTimes from "./WorkingTimes/WorkingTimes";
import WorkingTimeWizard from "./WorkingTimes/WorkingTimeWizard";
import Profile from "./Profile/Profile";
import WorkingTimeEdit from "./WorkingTimes/WorkingTimeEdit";
import Users from "./User/Users";
import UserEdit from "./User/UserEdit";
import BlockingTimeEdit from "./BlockingTimes/BlockingTimeEdit";
import AppointmentTypeStatistic from "./Statistic/AppointmentTypeStatistic";
import AppointmentStatistic from "./Statistic/AppointmentStatistic";
import Help from "./Help/Help";

const Portal = () => (
  <Routes>
    <Route element={<PortalLayout />}>
      <Route exact index element={<Navigate to="/portal/dashboard" />} />
      <Route exact path="dashboard" element={<Dashboard />} />
      <Route exact path="calendar" element={<Calendar />} />
      <Route path="appointment-type/*">
        <Route exact index element={<AppointmentTypes />} />
        <Route exact path="new" element={<AppointmentTypeEdit />} />
        <Route exact path="edit/:ulid" element={<AppointmentTypeEdit />} />
      </Route>
      <Route exact path="appointment-type-budgeting" element={<AppointmentTypesBudgeting />} />
      <Route exact path="base-data" element={<BaseDataEdit />} />
      <Route path="working-time/*">
        <Route exact index element={<WorkingTimes />} />
        <Route exact path="wizard" element={<WorkingTimeWizard />} />
        <Route exact path="edit/:ulid" element={<WorkingTimeEdit />} />
        <Route exact path="edit/set/:ulid" element={<WorkingTimeEdit editSet />} />
      </Route>
      <Route path="blocking-time/*">
        <Route exact path="wizard" element={<BlockingTimeWizard />} />
        <Route exact path="edit/:ulid" element={<BlockingTimeEdit />} />
        <Route exact path="edit/set/:ulid" element={<BlockingTimeEdit editSet />} />
      </Route>
      <Route exact path="profile" element={<Profile />} />
      <Route path="user/*">
        <Route exact index element={<Users />} />
        <Route exact path="new" element={<UserEdit />} />
        <Route exact path="edit/:ulid" element={<UserEdit />} />
      </Route>
      <Route exact path="appointment-type-statistics" element={<AppointmentTypeStatistic />} />
      <Route exact path="appointment-statistics" element={<AppointmentStatistic />} />
      <Route exact path="help" element={<Help />} />
    </Route>
  </Routes>
);

export default Portal;
