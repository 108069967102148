import React, { useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faExclamationTriangle, faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { Formik } from "formik";
import * as yup from "yup";

import { fetchUnregistered, register, reset } from "../../state/features/ClientSlice";
import Loading from "../../components/Loading/Loading";
import Panel from "../../components/Panel/Panel";
import IconButton from "../../components/IconButton/IconButton";
import FileInput from "../../components/FileInput/FileInput";

const ClientRegister = (props) => {
  const {
    fetchUnregisteredDispatch,
    registerDispatch,
    resetDispatch,
    unregisteredClient,
    clientLoading,
    registerLoading,
    registerSuccess,
    registerFail,
  } = props;

  const {
    ulid,
  } = useParams();

  const intl = useIntl();

  useEffect(() => {
    resetDispatch();

    fetchUnregisteredDispatch(ulid);
  }, [resetDispatch, fetchUnregisteredDispatch, ulid]);

  if (registerSuccess) {
    return (
      <Row>
        <Col md={{ span: 6, offset: 3 }} className="pt-40">
          <h1 className="mb-24">Registrierung erfolgreich übermittelt</h1>
          <div className="p-24 d-flex align-items-center bg-white">
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "4rem" }}
              className="me-24"
            />
            <div>
              <h3 className="mb-8">Vielen Dank für Ihre Registrierung!</h3>
              <p>
                Unser Support-Team wird Ihre Registrierung schnellstmöglich bearbeiten.<br />
                Wir werden Sie per E-Mail benachrichtigen, sobald Ihre Angaben geprüft
                und Ihr Zugang freigeschaltet wurde.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  if (clientLoading || unregisteredClient === null) {
    return <Loading />;
  }

  const validationSchema = yup.object().shape({
    name: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    ikNumber: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .min(7, intl.formatMessage({ id: "form.error.invalid_value" }))
      .max(9, intl.formatMessage({ id: "form.error.invalid_value" })),
    street: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    houseNumber: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(12, intl.formatMessage({ id: "form.error.max_length" }, { value: 12 })),
    zip: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(5, intl.formatMessage({ id: "form.error.max_length" }, { value: 5 })),
    city: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    country: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    firstname: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    lastname: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    phone: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(20, intl.formatMessage({ id: "form.error.max_length" }, { value: 20 })),
    email: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 }))
      .email(intl.formatMessage({ id: "form.error.email" })),
    userFirstname: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    userLastname: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    userEmail: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 }))
      .email(intl.formatMessage({ id: "form.error.email" })),
    password: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    passwordRepeat: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
  });

  return (
    <Formik
      initialValues={{
        name: unregisteredClient.name || "",
        ikNumber: "",
        street: "",
        houseNumber: "",
        zip: "",
        city: "",
        country: "de",
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        approve: null,
        userFirstname: "",
        userLastname: "",
        userEmail: "",
        password: "",
        passwordRepeat: "",
      }}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors = {};

        const {
          password,
          passwordRepeat,
        } = values;

        if (/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*\-_"§/()=?;,:.<>'+]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(password) === false) {
          errors.password = intl.formatMessage({ id: "form.error.password_complexity" });
        }

        if (password !== passwordRepeat) {
          errors.passwordRepeat = intl.formatMessage({ id: "form.error.password_repeat" });
        }

        // Todo: check file(s) size

        return errors;
      }}
      onSubmit={(values) => {
        const tmpValues = { ...values };
        delete tmpValues.passwordRepeat;

        registerDispatch(ulid, tmpValues);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={{ span: 6, offset: 3 }} className="pt-40">
              <h1 className="mb-24">Registrierung für Apotheken</h1>

              <h3 className="mb-16">Name der Apotheke</h3>
              <Form.Group className="mb-32">
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Name der Apotheke*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <h3 className="mb-16">Adresse</h3>
              <Row className="mb-32">
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="street"
                    type="text"
                    placeholder="Straße*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.street}
                  />
                  {errors.street && touched.street && (
                    <Form.Control.Feedback type="invalid">
                      {errors.street}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="houseNumber"
                    type="text"
                    placeholder="Hausnummer*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.houseNumber}
                  />
                  {errors.houseNumber && touched.houseNumber && (
                    <Form.Control.Feedback type="invalid">
                      {errors.houseNumber}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="zip"
                    type="text"
                    placeholder="PLZ*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zip}
                  />
                  {errors.zip && touched.zip && (
                    <Form.Control.Feedback type="invalid">
                      {errors.zip}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="city"
                    type="text"
                    placeholder="Ort*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                  {errors.city && touched.city && (
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Select
                    name="country"
                    placeholder="Land*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                  >
                    <option value="de">Deutschland</option>
                  </Form.Select>
                  {errors.country && touched.country && (
                    <Form.Control.Feedback type="invalid">
                      {errors.country}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <h3 className="mb-16">IK-Nummer</h3>
              <Row className="mb-32">
                <Form.Group as={Col} md="6">
                  <Form.Control
                    name="ikNumber"
                    type="text"
                    placeholder="IK-Nummer*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ikNumber}
                  />
                  {errors.ikNumber && touched.ikNumber && (
                    <Form.Control.Feedback type="invalid">
                      {errors.ikNumber}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <h3 className="mb-16">Kontaktdaten</h3>
              <Row className="mb-32">
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="firstname"
                    type="text"
                    placeholder="Vorname*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstname}
                  />
                  {errors.firstname && touched.firstname && (
                    <Form.Control.Feedback type="invalid">
                      {errors.firstname}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="lastname"
                    type="text"
                    placeholder="Nachname*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastname}
                  />
                  {errors.lastname && touched.lastname && (
                    <Form.Control.Feedback type="invalid">
                      {errors.lastname}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-24 mb-md-0">
                  <Form.Control
                    name="phone"
                    type="text"
                    placeholder="Telefonnummer*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  {errors.phone && touched.phone && (
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Control
                    name="email"
                    type="text"
                    placeholder="E-Mail-Adresse*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <h3 className="mb-16">Nachweise</h3>
              <Panel small className="mb-24">
                <strong>Hinweis:</strong><br />
                Um Missbrauch zu verhindern, benötigen wir Ihre Bescheinigung über
                Ihre Impfberechtigung der zuständigen LAK. Ihr Dokument wird
                selbstverständlich absolut vertraulich behandelt. Erst wenn
                uns dies vorliegt, sind Sie berechtigt Online-Termine anzubieten.
              </Panel>
              <FileInput
                id="approve"
                className="mb-32"
                isInvalid={!!(errors.approve && touched.approve)}
                multiple
                mimeType="application/pdf,image/*"
                onChange={(files) => {
                  setFieldValue("approve", files);
                }}
              >
                Dokumente hier ablegen oder <u>Computer durchsuchen</u>
              </FileInput>
              {errors.approve && touched.approve && (
                <Form.Control.Feedback type="invalid">
                  {errors.approve}
                </Form.Control.Feedback>
              )}

              <h3 className="mb-16">Anlegen Ihres Benutzerkontos</h3>
              <Row className="mb-32">
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="userFirstname"
                    type="text"
                    placeholder="Ihr Vorname*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userFirstname}
                  />
                  {errors.userFirstname && touched.userFirstname && (
                    <Form.Control.Feedback type="invalid">
                      {errors.userFirstname}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="userLastname"
                    type="text"
                    placeholder="Ihr Nachname*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userLastname}
                  />
                  {errors.userLastname && touched.userLastname && (
                    <Form.Control.Feedback type="invalid">
                      {errors.userLastname}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="userEmail"
                    type="text"
                    placeholder="E-Mail-Adresse (=Benutzername)*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userEmail}
                  />
                  {errors.userEmail && touched.userEmail && (
                    <Form.Control.Feedback type="invalid">
                      {errors.userEmail}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Col md={6} />
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Passwort festlegen*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {errors.password && touched.password && (
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="passwordRepeat"
                    type="password"
                    placeholder="Passwort wiederholen*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordRepeat}
                  />
                  {errors.passwordRepeat && touched.passwordRepeat && (
                    <Form.Control.Feedback type="invalid">
                      {errors.passwordRepeat}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <div>
                <IconButton
                  disabled={registerLoading}
                  type="submit"
                >
                  Registrieren
                  {registerLoading && (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  )}
                  {registerSuccess && (
                    <FontAwesomeIcon icon={faCheck} />
                  )}
                  {registerFail && (
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  )}
                </IconButton>
              </div>

            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};

ClientRegister.propTypes = {
  fetchUnregisteredDispatch: PropTypes.func.isRequired,
  registerDispatch: PropTypes.func.isRequired,
  resetDispatch: PropTypes.func.isRequired,
  unregisteredClient: PropTypes.oneOfType([PropTypes.object]),
  clientLoading: PropTypes.bool,
  registerLoading: PropTypes.bool,
  registerSuccess: PropTypes.bool,
  registerFail: PropTypes.bool,
};

ClientRegister.defaultProps = {
  unregisteredClient: null,
  clientLoading: false,
  registerLoading: false,
  registerSuccess: false,
  registerFail: false,
};

const mapStateToProps = ({ client }) => ({
  unregisteredClient: client.unregisteredClient,
  clientLoading: client.loading,
  registerLoading: client.registerLoading,
  registerSuccess: client.registerSuccess,
  registerFail: client.registerFail,
});

const mapDispatch = {
  fetchUnregisteredDispatch: fetchUnregistered,
  registerDispatch: register,
  resetDispatch: reset,
};

export default connect(mapStateToProps, mapDispatch)(ClientRegister);
