import React from "react";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FormattedMessage } from "react-intl";

import "./Footer.scss";

const Footer = () => (
  <Navbar bg="white" variant="light" className="footer mt-auto">
    <Container fluid="xxl" className="d-block">
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <Navbar.Collapse>
            <Nav>
              <NavLink className="nav-link" to="/help">
                <FormattedMessage id="footer_navigation.help" />
              </NavLink>
              <NavLink className="nav-link" to="/contact">
                <FormattedMessage id="footer_navigation.contact" />
              </NavLink>
              <NavLink className="nav-link" to="/data-protection">
                <FormattedMessage id="footer_navigation.data_protection" />
              </NavLink>
              <NavLink className="nav-link" to="/terms-and-conditions">
                <FormattedMessage id="footer_navigation.terms_and_conditions" />
              </NavLink>
              <NavLink className="nav-link" to="/imprint">
                <FormattedMessage id="footer_navigation.imprint" />
              </NavLink>
              <NavLink className="nav-link" to="/login">
                <FormattedMessage id="footer_navigation.login" />
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Col>
      </Row>
    </Container>
  </Navbar>
);

export default Footer;
