import { createSlice } from "@reduxjs/toolkit";

const AppointmentSearchSlice = createSlice({
  name: "appointmentSearch",
  initialState: {
    slots: [],
    loading: false,
  },
  reducers: {
    findSlots: (state) => ({
      ...state,
      slots: [],
      loading: true,
    }),
    findSlots_SUCCESS: (state, { payload }) => ({
      ...state,
      slots: payload.data.slots,
      loading: false,
    }),
    findSlots_FAIL: (state) => ({
      ...state,
      slots: [],
      loading: false,
    }),
  },
});

export default AppointmentSearchSlice.reducer;

export const findSlots = (appointmentTypeUlid, startDate) => ({
  type: "appointmentSearch/findSlots",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        appointmentTypeUlid,
        startDate,
      },
      url: "/appointment-search/find-slots",
    },
  },
});
