import React, { memo } from "react";

import PropTypes from "prop-types";
import Select from "react-select";

import FormRow from "./FormRow";

const FormRowText = (props) => {
  const {
    id,
    label,
    defaultValue,
    options,
    handleChange,
    handleBlur,
    errors,
    touched,
    hint,
    className,
    size,
    labelSize,
    ...otherProps
  } = props;

  return (
    <FormRow
      id={id}
      label={label}
      errors={errors}
      touched={touched}
      hint={hint}
      size={size}
      labelSize={labelSize}
      className={className}
    >
      <Select
        defaultValue={options.filter((option) => (option.value === defaultValue))}
        placeholder="Bitte auswählen"
        options={options}
        isSearchable={false}
        onBlur={handleBlur}
        onChange={(event) => {
          handleChange({
            target: {
              type: "change",
              id,
              name: id,
              value: event.value,
            },
          });
        }}
        {...otherProps}
      />
    </FormRow>
  );
};

FormRowText.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.any]).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  hint: PropTypes.string,
  className: PropTypes.string,
  labelSize: PropTypes.number,
  size: PropTypes.number,
};

FormRowText.defaultProps = {
  errors: null,
  touched: false,
  hint: null,
  className: "",
  labelSize: 4,
  size: 7,
};

export default memo(FormRowText);
