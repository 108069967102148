import React, { useState } from "react";

import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTimes, faCheck } from "@fortawesome/pro-light-svg-icons";

import "./Copy.scss";

const Copy = ({ text }) => {
  const [success, setSuccess] = useState(null);
  let timeout;

  const copyFallback = () => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      setSuccess(successful);
    } catch (err) {
      setSuccess(false);
    }

    timeout = setTimeout(() => {
      setSuccess(null);
    }, 1000);

    document.body.removeChild(textArea);
  };

  const handleCopy = async () => {
    clearTimeout(timeout);

    if (!navigator.clipboard) {
      copyFallback();

      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      setSuccess(true);
    } catch (err) {
      setSuccess(false);
    } finally {
      timeout = setTimeout(() => {
        setSuccess(null);
      }, 1000);
    }
  };

  const getIcon = () => {
    switch (success) {
      case true:
        return <FontAwesomeIcon icon={faCheck} />;
      case false:
        return <FontAwesomeIcon icon={faTimes} />;
      default:
        return <FontAwesomeIcon icon={faCopy} />;
    }
  };

  return (
    <span
      className="copy"
      onClick={handleCopy}
      onKeyPress={handleCopy}
      role="button"
      tabIndex="0"
    >
      {getIcon()}
    </span>
  );
};

Copy.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Copy;
