import React, { useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import IconButton from "../../../components/IconButton/IconButton";
import {
  fetchTotpSecret,
  removeTotpSecret, toggleTotp,
} from "../../../state/features/ProfileSlice";

const TotpModal = (props) => {
  const {
    isEnabled,
    fetchTotpSecretDispatch,
    removeTotpSecretDispatch,
    toggleTotpDispatch,
    totpSecretLoading,
    totpSecretSuccess,
    totpSecretFail,
    totpSecret,
    toggleTotpLoading,
    toggleTotpSuccess,
    toggleTotpFail,
    className,
  } = props;

  const [showTotpModal, setShowTotpModal] = useState(false);
  const [password, setPassword] = useState(null);
  const [totp, setTotp] = useState(null);

  const hideTotpModal = () => {
    removeTotpSecretDispatch();
    setPassword(null);
    setTotp(null);
    setShowTotpModal(false);
  };

  const submitPassword = () => {
    fetchTotpSecretDispatch(password)
      .then(() => {
        setTimeout(removeTotpSecretDispatch, 60000);
        setPassword(null);
      });
  };

  const submitTotp = () => {
    toggleTotpDispatch({ totp, enable: !isEnabled })
      .then(() => {
        hideTotpModal();
      });
  };

  return (
    <div className={className}>
      <Button
        onClick={() => {
          setShowTotpModal(true);
        }}
      >
        {isEnabled ? "Deaktivieren" : "Aktivieren"}
      </Button>

      <Modal
        show={showTotpModal}
        onHide={hideTotpModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>2FA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!totpSecret ? (
            <React.Fragment key="2fa-password">
              Um die 2-Faktor Authentifizierung aktivieren/deaktivieren zu
              können, müssen Sie zunächst Ihr aktuelles Password eingeben:

              <Form.Control
                type="password"
                id="password"
                placeholder="Passwort"
                className="mt-16 mb-8"
                autoFocus
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    submitPassword();
                  }
                }}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </React.Fragment>
          ) : (
            <React.Fragment key="2fa-totp">
              {!isEnabled && (
                <>
                  <div>
                    Bitte scannen Sie den QR-Code mit Ihrer 2FA-App ein und
                    geben den generierten Code in das darauf folgende Feld ein.<br />
                    Der generierte Code ist nur etwa eine Minute gültig!
                  </div>
                  <div className="text-center mb-16">
                    <img src={totpSecret} className="img-fluid" alt="TOTP Secret" />
                  </div>
                </>
              )}

              <Form.Control
                type="text"
                id="totp"
                placeholder="Einmal Passwort / Code"
                className="mb-8"
                autoFocus
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    submitTotp();
                  }
                }}
                onChange={(event) => {
                  setTotp(event.target.value);
                }}
              />

            </React.Fragment>
          )}

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={hideTotpModal}
          >
            Abbrechen
          </Button>
          {!totpSecret ? (
            <IconButton
              onClick={() => {
                submitPassword();
              }}
              disabled={password === null}
            >
              Weiter
              {totpSecretLoading && (
                <FontAwesomeIcon icon={faSpinner} spin />
              )}
              {totpSecretSuccess && (
                <FontAwesomeIcon icon={faCheck} />
              )}
              {totpSecretFail && (
                <FontAwesomeIcon icon={faExclamationTriangle} />
              )}
            </IconButton>
          ) : (
            <IconButton
              onClick={submitTotp}
            >
              {isEnabled ? "Deaktivieren" : "Aktivieren"}
              {toggleTotpLoading && (
                <FontAwesomeIcon icon={faSpinner} spin />
              )}
              {toggleTotpSuccess && (
                <FontAwesomeIcon icon={faCheck} />
              )}
              {toggleTotpFail && (
                <FontAwesomeIcon icon={faExclamationTriangle} />
              )}
            </IconButton>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

TotpModal.propTypes = {
  isEnabled: PropTypes.bool,
  className: PropTypes.string,
  fetchTotpSecretDispatch: PropTypes.func.isRequired,
  removeTotpSecretDispatch: PropTypes.func.isRequired,
  toggleTotpDispatch: PropTypes.func.isRequired,
  totpSecretLoading: PropTypes.bool,
  totpSecretSuccess: PropTypes.bool,
  totpSecretFail: PropTypes.bool,
  totpSecret: PropTypes.string,
  toggleTotpLoading: PropTypes.bool,
  toggleTotpSuccess: PropTypes.bool,
  toggleTotpFail: PropTypes.bool,
};

TotpModal.defaultProps = {
  isEnabled: false,
  className: null,
  totpSecretLoading: false,
  totpSecretSuccess: false,
  totpSecretFail: false,
  totpSecret: null,
  toggleTotpLoading: false,
  toggleTotpSuccess: false,
  toggleTotpFail: false,
};

const mapStateToProps = ({ profile }) => ({
  totpSecretLoading: profile.totpSecretLoading,
  totpSecretSuccess: profile.totpSecretSuccess,
  totpSecretFail: profile.totpSecretFail,
  totpSecret: profile.totpSecret,
  toggleTotpLoading: profile.toggleTotpLoading,
  toggleTotpSuccess: profile.toggleTotpSuccess,
  toggleTotpFail: profile.toggleTotpFail,
});

const mapDispatch = {
  fetchTotpSecretDispatch: fetchTotpSecret,
  removeTotpSecretDispatch: removeTotpSecret,
  toggleTotpDispatch: toggleTotp,
};

export default connect(mapStateToProps, mapDispatch)(TotpModal);
