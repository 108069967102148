import React, { Component } from "react";

import PropTypes from "prop-types";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
// import classNames from "classnames";
import {
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faSirenOn,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { notificationService, notificationType } from "../../services/NotificationService";

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
    };

    this.subscription = null;
  }

  componentDidMount() {
    const {
      id,
    } = this.props;

    this.subscription = notificationService.onNotification(id)
      .subscribe((notification) => {
        const {
          notifications,
        } = this.state;

        if (!notification.message) {
          const filteredNotifications = notifications.filter(((x) => x.keepAfterRouteChange));
          const tmpNotifications = [];

          filteredNotifications.forEach((x) => {
            const tmpNotification = x;
            delete tmpNotification.keepAfterRouteChange;
            tmpNotifications.push(tmpNotification);
          });

          this.setState({ notifications: tmpNotifications });
        }

        this.setState({ notifications: [...notifications, notification] });
      });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  getTitle = (type) => {
    switch (type) {
      case notificationType.error:
        return (
          <><FontAwesomeIcon icon={faExclamationCircle} className="text-danger me-8" /> Fehler</>
        );
      case notificationType.info:
        return (
          <><FontAwesomeIcon icon={faInfoCircle} className="text-info me-8" /> Info</>
        );
      case notificationType.warning:
        return (
          <><FontAwesomeIcon icon={faSirenOn} className="text-warning me-8" /> Warnung</>
        );
      default:
        return (
          <><FontAwesomeIcon icon={faCheckCircle} className="text-success me-8" /> Erfolgreich</>
        );
    }
  }

  removeNotification(notification) {
    const {
      notifications,
    } = this.state;

    this.setState({
      notifications: notifications.filter((item) => item !== notification),
    });
  }

  render() {
    const {
      notifications,
    } = this.state;

    if (notifications.length === 0) {
      return null;
    }

    return (
      <ToastContainer
        position="bottom-end"
        className="position-fixed p-24"
      >
        {notifications.map((notification) => (
          <Toast
            key={notification.id}
            delay={5000}
            autohide={notification.autohide || false}
            onClose={() => {
              this.removeNotification(notification);
            }}
          >
            <Toast.Header>
              <strong className="me-auto">{this.getTitle(notification.type)}</strong>
            </Toast.Header>
            <Toast.Body>{notification.message}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    );
  }
}

Notification.propTypes = {
  id: PropTypes.string,
};

Notification.defaultProps = {
  id: "default-notification",
};

export default Notification;
