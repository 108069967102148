import { createSlice } from "@reduxjs/toolkit";

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    appointmentTypeStatistics: [],
    appointmentTypeBudgetings: [],
    loading: false,
  },
  reducers: {
    fetch: (state) => ({
      ...state,
      appointmentTypeStatistics: [],
      appointmentTypeBudgetings: [],
      loading: true,
    }),
    fetch_SUCCESS: (state, { payload }) => ({
      ...state,
      appointmentTypeStatistics: payload.data.appointmentTypeStatistics,
      appointmentTypeBudgetings: payload.data.appointmentTypeBudgetings,
      loading: false,
    }),
    fetch_FAIL: (state) => ({
      ...state,
      appointmentTypeStatistics: [],
      appointmentTypeBudgetings: [],
      loading: false,
    }),
  },
});

export default DashboardSlice.reducer;

export const fetch = () => ({
  type: "dashboard/fetch",
  payload: {
    client: "default",
    request: {
      url: "portal/dashboard",
    },
  },
});
