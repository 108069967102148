import React, { memo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./Dot.scss";

const Dot = (props) => {
  const {
    className,
    color,
    small,
    ...otherProps
  } = props;

  return (
    <span
      className={classNames("dot", className, { "dot--small": small })}
      style={{ backgroundColor: color }}
      {...otherProps}
    />
  );
};

Dot.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  small: PropTypes.bool,
};

Dot.defaultProps = {
  className: "",
  color: null,
  small: false,
};

export default memo(Dot);
