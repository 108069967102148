import React, { useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import ClientCard from "../../components/ClientCard/ClientCard";
import Panel from "../../components/Panel/Panel";
import AppointmentSlots from "../../components/AppointmentSlots/AppointmentSlots";
import { reserveAppointment, setBookingData } from "../../state/features/AppointmentBookingSlice";
import IconButton from "../../components/IconButton/IconButton";

const AppointmentSearch = (props) => {
  const {
    reserveAppointmentDispatch,
    setBookingDataDispatch,
    client,
    appointmentTypes,
    slotsLoading,
  } = props;

  const intl = useIntl();

  const [selectedAppointmentType, setSelectedAppointmentType] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [firstSearchDay, setFirstSearchDay] = useState(null);

  return (
    <Row>
      <Col md={{ span: 4, order: 2 }}>
        <ClientCard
          logo={client.logo}
          name={client.name}
          street={client.street}
          houseNumber={client.houseNumber}
          zip={client.zip}
          city={client.city}
        />
      </Col>
      <Col md={{ span: 6, offset: 1 }} className="pt-32 pt-md-40">
        <h1 className="mb-24">
          <FormattedMessage id="appointment_booking.headline" />
        </h1>
        <p className="mb-24">
          {client.text}
        </p>

        <Form.Group as={Row} className="mb-16" controlId="appointmentType">
          <Form.Label column md="3">
            <FormattedMessage id="appointment_booking.appointment_type" />
          </Form.Label>
          <Col md="9">
            <Form.Select
              onChange={(event) => {
                setSelectedAppointmentType(
                  appointmentTypes
                    .filter((appointmentType) => appointmentType.ulid === event.target.value)
                    .pop() || null,
                );
              }}
            >
              <option disabled selected>Bitte Termingrund auswählen</option>
              {[...appointmentTypes].sort((a, b) => {
                if (a.managed === b.managed) {
                  return a.index - b.index;
                }

                return a.managed && !b.managed ? 1 : -1;
              }).map((appointmentType) => (
                <option
                  key={appointmentType.ulid}
                  value={appointmentType.ulid}
                >
                  {appointmentType.publicName
                    ? appointmentType.publicName
                    : intl.formatMessage({ id: appointmentType.name })}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>

        {selectedAppointmentType?.searchHint && (
          <Row>
            <Col md={{ span: 9, offset: 3 }}>
              <Panel small className="mb-24">
                {selectedAppointmentType.searchHint}
              </Panel>
            </Col>
          </Row>
        )}

        <Form.Group as={Row} className="mb-24" controlId="month">
          <Form.Label column md="3">
            <FormattedMessage id="appointment_booking.date" />
          </Form.Label>
          <Col md="9">
            <Form.Select
              onChange={(event) => {
                setSelectedMonth(parseInt(event.target.value, 10));
                const startDay = moment().add(parseInt(event.target.value, 10), "months");

                setFirstSearchDay(startDay);
              }}
            >
              <option value={0}>
                {intl.formatMessage({ id: "appointment_booking.dates_earliest_possible" })}
              </option>
              <option value={1}>
                {intl.formatMessage({ id: "appointment_booking.dates_1_month" })}
              </option>
              <option value={2}>
                {intl.formatMessage({ id: "appointment_booking.dates_2_month" })}
              </option>
              <option value={3}>
                {intl.formatMessage({ id: "appointment_booking.dates_3_month" })}
              </option>
              <option value={4}>
                {intl.formatMessage({ id: "appointment_booking.dates_4_month" })}
              </option>
              <option value={5}>
                {intl.formatMessage({ id: "appointment_booking.dates_5_month" })}
              </option>
              <option value={6}>
                {intl.formatMessage({ id: "appointment_booking.dates_6_month" })}
              </option>
            </Form.Select>
          </Col>
        </Form.Group>
        <div className="text-end">
          <IconButton
            disabled={selectedAppointmentType === null || slotsLoading}
            onClick={() => {
              const startDay = moment().add(selectedMonth, "months");

              setFirstSearchDay(startDay);
            }}
          >
            {intl.formatMessage({ id: "appointment_booking.search_appointment" })}
            {slotsLoading && (
              <FontAwesomeIcon icon={faSpinner} spin />
            )}
          </IconButton>
        </div>

        <div>
          {firstSearchDay && selectedAppointmentType && (
            <AppointmentSlots
              appointmentType={selectedAppointmentType}
              searchDay={firstSearchDay}
              onSelect={(dateTime) => {
                setBookingDataDispatch({
                  dateTime: dateTime.toISOString(),
                  appointmentType: selectedAppointmentType,
                  reservationExpireTime: moment().add(15, "minutes"),
                });
                reserveAppointmentDispatch(
                  selectedAppointmentType.ulid,
                  dateTime.toISOString(),
                ).then((action) => {
                  if (!action.type.endsWith("SUCCESS")) {
                    // Todo: Slot already in use?
                  }
                });
              }}
            />
          )}
        </div>

      </Col>
    </Row>
  );
};

AppointmentSearch.propTypes = {
  reserveAppointmentDispatch: PropTypes.func.isRequired,
  setBookingDataDispatch: PropTypes.func.isRequired,
  client: PropTypes.oneOfType([PropTypes.object]),
  appointmentTypes: PropTypes.oneOfType([PropTypes.array]),
  slotsLoading: PropTypes.bool,
};

AppointmentSearch.defaultProps = {
  client: null,
  appointmentTypes: [],
  slotsLoading: false,
};

const mapStateToProps = ({ appointmentSearch }) => ({
  slotsLoading: appointmentSearch.loading,
});

const mapDispatch = {
  reserveAppointmentDispatch: reserveAppointment,
  setBookingDataDispatch: setBookingData,
};

export default connect(mapStateToProps, mapDispatch)(AppointmentSearch);
