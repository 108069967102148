import React, { useState } from "react";

import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
} from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import "./AppointmentTimer.scss";

const AppointmentTimer = (props) => {
  const {
    dateTime,
    className,
  } = props;

  const [timer, setTimer] = useState("--:--");

  setTimeout(() => {
    const seconds = moment().diff(dateTime, "seconds");

    setTimer(
      `${Math.floor(seconds / 60).toString().padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`,
    );
  }, 1000);

  return (
    <div className={classNames("appointment-timer", className)}>
      <FontAwesomeIcon icon={faClock} />
      <div>
        <div className="mb-8"><FormattedMessage id="appointment_timer.in_appointment_since" /></div>
        <div className="h3">{timer} <FormattedMessage id="appointment_timer.minutes" /></div>
      </div>
    </div>
  );
};

AppointmentTimer.propTypes = {
  dateTime: PropTypes.string.isRequired,
  className: PropTypes.string,
};

AppointmentTimer.defaultProps = {
  className: "",
};

export default AppointmentTimer;
