import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

const notificationSubject = new Subject();
const defaultId = "default-notification";

export const notificationType = {
  error: "error",
  info: "info",
  success: "success",
  warning: "warning",
};

function clear(id = defaultId) {
  notificationSubject.next({ id });
}

function notification(notify) {
  const tmpNotification = notify;
  tmpNotification.id = notify.id || defaultId;
  notificationSubject.next(tmpNotification);
}

function error(message, options) {
  notification({
    type: notificationType.error,
    message,
    autohide: true,
    ...options,
  });
}

function info(message, options) {
  notification({
    type: notificationType.info,
    message,
    autohide: true,
    ...options,
  });
}

function success(message, options) {
  notification({
    type: notificationType.success,
    message,
    autohide: true,
    ...options,
  });
}

function warn(message, options) {
  notification({
    type: notificationType.warning,
    message,
    autohide: true,
    ...options,
  });
}

function onNotification(id = defaultId) {
  return notificationSubject.asObservable().pipe(filter((x) => x && x.id === id));
}

export const notificationService = {
  clear,
  error,
  info,
  notification,
  onNotification,
  success,
  warn,
};
