import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { Formik } from "formik";
import * as yup from "yup";
import { useIntl } from "react-intl";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import Panel from "../../../components/Panel/Panel";
import IconButton from "../../../components/IconButton/IconButton";
import Loading from "../../../components/Loading/Loading";
import { wizard, reset } from "../../../state/features/WorkingTimeSlice";
import FormRowText from "../../../components/FormRow/FormRowText";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { fetchAll } from "../../../state/features/AppointmentTypeSlice";
import FormRowDate from "../../../components/FormRow/FormRowDate";
import FormRowTime from "../../../components/FormRow/FormRowTime";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { notificationService } from "../../../services/NotificationService";

const WorkingTimeWizard = (props) => {
  const {
    wizardDispatch,
    fetchAllAppointmentTypesDispatch,
    resetDispatch,
    appointmentTypes,
    loading,
    wizardLoading,
    wizardSuccess,
    wizardFail,
  } = props;

  const intl = useIntl();

  const navigate = useNavigate();

  useEffect(() => {
    resetDispatch();
    fetchAllAppointmentTypesDispatch();
  }, [resetDispatch, fetchAllAppointmentTypesDispatch]);

  const validationSchema = yup.object().shape({
    dateFrom: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    dateTo: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    days: yup.array()
      .min(1, intl.formatMessage({ id: "form.error.required" })),
    startTime: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    endTime: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    appointmentCount: yup.number(intl.formatMessage({ id: "form.error.numeric" }))
      .required(intl.formatMessage({ id: "form.error.required" })),
    appointmentTypes: yup.array()
      .min(1, intl.formatMessage({ id: "form.error.required" })),
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/working-time", name: "client_navigation.working_time_calendar" },
          { to: "/portal/working-time/wizard", name: "client_navigation.working_time_new" },
        ]}
      />

      <h3 className="mb-16">Arbeitszeit anlegen</h3>

      <Formik
        initialValues={{
          dateFrom: "",
          dateTo: "",
          days: [],
          startTime: "",
          endTime: "",
          appointmentCount: 1,
          appointmentTypes: [],
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          const {
            dateFrom,
            dateTo,
            startTime,
            endTime,
          } = values;

          if (moment(dateFrom).isAfter(dateTo)) {
            errors.dateTo = intl.formatMessage({ id: "form.error.date_after" });
          }

          if (moment(startTime).isSameOrAfter(endTime)) {
            errors.endTime = intl.formatMessage({ id: "form.error.time_after" });
          }

          return errors;
        }}
        onSubmit={(values) => {
          wizardDispatch(values)
            .then((action) => {
              if (action.type.endsWith("SUCCESS")) {
                notificationService.success(`Es wurde(n) ${action.payload.data.count} Arbeitszeit(en) erfolgreich angelegt.`);
                navigate("/portal/working-time", { replace: true });
              }
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Wählen Sie den ersten und den letzten Tag an denen die
                Arbeitszeit angelegt werden soll.
              </Col>
              <Col md={9}>
                <Panel headline="Welchen Zeitraum soll die Arbeitszeit abdecken?">
                  <FormRowDate
                    id="dateFrom"
                    name="dateFrom"
                    label="Erstmalig*"
                    className="mb-24"
                    defaultValue={values.dateFrom}
                    errors={errors.dateFrom}
                    touched={touched.dateFrom}
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setFieldValue("dateFrom", value);
                    }}
                  />

                  <FormRowDate
                    id="dateTo"
                    name="dateTo"
                    label="Letztmalig*"
                    defaultValue={values.dateTo}
                    errors={errors.dateTo}
                    touched={touched.dateTo}
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setFieldValue("dateTo", value);
                    }}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Mehrfachauswahl möglich.
              </Col>
              <Col md={9}>
                <Panel headline="An welchen Wochentagen soll die Arbeitszeit gelten?">
                  <MultiSelect
                    onChange={(options) => { setFieldValue("days", options); }}
                    options={[
                      { label: "Montag", value: 1 },
                      { label: "Dienstag", value: 2 },
                      { label: "Mittwoch", value: 3 },
                      { label: "Donnerstag", value: 4 },
                      { label: "Freitag", value: 5 },
                      { label: "Samstag", value: 6 },
                      { label: "Sonntag", value: 7 },
                    ]}
                  />

                  {errors.days && touched.days && (
                    <Form.Control.Feedback type="invalid">
                      {errors.days}
                    </Form.Control.Feedback>
                  )}
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Wählen die Startzeit und die Endzeit der geplanten Arbeitzeit.
              </Col>
              <Col md={9}>
                <Panel headline="An welchen Stunden soll die Arbeitszeit gelten?">
                  <FormRowTime
                    id="startTime"
                    name="startTime"
                    label="Beginn*"
                    className="mb-24"
                    defaultValue={values.startTime}
                    errors={errors.startTime}
                    touched={touched.startTime}
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setFieldValue("startTime", value);
                    }}
                  />

                  <FormRowTime
                    id="endTime"
                    name="endTime"
                    label="Ende*"
                    defaultValue={values.endTime}
                    errors={errors.endTime}
                    touched={touched.endTime}
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setFieldValue("endTime", value);
                    }}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Es sind beliebig viele gleichzeitige Termine möglich. 1 = Ein Termin pro Startzeit
              </Col>
              <Col md={9}>
                <Panel headline="Wie viele Termine möchten Sie parallel anbieten?">
                  <FormRowText
                    id="appointmentCount"
                    name="appointmentCount"
                    label="Anzahl gleichzeitiger Termine"
                    defaultValue={values.appointmentCount}
                    errors={errors.appointmentCount}
                    touched={touched.appointmentCount}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={{ span: 9, offset: 3 }}>
                <Panel headline="Welche Terminarten sollen in dieser Arbeitszeit angeboten werden?">
                  <MultiSelect
                    onChange={(options) => { setFieldValue("appointmentTypes", options); }}
                    options={[...appointmentTypes]
                      .sort((a, b) => a.index - b.index)
                      .map((appointmentType) => ({
                        value: appointmentType.ulid,
                        label: intl.formatMessage({ id: appointmentType.name }),
                      }))}
                  />

                  {errors.appointmentTypes && touched.appointmentTypes && (
                    <Form.Control.Feedback type="invalid">
                      {errors.appointmentTypes}
                    </Form.Control.Feedback>
                  )}
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={{ span: 9, offset: 3 }}>
                <Panel small className="text-end">
                  <IconButton
                    disabled={wizardLoading}
                    type="submit"
                  >
                    Speichern
                    {wizardLoading && (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                    {wizardSuccess && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    {wizardFail && (
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    )}
                  </IconButton>
                </Panel>
              </Col>
            </Row>

          </form>
        )}
      </Formik>
    </>
  );
};

WorkingTimeWizard.propTypes = {
  wizardDispatch: PropTypes.func.isRequired,
  fetchAllAppointmentTypesDispatch: PropTypes.func.isRequired,
  resetDispatch: PropTypes.func.isRequired,
  appointmentTypes: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  loading: PropTypes.bool,
  wizardLoading: PropTypes.bool,
  wizardSuccess: PropTypes.bool,
  wizardFail: PropTypes.bool,
};

WorkingTimeWizard.defaultProps = {
  appointmentTypes: [],
  loading: false,
  wizardLoading: false,
  wizardSuccess: false,
  wizardFail: false,
};

const mapStateToProps = ({ appointmentType, workingTime }) => ({
  appointmentTypes: appointmentType.appointmentTypes,
  wizardLoading: workingTime.wizardLoading,
  wizardSuccess: workingTime.wizardSuccess,
  wizardFail: workingTime.wizardFail,
});

const mapDispatch = {
  wizardDispatch: wizard,
  fetchAllAppointmentTypesDispatch: fetchAll,
  resetDispatch: reset,
};

export default connect(mapStateToProps, mapDispatch)(WorkingTimeWizard);
