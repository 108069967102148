import React from "react";

import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import moment from "moment";
import "moment/locale/de";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ConfigureStore from "./state/ConfigureStore";
import messagesDe from "./translations/de.json";

if (typeof process.env.REACT_APP_SENTRY_DSN !== "undefined") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

const messages = {
  de: messagesDe,
};
let language = navigator.language.split(/[-_]/)[0];

// de fallback
if (typeof messages[language] === "undefined") {
  language = "de";
}

// fixme: use dayjs or similar instead
// the old moment library is used as it is also needed in some dependencies :(
moment.locale(language);

const { store, persistor } = ConfigureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider locale={language} messages={messages[language]}>
          <App />
        </IntlProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
