import React, { memo } from "react";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import Panel from "../Panel/Panel";

const Loading = ({ withoutPanel }) => {
  const Tag = withoutPanel ? "div" : Panel;

  return (
    <Tag className="text-center">
      <FontAwesomeIcon icon={faSpinner} spin /> <FormattedMessage id="loading.loading" />
    </Tag>
  );
};

Loading.propTypes = {
  withoutPanel: PropTypes.bool,
};

Loading.defaultProps = {
  withoutPanel: false,
};

export default memo(Loading);
