import React, { memo } from "react";

import PropTypes from "prop-types";

import MobilePhoneInput from "../MobilePhoneInput/MobilePhoneInput";
import FormRow from "./FormRow";

const FormRowMobilePhone = (props) => {
  const {
    id,
    label,
    defaultValue,
    handleChange,
    handleBlur,
    errors,
    touched,
    hint,
    className,
    size,
    labelSize,
    ...otherProps
  } = props;

  return (
    <FormRow
      id={id}
      label={label}
      errors={errors}
      touched={touched}
      hint={hint}
      size={size}
      labelSize={labelSize}
      className={className}
    >
      <MobilePhoneInput
        name={id}
        handleChange={handleChange}
        handleBlur={handleBlur}
        defaultValue={defaultValue}
        {...otherProps}
      />
    </FormRow>
  );
};

FormRowMobilePhone.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.any]).isRequired,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  hint: PropTypes.string,
  className: PropTypes.string,
  labelSize: PropTypes.number,
  size: PropTypes.number,
};

FormRowMobilePhone.defaultProps = {
  errors: null,
  touched: false,
  hint: null,
  className: "",
  labelSize: 4,
  size: 7,
  handleChange: () => {},
  handleBlur: () => {},
};

export default memo(FormRowMobilePhone);
