import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  userLoading: false,
  changePasswordLoading: false,
  changePasswordSuccess: false,
  changePasswordFail: false,
  totpSecretLoading: false,
  totpSecretSuccess: false,
  totpSecretFail: false,
  totpSecret: null,
  toggleTotpLoading: false,
  toggleTotpSuccess: false,
  toggleTotpFail: false,
};

const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    fetch: (state) => ({
      ...state,
      userLoading: true,
    }),
    fetch_SUCCESS: (state, { payload }) => ({
      ...state,
      user: payload.data.user,
      userLoading: false,
    }),
    fetch_FAIL: (state) => ({
      ...state,
      user: null,
      userLoading: false,
    }),
    changePassword: (state) => ({
      ...state,
      changePasswordLoading: true,
      changePasswordSuccess: false,
      changePasswordFail: false,
    }),
    changePassword_SUCCESS: (state) => ({
      ...state,
      changePasswordLoading: false,
      changePasswordSuccess: true,
      changePasswordFail: false,
    }),
    changePassword_FAIL: (state) => ({
      ...state,
      changePasswordLoading: false,
      changePasswordSuccess: false,
      changePasswordFail: true,
    }),
    fetchTotpSecret: (state) => ({
      ...state,
      totpSecret: null,
      totpSecretLoading: true,
      totpSecretSuccess: false,
      totpSecretFail: false,
    }),
    fetchTotpSecret_SUCCESS: (state, { payload }) => ({
      ...state,
      totpSecret: payload.data.qrCode,
      totpSecretLoading: false,
      totpSecretSuccess: true,
      totpSecretFail: false,
    }),
    fetchTotpSecret_FAIL: (state) => ({
      ...state,
      totpSecret: null,
      totpSecretLoading: false,
      totpSecretSuccess: false,
      totpSecretFail: true,
    }),
    toggleTotp: (state) => ({
      ...state,
      toggleTotpLoading: true,
      toggleTotpSuccess: false,
      toggleTotpFail: false,
    }),
    toggleTotp_SUCCESS: (state, { payload }) => ({
      ...state,
      user: payload.data.user,
      toggleTotpLoading: false,
      toggleTotpSuccess: true,
      toggleTotpFail: false,
    }),
    toggleTotp_FAIL: (state) => ({
      ...state,
      toggleTotpLoading: false,
      toggleTotpSuccess: false,
      toggleTotpFail: true,
    }),
    removeTotpSecret: (state) => ({
      ...state,
      totpSecret: null,
      totpSecretLoading: false,
      totpSecretSuccess: false,
      totpSecretFail: false,
    }),
    reset: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const { removeTotpSecret, reset } = ProfileSlice.actions;

export default ProfileSlice.reducer;

export const fetch = () => ({
  type: "profile/fetch",
  payload: {
    client: "default",
    request: {
      url: "portal/profile",
    },
  },
});

export const changePassword = (data) => ({
  type: "profile/changePassword",
  payload: {
    client: "default",
    request: {
      method: "post",
      data,
      url: "portal/profile/password",
    },
  },
});

export const fetchTotpSecret = (password) => ({
  type: "profile/fetchTotpSecret",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        password,
      },
      url: "portal/profile/totp-secret",
    },
  },
});

export const toggleTotp = (data) => ({
  type: "profile/toggleTotp",
  payload: {
    client: "default",
    request: {
      method: "post",
      data,
      url: "portal/profile/totp",
    },
  },
});
