import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appointmentType: null,
  appointmentTypes: [],
  loading: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
};

const AppointmentTypeSlice = createSlice({
  name: "appointmentType",
  initialState,
  reducers: {
    fetchOne: (state) => ({
      ...state,
      loading: true,
      appointmentType: null,
    }),
    fetchOne_SUCCESS: (state, { payload }) => ({
      ...state,
      loading: false,
      appointmentType: payload.data.appointmentType,
    }),
    fetchOne_FAIL: (state) => ({
      ...state,
      loading: false,
      appointmentType: null,
    }),
    fetchAll: (state) => ({
      ...state,
      loading: true,
      appointmentTypes: [],
    }),
    fetchAll_SUCCESS: (state, { payload }) => ({
      ...state,
      loading: false,
      appointmentTypes: payload.data.appointmentTypes,
    }),
    fetchAll_FAIL: (state) => ({
      ...state,
      loading: false,
      appointmentTypes: [],
    }),
    edit: (state) => ({
      ...state,
      editLoading: true,
      editSuccess: false,
      editFail: false,
    }),
    edit_SUCCESS: (state) => ({
      ...state,
      editLoading: false,
      editSuccess: true,
      editFail: false,
    }),
    edit_FAIL: (state) => ({
      ...state,
      editLoading: false,
      editSuccess: false,
      editFail: true,
    }),
    reset: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const { reset } = AppointmentTypeSlice.actions;

export default AppointmentTypeSlice.reducer;

export const fetchOne = (ulid) => ({
  type: "appointmentType/fetchOne",
  payload: {
    client: "default",
    request: {
      url: `portal/appointment-type/details/${ulid}`,
    },
  },
});

export const fetchAll = () => ({
  type: "appointmentType/fetchAll",
  payload: {
    client: "default",
    request: {
      url: "portal/appointment-type",
    },
  },
});

export const toggle = (ulid, value) => ({
  type: "appointmentType/toggle",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        active: value,
      },
      url: `portal/appointment-type/toggle/${ulid}`,
    },
  },
});

export const edit = (ulid, data) => ({
  type: "appointmentType/edit",
  payload: {
    client: "default",
    request: {
      method: "post",
      data,
      url: ulid ? `portal/appointment-type/edit/${ulid}` : "portal/appointment-type/new",
    },
  },
});

export const remove = (ulid) => ({
  type: "appointmentType/remove",
  payload: {
    client: "default",
    request: {
      method: "delete",
      url: `portal/appointment-type/remove/${ulid}`,
    },
  },
});
