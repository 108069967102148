import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  faSpinner,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faDotCircle } from "@fortawesome/pro-regular-svg-icons";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { CSVLink } from "react-csv";

import { fetchDay } from "../../../state/features/AppointmentTypeStatisticSlice";
import IconButton from "../../../components/IconButton/IconButton";
import Dot from "../../../components/Dot/Dot";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

const AppointmentTypeStatistic = (props) => {
  const {
    fetchDayDispatch,
    appointmentTypeStatistics,
    appointmentTypeStatisticsLoading,
  } = props;

  const [date, setDate] = useState(moment());

  const intl = useIntl();

  useEffect(() => {
    fetchDayDispatch(date.format("YYYY-MM-DD"));
  }, [fetchDayDispatch, date]);

  const csvHeaders = [
    { label: "Impfstoff", key: "appointmentType" },
    { label: "6 bis 11 Jahre", key: "range1" },
    { label: "12 bis 17 Jahre", key: "range2" },
    { label: "18 bis 59 Jahre", key: "range3" },
    { label: "60 Jahre oder älter", key: "range4" },
    { label: "Gesamt", key: "total" },
  ];

  const csvData = appointmentTypeStatistics.map((appointmentTypeStatistic) => ({
    appointmentType: intl.formatMessage({ id: appointmentTypeStatistic.name }),
    range1: appointmentTypeStatistic.range1,
    range2: appointmentTypeStatistic.range2,
    range3: appointmentTypeStatistic.range3,
    range4: appointmentTypeStatistic.range4,
    total: appointmentTypeStatistic.total,
  }));

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/appointment-type-statistics", name: "client_navigation.appointment_type_statistic" },
        ]}
      />

      <h3 className="mb-16">Impf-Statistik</h3>

      <div className="mb-8 d-flex">
        <IconButton
          variant="white"
          className="me-4"
          onClick={() => {
            setDate(
              moment(date.subtract(1, "day")),
            );
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </IconButton>
        <Button
          variant="white"
          className="me-4"
          style={{ width: "230px" }}
        >
          {date.format("L")}
        </Button>
        <IconButton
          variant="white"
          className="me-8"
          onClick={() => {
            setDate(
              moment(date.add(1, "day")),
            );
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </IconButton>
        <IconButton
          variant="white"
          onClick={() => {
            setDate(moment());
          }}
        >
          <FontAwesomeIcon icon={faDotCircle} />
          Heute
        </IconButton>

        <IconButton
          size="sm"
          className="ms-auto"
          as={CSVLink}
          headers={csvHeaders}
          data={csvData}
          filename={`rki_export_${date.format("YYYY-MM-DD")}.csv`}
        >
          <FontAwesomeIcon icon={faFileAlt} fixedWidth />
          Export für RKI
        </IconButton>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Impfstoff</th>
            <th scope="col" className="text-center">6 bis 11 Jahre</th>
            <th scope="col" className="text-center">12 bis 17 Jahre</th>
            <th scope="col" className="text-center">18 bis 59 Jahre</th>
            <th scope="col" className="text-center">60 Jahre oder älter</th>
            <th scope="col" className="text-center">Gesamt</th>
          </tr>
        </thead>
        <tbody>
          {appointmentTypeStatisticsLoading && (
            <tr>
              <td colSpan={6} className="text-center py-24">
                <FontAwesomeIcon icon={faSpinner} spin />
              </td>
            </tr>
          )}
          {[...appointmentTypeStatistics]
            .sort((a, b) => a.index - b.index)
            .map((appointmentTypeStatistic) => {
              const {
                ulid,
                name,
                publicName,
                color,
                range1,
                range2,
                range3,
                range4,
                total,
              } = appointmentTypeStatistic;

              return (
                <tr key={ulid}>
                  <td className="d-flex align-items-center">
                    <Dot color={color} className="me-16" />
                    <div>
                      <FormattedMessage id={name} />
                      {publicName && (
                        <>
                          <br />
                          <small className="text-muted">{publicName}</small>
                        </>
                      )}
                    </div>
                  </td>
                  <td className="text-center">
                    {range1}
                  </td>
                  <td className="text-center">
                    {range2}
                  </td>
                  <td className="text-center">
                    {range3}
                  </td>
                  <td className="text-center">
                    {range4}
                  </td>
                  <td className="text-center">
                    {total}
                  </td>
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6} />
          </tr>
        </tfoot>
      </table>
    </>
  );
};

AppointmentTypeStatistic.propTypes = {
  fetchDayDispatch: PropTypes.func.isRequired,
  appointmentTypeStatistics: PropTypes.oneOfType([PropTypes.array]),
  appointmentTypeStatisticsLoading: PropTypes.bool,
};

AppointmentTypeStatistic.defaultProps = {
  appointmentTypeStatistics: [],
  appointmentTypeStatisticsLoading: false,
};

const mapStateToProps = ({ appointmentTypeStatistic }) => ({
  appointmentTypeStatistics: appointmentTypeStatistic.appointmentTypeStatistics,
  appointmentTypeStatisticsLoading: appointmentTypeStatistic.appointmentTypeStatisticsLoading,
});

const mapDispatch = {
  fetchDayDispatch: fetchDay,
};

export default connect(mapStateToProps, mapDispatch)(AppointmentTypeStatistic);
