import React, { useRef, useState } from "react";

import PropTypes from "prop-types";
import classNames from "classnames";

import "./FileInput.scss";

const FileInput = (props) => {
  const {
    id,
    children,
    onChange,
    isInvalid,
    mimeType,
    className,
    multiple,
    ...otherProps
  } = props;

  const fileInputRef = useRef(null);

  const [selectedFiles, setSelectedFiles] = useState(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    const { files } = fileInputRef.current;

    if (files.length) {
      setSelectedFiles(files);
      onChange(multiple ? files : files[0]);
    }
  };

  const dragOver = (event) => {
    event.preventDefault();

    return false;
  };

  const dragEnter = (event) => {
    event.preventDefault();

    return false;
  };

  const dragLeave = (event) => {
    event.preventDefault();

    return false;
  };

  const fileDrop = (event) => {
    event.preventDefault();
    const { files } = event.dataTransfer;

    if (files.length) {
      setSelectedFiles(files);
      onChange(multiple ? files : files[0]);
    }

    return false;
  };

  return (
    <div
      className={classNames("file-input", className, { "is-invalid": isInvalid })}
      onClick={handleClick}
      onKeyPress={handleClick}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
      role="button"
      tabIndex="0"
    >
      {children}
      {selectedFiles && (
        <div className="mt-8">
          Datei(n) ausgewählt:<br />
          <strong>{Array.from(selectedFiles).map((file) => file.name).join(", ")}</strong>
        </div>
      )}
      <input
        ref={fileInputRef}
        type="file"
        multiple={multiple}
        accept={mimeType}
        id={id}
        onChange={handleChange}
        {...otherProps}
      />
    </div>
  );
};

FileInput.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  multiple: PropTypes.bool,
  mimeType: PropTypes.string,
  className: PropTypes.string,
};

FileInput.defaultProps = {
  isInvalid: false,
  multiple: false,
  mimeType: "image/*",
  className: "",
};

export default FileInput;
