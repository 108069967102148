import React, { memo } from "react";

import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";

import IconButton from "../IconButton/IconButton";

const ConfirmButton = (props) => {
  const {
    children,
    onClick,
  } = props;

  const popover = (
    <Popover id="popover-positioned">
      <Popover.Body>
        <IconButton
          size="sm"
          variant="danger"
          onClick={onClick}
        >
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <FormattedMessage id="confirm_button.confirm" />
        </IconButton>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="top"
      overlay={popover}
    >
      <span>
        {children}
      </span>
    </OverlayTrigger>
  );
};

ConfirmButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  onClick: PropTypes.func,
};

ConfirmButton.defaultProps = {
  onClick: () => {},
};

export default memo(ConfirmButton);
