import React, { useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik } from "formik";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import moment from "moment";

import IconButton from "../../components/IconButton/IconButton";
import AppointmentBookingCard from "../../components/AppointmentBookingCard/AppointmentBookingCard";
import { confirmAppointment, resendSms, unreserveAppointment } from "../../state/features/AppointmentBookingSlice";
import { notificationService } from "../../services/NotificationService";
import ChangeMobilePhoneModal from "../../components/ChangeMobilePhoneModal/ChangeMobilePhoneModal";

const AppointmentConfirm = (props) => {
  const {
    client,
    confirmAppointmentDispatch,
    unreserveAppointmentDispatch,
    dateTime,
    appointmentType,
    reservedAppointmentUlid,
    reservationExpireTime,
    confirmAppointmentLoading,
    confirmAppointmentSuccess,
    confirmAppointmentFail,
    mobilePhone,
    resendSmsDispatch,
    resendSmsSuccess,
    resendSmsFail,
    changeMobilePhoneSuccess,
    changeMobilePhoneFail,
  } = props;

  const intl = useIntl();
  const [mobilePhoneState, setMobilePhoneState] = useState(mobilePhone);
  const [enableChanges, setEnableChanges] = useState(
    !resendSmsSuccess && !resendSmsFail && !changeMobilePhoneSuccess && !changeMobilePhoneFail,
  );
  const [changeMobilePhoneModal, setChangeMobilePhoneModal] = useState(false);

  const validationSchema = yup.object().shape({
    verificationCode: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
  });

  const resendSmsOnClick = () => resendSmsDispatch(reservedAppointmentUlid)
    .then((action) => {
      if (action.type.endsWith("SUCCESS")) {
        notificationService.info("Die SMS wurde erneut versendet.");
      }

      if (action.type.endsWith("FAIL")) {
        notificationService.error("Die SMS konnte nicht erneut versendet werden.");
      }

      setEnableChanges(false);
    });

  return (
    <>
      <Formik
        initialValues={{
          mobilePhone: mobilePhoneState,
          verificationCode: "",
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const tmpValues = { ...values };
          delete tmpValues.mobilePhone;

          confirmAppointmentDispatch(reservedAppointmentUlid, tmpValues);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="pt-40">
                <h1 className="mb-24">
                  <FormattedMessage id="appointment_booking.headline" />
                </h1>

                <AppointmentBookingCard
                  clientName={client.name}
                  appointmentDateTime={moment(dateTime)}
                  reservationExpireTime={reservationExpireTime}
                  onChange={() => {
                    unreserveAppointmentDispatch(reservedAppointmentUlid);
                  }}
                  onExpired={() => {
                    unreserveAppointmentDispatch(reservedAppointmentUlid);
                  }}
                  appointmentType={appointmentType}
                  className="mb-32"
                />

                <h3 className="mb-16">Bitte verifizieren Sie sich</h3>
                <p className="mb-16">
                  Nur noch ein Schritt! Wir haben Ihnen Ihren persönlichen
                  SMS-Verifizierungscode an die folgende Rufnummer gesendet:
                </p>
                <Row className="mb-32">
                  <Form.Group as={Col} md="6">
                    <Form.Control
                      name="mobilePhone"
                      type="text"
                      placeholder="Ihre Handynummer*"
                      readOnly
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={mobilePhoneState}
                    />
                    {errors.firstname && touched.firstname && (
                      <Form.Control.Feedback type="invalid">
                        {errors.firstname}
                      </Form.Control.Feedback>
                    )}
                    {enableChanges && (
                      <span
                        title="Handynummer ändern"
                        className="text-secondary d-inline-block mt-8"
                        role="button"
                        onKeyPress={() => setChangeMobilePhoneModal(true)}
                        tabIndex="0"
                        onClick={() => setChangeMobilePhoneModal(true)}
                      >
                        <u>Dies ist nicht Ihre Handynummer?</u>
                      </span>
                    )}
                  </Form.Group>
                </Row>

                <h3 className="mb-16">Bitte Code eingeben</h3>
                <Row className="mb-32">
                  <Form.Group as={Col} md="6">
                    <Form.Control
                      name="verificationCode"
                      type="text"
                      placeholder="Verifizierungscode eingeben*"
                      maxLength={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.verificationCode}
                    />
                    {errors.verificationCode && touched.verificationCode && (
                      <Form.Control.Feedback type="invalid">
                        {errors.verificationCode}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>

                <div className="mb-24">
                  <IconButton
                    type="submit"
                    disabled={confirmAppointmentLoading}
                  >
                    Code bestätigen
                    {confirmAppointmentLoading && (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                    {confirmAppointmentSuccess && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    {confirmAppointmentFail && (
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    )}
                  </IconButton>
                </div>

                {enableChanges && (
                  <p className="mb-16">
                    Falls Sie den Code nicht erhalten haben, klicken Sie bitte
                    auf den folgenden Link:
                    <br />
                    <span
                      title="Code erneut anfordern"
                      className="text-secondary d-inline-block "
                      role="button"
                      onKeyPress={resendSmsOnClick}
                      tabIndex="0"
                      onClick={resendSmsOnClick}
                    >
                      Code erneut anfordern
                    </span>
                  </p>
                )}
              </Col>
            </Row>
          </form>
        )}
      </Formik>

      <ChangeMobilePhoneModal
        show={changeMobilePhoneModal}
        appointmentUlid={reservedAppointmentUlid}
        mobilePhone={mobilePhone}
        onHide={(newNumber) => {
          if (newNumber) {
            setMobilePhoneState(newNumber);
            setEnableChanges(false);
          }
          setChangeMobilePhoneModal(false);
        }}
      />
    </>
  );
};

AppointmentConfirm.propTypes = {
  client: PropTypes.oneOfType([PropTypes.object]),
  confirmAppointmentDispatch: PropTypes.func.isRequired,
  unreserveAppointmentDispatch: PropTypes.func.isRequired,
  dateTime: PropTypes.string.isRequired,
  appointmentType: PropTypes.oneOfType([PropTypes.array]).isRequired,
  reservedAppointmentUlid: PropTypes.string.isRequired,
  reservationExpireTime: PropTypes.string.isRequired,
  resendSmsDispatch: PropTypes.func.isRequired,
  mobilePhone: PropTypes.string,
  confirmAppointmentLoading: PropTypes.bool,
  confirmAppointmentSuccess: PropTypes.bool,
  confirmAppointmentFail: PropTypes.bool,
  resendSmsSuccess: PropTypes.bool,
  resendSmsFail: PropTypes.bool,
  changeMobilePhoneSuccess: PropTypes.bool,
  changeMobilePhoneFail: PropTypes.bool,
};

AppointmentConfirm.defaultProps = {
  client: null,
  confirmAppointmentLoading: false,
  confirmAppointmentSuccess: false,
  confirmAppointmentFail: false,
  mobilePhone: "",
  resendSmsSuccess: false,
  resendSmsFail: false,
  changeMobilePhoneSuccess: false,
  changeMobilePhoneFail: false,
};

const mapStateToProps = ({ appointmentBooking }) => ({
  dateTime: appointmentBooking.dateTime,
  appointmentType: appointmentBooking.appointmentType,
  reservedAppointmentUlid: appointmentBooking.reservedAppointmentUlid,
  reservationExpireTime: appointmentBooking.reservationExpireTime,
  confirmAppointmentLoading: appointmentBooking.confirmAppointmentLoading,
  confirmAppointmentSuccess: appointmentBooking.confirmAppointmentSuccess,
  confirmAppointmentFail: appointmentBooking.confirmAppointmentFail,
  mobilePhone: appointmentBooking.mobilePhone,
  resendSmsSuccess: appointmentBooking.resendSmsSuccess,
  resendSmsFail: appointmentBooking.resendSmsFail,
  changeMobilePhoneSuccess: appointmentBooking.changeMobilePhoneSuccess,
  changeMobilePhoneFail: appointmentBooking.changeMobilePhoneFail,
});

const mapDispatch = {
  confirmAppointmentDispatch: confirmAppointment,
  unreserveAppointmentDispatch: unreserveAppointment,
  resendSmsDispatch: resendSms,
};

export default connect(mapStateToProps, mapDispatch)(AppointmentConfirm);
