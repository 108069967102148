import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { Formik } from "formik";
import * as yup from "yup";
import { Alert } from "react-bootstrap";
import { useIntl } from "react-intl";

import Panel from "../../../components/Panel/Panel";
import Hint from "../../../components/Hint/Hint";
import IconButton from "../../../components/IconButton/IconButton";
import Loading from "../../../components/Loading/Loading";
import { edit, fetch, reset } from "../../../state/features/ClientBaseDataSlice";
import FileInput from "../../../components/FileInput/FileInput";
import Copy from "../../../components/Copy/Copy";
import FormRowText from "../../../components/FormRow/FormRowText";
import FormRowSelect from "../../../components/FormRow/FormRowSelect";
import FormRowSwitch from "../../../components/FormRow/FormRowSwitch";
import { COUNTRIES } from "../../../constants";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { notificationService } from "../../../services/NotificationService";

const BaseDataEdit = (props) => {
  const {
    fetchDispatch,
    editDispatch,
    resetDispatch,
    client,
    loading,
    editLoading,
    editSuccess,
    editFail,
  } = props;

  useEffect(() => {
    resetDispatch();
    fetchDispatch();
  }, [resetDispatch, fetchDispatch]);

  const intl = useIntl();

  const validationSchema = yup.object().shape({
    name: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    ikNumber: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .min(7, intl.formatMessage({ id: "form.error.invalid_value" }))
      .max(9, intl.formatMessage({ id: "form.error.invalid_value" })),
    street: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    houseNumber: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(12, intl.formatMessage({ id: "form.error.max_length" }, { value: 12 })),
    zip: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(5, intl.formatMessage({ id: "form.error.max_length" }, { value: 5 })),
    city: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    country: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    firstname: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    lastname: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    phone: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(20, intl.formatMessage({ id: "form.error.max_length" }, { value: 20 })),
    email: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 }))
      .email(intl.formatMessage({ id: "form.error.email" })),
    text: yup.string()
      .max(500, intl.formatMessage({ id: "form.error.max_length" }, { value: 500 })),
  });

  if (loading || client === null) {
    return <Loading />;
  }

  const getBookingUrl = () => {
    switch (client.reseller.name) {
      case "Noventi":
        return `https://noventi.dubidoc.de/appointments/${client.ulid}`;
      default:
        return `https://dubivax.dubidoc.de/appointments/${client.ulid}`;
    }
  };

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/base-data", name: "client_navigation.base_data" },
        ]}
      />

      <h3 className="mb-16">Stammdaten bearbeiten</h3>

      <Formik
        initialValues={{
          name: client.name || "",
          ikNumber: client.ikNumber || "",
          street: client.street || "",
          houseNumber: client.houseNumber || "",
          zip: client.zip || "",
          city: client.city || "",
          country: client.country || "",
          firstname: client.firstname || "",
          lastname: client.lastname || "",
          phone: client.phone || "",
          email: client.email || "",
          logo: null,
          active: client.active,
          sendEmail: client.sendEmail,
          text: client.text || "",
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          if (values.logo !== null && values.logo.size > 5242880) {
            errors.logo = intl.formatMessage({ id: "form.error.file_size" });
          } else if (values.logo !== null && !["image/jpeg", "image/png"].includes(values.logo.type)) {
            errors.logo = intl.formatMessage({ id: "form.error.file_type" });
          }

          return errors;
        }}
        onSubmit={(values) => {
          editDispatch(values)
            .then((action) => {
              if (action.type.endsWith("SUCCESS")) {
                notificationService.success("Stammdaten erfolgreich gespeichert.");
              }
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Hinweis:</strong><br />
                Bitte geben Sie als Bezeichnung den Namen ein unter dem Ihre
                Einrichtung öffentlich gefunden werden soll.
              </Col>
              <Col md={9}>
                <Panel headline="Allgemeines">
                  <FormRowText
                    id="name"
                    name="name"
                    label="Bezeichnung*"
                    className="mb-24"
                    defaultValue={values.name}
                    errors={errors.name}
                    touched={touched.name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="street"
                    name="street"
                    label="Straße*"
                    className="mb-24"
                    defaultValue={values.street}
                    errors={errors.street}
                    touched={touched.street}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="houseNumber"
                    name="houseNumber"
                    label="Hausnummer*"
                    className="mb-24"
                    defaultValue={values.houseNumber}
                    errors={errors.houseNumber}
                    touched={touched.houseNumber}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="zip"
                    name="zip"
                    label="PLZ*"
                    className="mb-24"
                    defaultValue={values.zip}
                    errors={errors.zip}
                    touched={touched.zip}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="city"
                    name="city"
                    label="Ort*"
                    className="mb-24"
                    defaultValue={values.city}
                    errors={errors.city}
                    touched={touched.city}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowSelect
                    id="country"
                    name="country"
                    label="Land*"
                    className="mb-24"
                    defaultValue={values.country}
                    options={COUNTRIES.map(({ value, label }) => ({
                      value,
                      label: intl.formatMessage({ id: label }),
                    }))}
                    errors={errors.country}
                    touched={touched.country}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="ikNumber"
                    name="ikNumber"
                    label="IK-Nummer*"
                    defaultValue={values.ikNumber}
                    errors={errors.ikNumber}
                    touched={touched.ikNumber}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Bitte geben Sie die vollständigen Kontaktdaten des Verantwortlichen
                in Ihrer Einrichtung ein. Die Daten sind nicht öffentlich einsehbar.
              </Col>
              <Col md={9}>
                <Panel headline="Allgemeines">
                  <FormRowText
                    id="firstname"
                    name="firstname"
                    label="Vorname*"
                    className="mb-24"
                    defaultValue={values.firstname}
                    errors={errors.firstname}
                    touched={touched.firstname}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="lastname"
                    name="lastname"
                    label="Nachname*"
                    className="mb-24"
                    defaultValue={values.lastname}
                    errors={errors.lastname}
                    touched={touched.lastname}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="phone"
                    name="phone"
                    label="Telefonnummer*"
                    className="mb-24"
                    defaultValue={values.phone}
                    errors={errors.phone}
                    touched={touched.phone}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="email"
                    name="email"
                    label="E-Mailadresse*"
                    type="email"
                    className="mb-24"
                    defaultValue={values.email}
                    errors={errors.email}
                    touched={touched.email}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <Form.Group as={Row}>
                    <Form.Label column md={4}>
                      Nachweise vorgelegt?
                    </Form.Label>
                    <Col md={7}>
                      {client.approved ? (
                        <Alert variant="success" className="mb-0">
                          <FontAwesomeIcon icon={faCheck} />
                          Nachweis erbracht - Terminbuchung zugelassen
                        </Alert>
                      ) : (
                        <Alert variant="danger" className="mb-0">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                          Nachweis nicht erbracht - Terminbuchung nicht zugelassen
                        </Alert>
                      )}
                    </Col>
                    <Col md={1} className="d-flex align-items-center">
                      <Hint text="Wir sind verpflichtet Ihre Zulassung zur Durchführung von Impfungen zu überprüfen, bevor Sie Online-Termine für Impfungen anbieten dürfen." />
                    </Col>
                  </Form.Group>
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Hier können Sie das Logo Ihrer Einrichtung hochladen.
                Es wird Ihren Kunden/Patienten bei der Terminbuchung angezeigt.
              </Col>
              <Col md={9}>
                <Panel headline="Logo">
                  <Form.Group as={Row}>
                    <Form.Label column md={4}>
                      Logo auswählen (max. 5 MB)
                    </Form.Label>
                    <Col md={7}>
                      <FileInput
                        id="logo"
                        isInvalid={!!(errors.logo && touched.logo)}
                        onChange={(file) => {
                          setFieldValue("logo", file);
                        }}
                      >
                        Logo hier ablegen oder <u>Computer durchsuchen</u>
                      </FileInput>

                      {errors.logo && touched.logo && (
                        <Form.Control.Feedback type="invalid">
                          {errors.logo}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Hier können Sie Patienten generelle Hinweise rund um die
                Terminbuchung in Ihrer Einrichtung geben.
                (z.B. Hygienehinweise, Parkplatzsitution etc.)
                <br /><br />
                Bitte wählen Sie ob Sie über Online-Terminbuchungen per
                E-Mail benachrichtigt werden möchten.
              </Col>
              <Col md={9}>
                <Panel headline="Einstellungen für die Online-Terminierung">
                  <Form.Group
                    as={Row}
                    controlId="text"
                    className="mb-24"
                  >
                    <Form.Label column md={4}>
                      Hinweis Online-Terminierung
                    </Form.Label>
                    <Col md={7}>
                      <Form.Control
                        as="textarea"
                        name="text"
                        style={{ height: "100px" }}
                        placeholder="Max. 500 Zeichen"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.text}
                      />

                      {errors.text && touched.text && errors.text}
                    </Col>
                  </Form.Group>

                  <FormRowSwitch
                    id="sendEmail"
                    name="sendEmail"
                    label="Online-Teminbenachrichtungen per E-Mail"
                    defaultValue={values.sendEmail}
                    errors={errors.sendEmail}
                    touched={touched.sendEmail}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    hint="Aktivieren Sie den Schalter, wenn Sie über eingehende Onilne-Terminbuchungen an die in den Kontaktdaten angegebene E-Mail- Adresse informiert werden möchten."
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Sie können jederzeit die Online-Buchungen von Terminen in Ihrer
                Einrichtung durch Klick auf den Schalter aktivieren oder deaktivieren.
              </Col>
              <Col md={9}>
                <Panel headline="Status des Buchungssystems">
                  <FormRowSwitch
                    id="active"
                    name="active"
                    label="Auf Buchungs-Plattform anzeigen"
                    className="mb-24"
                    defaultValue={values.active}
                    errors={errors.active}
                    touched={touched.active}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />

                  <Form.Group as={Row}>
                    <Form.Label column md={4}>
                      Link zur Anbindung von Ihrer Website
                    </Form.Label>
                    <Col md={7}>
                      <Form.Control
                        type="text"
                        value={getBookingUrl()}
                        disabled
                      />
                    </Col>
                    <Col md={1}>
                      <Copy text={getBookingUrl()} />
                    </Col>
                  </Form.Group>
                  <Row>
                    <Col md={{ span: 7, offset: 4 }}>
                      {!values.active && (
                        <Alert variant="danger" className="mt-24 mb-0">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                          Online-Terminbuchungen sind deaktiviert.
                        </Alert>
                      )}
                    </Col>
                  </Row>
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={{ span: 9, offset: 3 }}>
                <Panel small className="text-end">
                  <IconButton
                    disabled={editLoading}
                    type="submit"
                  >
                    Speichern
                    {editLoading && (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                    {editSuccess && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    {editFail && (
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    )}
                  </IconButton>
                </Panel>
              </Col>
            </Row>

          </form>
        )}
      </Formik>
    </>
  );
};

BaseDataEdit.propTypes = {
  fetchDispatch: PropTypes.func.isRequired,
  editDispatch: PropTypes.func.isRequired,
  resetDispatch: PropTypes.func.isRequired,
  client: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  editLoading: PropTypes.bool,
  editSuccess: PropTypes.bool,
  editFail: PropTypes.bool,
};

BaseDataEdit.defaultProps = {
  client: null,
  loading: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
};

const mapStateToProps = ({ clientBaseData }) => ({
  client: clientBaseData.client,
  loading: clientBaseData.loading,
  editLoading: clientBaseData.editLoading,
  editSuccess: clientBaseData.editSuccess,
  editFail: clientBaseData.editFail,
});

const mapDispatch = {
  fetchDispatch: fetch,
  editDispatch: edit,
  resetDispatch: reset,
};

export default connect(mapStateToProps, mapDispatch)(BaseDataEdit);
