import React, { Component } from "react";

import {
  faChevronLeft,
  faChevronRight,
  faDotCircle,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";
import FullCalendar from "@fullcalendar/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeLocale from "@fullcalendar/core/locales/de";
import DayGridPlugin from "@fullcalendar/daygrid";
import ListPlugin from "@fullcalendar/list";
import TimeGridPlugin from "@fullcalendar/timegrid";
import InteractionPlugin from "@fullcalendar/interaction";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import moment from "moment";
import classNames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";

import IconButton from "../../../components/IconButton/IconButton";
import Appointment from "../../../components/Appointment/Appointment";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { copy, move } from "../../../state/features/AppointmentSlice";
import { notificationService } from "../../../services/NotificationService";
import { fetchEvents, getInfo } from "../../../state/features/CalendarSlice";
import { fetchWeek } from "../../../state/features/AppointmentTypeBudgetingSlice";
import AppointmentModal from "../../../components/AppointmentModal/AppointmentModal";
import AppointmentWarningModal from "../../../components/AppointmentWarningModal/AppointmentWarningModal";
import AppointmentOverbookModal from "../../../components/AppointmentOverbookModal/AppointmentOverbookModal";
import { fetch } from "../../../state/features/ClientBaseDataSlice";

import "./Calendar.scss";

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.calendarRef = React.createRef();
    this.calendarApi = null;
    this.refreshInterval = null;
    this.scrollPosition = null;

    this.state = {
      title: null,
      currentView: "timeGridWeek",
      overbookModal: false,
      errorModal: false,
      errorModalTitle: null,
      errorModalMessage: null,
      errorModalAction: null,
      appointmentModal: false,
      currentAppointmentUlid: null,
      currentDateTime: null,
      isMoving: false,
      movingAppointmentUlid: null,
      isCopying: false,
      copyingAppointmentUlid: null,
      slotSize: localStorage.getItem("slotSize") || 5,
    };
  }

  componentDidMount() {
    this.calendarApi = this.calendarRef.current.getApi();

    this.updateTitle();

    // Fixme: implement push mechanics
    // Fixme: disable interval if modal is visible
    this.refreshInterval = setInterval(() => {
      this.calendarApi.refetchEvents();
    }, 15000); // 15s

    const {
      fetchClientDispatch,
    } = this.props;

    fetchClientDispatch();
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  fetchBudgetingData = (date) => {
    const {
      fetchWeekDispatch,
    } = this.props;

    const weekDate = moment(date).day(1);

    fetchWeekDispatch(weekDate.week(), weekDate.year());
  }

  updateTitle = () => {
    if (this.calendarApi === null) {
      return;
    }

    const {
      title,
    } = this.state;

    const {
      view,
    } = this.calendarApi;

    if (title !== view.title) {
      this.setState({
        title: view.title,
      });
    }
  }

  fetchEvents = (fetchInfo, onSuccess, onFailure) => {
    const {
      fetchEventsDispatch,
    } = this.props;

    this.scrollPosition = document.querySelector(".fc-scroller-liquid-absolute")?.scrollTop;

    this.fetchBudgetingData(fetchInfo.startStr);

    fetchEventsDispatch(fetchInfo.startStr, fetchInfo.endStr)
      .then(({ payload }) => {
        onSuccess([
          ...(payload.data.appointments.map((appointment) => ({
            id: appointment.ulid,
            start: appointment.dateTime,
            end: appointment.dateTimeEnd,
            extendedProps: {
              firstname: appointment.firstname,
              lastname: appointment.lastname,
              color: appointment.appointmentType.color,
              state: appointment.state,
              type: "appointment",
            },
          }))),
          ...(payload.data.workingTimes.map((workingTime) => ({
            id: workingTime.ulid,
            groupId: "lala",
            start: workingTime.startTime,
            end: workingTime.endTime,
            display: "inverse-background",
            backgroundColor: "#E0E0E0",
            extendedProps: {
              lastDate: workingTime.lastDate,
              type: "working-time",
            },
          }))),
          ...(payload.data.blockingTimes.map((blockingTime) => ({
            id: blockingTime.ulid,
            start: blockingTime.startTime,
            end: blockingTime.endTime,
            display: "background",
            backgroundColor: "#BB260A",
            extendedProps: {
              title: blockingTime.title,
              type: "blocking-time",
            },
          }))),
        ]);

        if (typeof this.scrollPosition !== "undefined") {
          setTimeout(() => {
            document.querySelector(".fc-scroller-liquid-absolute").scrollTop = this.scrollPosition;
          }, 50);
        }
      })
      .catch((errors) => {
        onFailure(errors);
      });
  }

  renderEventContent = (arg) => {
    switch (arg.event.extendedProps.type) {
      case "appointment":
        return (
          <Appointment
            firstname={arg.event.extendedProps.firstname}
            lastname={arg.event.extendedProps.lastname}
            state={arg.event.extendedProps.state}
            color={arg.event.extendedProps.color}
          />
        );
      case "blocking-time":
        return <div style={{ backgroundImage: `url("data:image/svg+xml;utf8,%3Csvg%20width%3D%2220%22%20height%3D%22500%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%3E%3Ctext%20transform%3D%22rotate%28-90%205%2C5%29%22%20text-anchor%3D%22end%22%20font-family%3D%22Open%20Sans%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2212%22%20y%3D%2213%22%20x%3D%225%22%20fill%3D%22%23ffffff%22%3E${arg.event.extendedProps.title}%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fsvg%3E")` }} />;
      default:
        return null;
    }
  }

  dateChanged = () => {
    this.updateTitle();
  }

  calendarClickSentinel = (action, date) => {
    const {
      intl,
      getInfoDispatch,
    } = this.props;

    getInfoDispatch(moment(date).toISOString())
      .then(({ type, payload }) => {
        if (type.endsWith("SUCCESS")) {
          const {
            workingTimes,
            blockingTimes,
            parallelAppointments,
          } = payload.data;

          let errorMessage = null;
          let errorTitle = null;

          if (workingTimes.length === 0) {
            errorTitle = "calendar.working_time_title";
            errorMessage = "calendar.working_time_message";
          } else if (blockingTimes.length > 0) {
            errorTitle = "calendar.blocking_time_title";
            errorMessage = "calendar.blocking_time_message";
          } else if (parallelAppointments >= workingTimes[0].appointmentCount) {
            errorTitle = "calendar.appointment_count_title";
            errorMessage = "calendar.appointment_count_message";
          }

          if (errorMessage !== null) {
            this.setState({
              errorModal: true,
              errorModalTitle: intl.formatMessage({ id: errorTitle }),
              errorModalMessage: intl.formatMessage({ id: errorMessage }),
              errorModalAction: action,
            });
          } else {
            action();
          }
        } else {
          // fallback on error
          action();
        }
      });
  }

  calendarClick = (date, eventId) => {
    const {
      moveDispatch,
      copyDispatch,
    } = this.props;

    const {
      isMoving,
      movingAppointmentUlid,
      isCopying,
      copyingAppointmentUlid,
    } = this.state;

    if (!isCopying && !isMoving) {
      this.setState({
        appointmentModal: true,
        currentAppointmentUlid: eventId,
        currentDateTime: moment(date),
      });
    }

    if (isMoving) {
      moveDispatch(movingAppointmentUlid, moment(date).toISOString())
        .then((action) => {
          this.calendarApi.refetchEvents();

          if (action.type.endsWith("SUCCESS")) {
            notificationService.success("Der ausgewählte Termin wurde erfolgreich verschoben.");
          }
        });

      this.setState({
        isMoving: false,
        movingAppointmentUlid: null,
      });
    }

    if (isCopying) {
      copyDispatch(copyingAppointmentUlid, moment(date).toISOString())
        .then((action) => {
          this.calendarApi.refetchEvents();

          if (action.type.endsWith("SUCCESS")) {
            notificationService.success("Der ausgewählte Termin wurde erfolgreich kopiert.");
          }
        });

      this.setState({
        isCopying: false,
        copyingAppointmentUlid: null,
      });
    }
  }

  setSlotSize = (slotSize) => {
    this.setState({
      slotSize,
    });

    localStorage.setItem("slotSize", slotSize);
  }

  render() {
    const {
      title,
      overbookModal,
      errorModal,
      errorModalMessage,
      errorModalTitle,
      errorModalAction,
      appointmentModal,
      currentAppointmentUlid,
      currentDateTime,
      currentView,
      isMoving,
      isCopying,
      slotSize,
    } = this.state;

    const {
      appointmentTypeBudgetings,
    } = this.props;

    const formattedSlotSize = `${Math.floor(slotSize / 60).toString().padStart(2, "0")}:${(slotSize % 60).toString().padStart(2, "0")}:00`;

    const overbookedAppointmentTypes = [];

    if (appointmentTypeBudgetings.length > 0) {
      appointmentTypeBudgetings.forEach(({ appointmentType, count }) => {
        if (
          count > 0
          && appointmentType.appointmentTypeBudgetings.length > 0
          && count > appointmentType.appointmentTypeBudgetings[0].amount
        ) {
          overbookedAppointmentTypes.push({ appointmentType, count });
        }
      });
    }

    return (
      <div className={classNames("calendar", { "calendar--moving": isMoving })}>
        <BreadCrumbs
          crumbs={[
            { to: "/portal/dashboard", name: "client_navigation.dashboard" },
            { to: "/portal/calendar", name: "client_navigation.calendar" },
          ]}
        />

        {(isMoving || isCopying) && (
          <div className="calendar__moving-hint">
            Bitte klicken Sie im Kalender auf den gewünschten Zeitpunkt.
            <Button
              onClick={() => {
                this.setState({
                  isMoving: false,
                  movingAppointmentUlid: null,
                  isCopying: false,
                  copyingAppointmentUlid: null,
                });
              }}
            >
              Abbrechen
            </Button>
          </div>
        )}

        <div className="mb-8 d-flex">
          <IconButton
            variant="white"
            className="me-4"
            onClick={() => {
              this.calendarApi.prev();
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
          <Button
            variant="white"
            className="me-4"
            style={{ width: "230px" }}
          >
            {title}
          </Button>
          <IconButton
            variant="white"
            className="me-8"
            onClick={() => {
              this.calendarApi.next();
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </IconButton>
          <IconButton
            variant="white"
            onClick={() => {
              this.calendarApi.today();
            }}
          >
            <FontAwesomeIcon icon={faDotCircle} />
            Heute
          </IconButton>

          {overbookedAppointmentTypes.length > 0 && (
            <IconButton
              variant="white"
              className="ms-8 text-warning"
              onClick={() => {
                this.setState({
                  overbookModal: true,
                });
              }}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} className="ring" />
            </IconButton>
          )}

          <div className="ms-auto">
            {currentView !== "listDay" && (
              <Dropdown className="d-inline me-8">
                <Dropdown.Toggle variant="white">
                  {slotSize} Min
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => { this.setSlotSize(5); }}>5 Min</Dropdown.Item>
                  <Dropdown.Item onClick={() => { this.setSlotSize(10); }}>10 Min</Dropdown.Item>
                  <Dropdown.Item onClick={() => { this.setSlotSize(15); }}>15 Min</Dropdown.Item>
                  <Dropdown.Item onClick={() => { this.setSlotSize(20); }}>20 Min</Dropdown.Item>
                  <Dropdown.Item onClick={() => { this.setSlotSize(30); }}>30 Min</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

            <ButtonGroup>
              <Button
                variant={currentView === "listDay" ? "white" : "tertiary"}
                onClick={() => {
                  this.setState({
                    currentView: "listDay",
                  });
                  this.calendarApi.changeView("listDay");
                }}
              >
                Liste
              </Button>
              <Button
                variant={currentView === "timeGridDay" ? "white" : "tertiary"}
                onClick={() => {
                  this.setState({
                    currentView: "timeGridDay",
                  });
                  this.calendarApi.changeView("timeGridDay");
                }}
              >
                Tag
              </Button>
              <Button
                variant={currentView === "timeGridWeek" ? "white" : "tertiary"}
                onClick={() => {
                  this.setState({
                    currentView: "timeGridWeek",
                  });
                  this.calendarApi.changeView("timeGridWeek");
                }}
              >
                Woche
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <FullCalendar
          ref={this.calendarRef}
          plugins={[
            DayGridPlugin,
            TimeGridPlugin,
            ListPlugin,
            InteractionPlugin,
          ]}
          initialView={currentView}
          locales={[DeLocale]}
          locale="de"
          firstDay={1}
          allDaySlot={false}
          slotMinTime="06:00:00"
          slotMaxTime="22:00:00"
          slotDuration={formattedSlotSize}
          slotMinWidth={100}
          slotEventOverlap={false}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: "short",
          }}
          nowIndicator
          headerToolbar={false}
          eventClassNames={(arg) => {
            if (arg.event.extendedProps.type === "blocking-time") {
              return ["blocking-time"];
            }

            return [];
          }}
          events={this.fetchEvents}
          eventContent={this.renderEventContent}
          eventBackgroundColor="#fff"
          eventBorderColor="#fff"
          eventTextColor="#212121"
          eventClick={(eventClickInfo) => {
            if (eventClickInfo.event.extendedProps.type !== "appointment") {
              return;
            }

            if (!isCopying && !isMoving) {
              this.setState({
                appointmentModal: true,
                currentAppointmentUlid: eventClickInfo.event.id,
                currentDateTime: moment(eventClickInfo.event.start),
              });
            } else {
              this.calendarClickSentinel(
                () => {
                  this.calendarClick(eventClickInfo.event.start, eventClickInfo.event.id);
                },
                eventClickInfo.event.start,
              );
            }
          }}
          dateClick={(dateClickInfo) => {
            this.calendarClickSentinel(
              () => {
                this.calendarClick(dateClickInfo.date, null);
              },
              dateClickInfo.date,
            );
          }}
          datesSet={this.dateChanged}
          defaultTimedEventDuration={formattedSlotSize}
        />

        <AppointmentOverbookModal
          show={overbookModal}
          onHide={() => {
            this.setState({
              overbookModal: false,
            });
          }}
          appointmentTypeBudgetings={overbookedAppointmentTypes}
        />

        <AppointmentWarningModal
          show={errorModal}
          onHide={() => {
            this.setState({
              errorModal: false,
            });
          }}
          title={errorModalTitle}
          message={errorModalMessage}
          action={errorModalAction}
        />

        {(appointmentModal && (currentAppointmentUlid !== null || currentDateTime !== null)) && (
          <AppointmentModal
            show={appointmentModal}
            appointmentUlid={currentAppointmentUlid}
            overbookedAppointmentTypes={overbookedAppointmentTypes}
            dateTime={currentDateTime}
            onHide={(appointmentDateTime) => {
              this.setState({
                appointmentModal: false,
                currentAppointmentUlid: null,
                currentDateTime: null,
              });
              this.calendarApi.refetchEvents();
              this.calendarApi.scrollToTime(appointmentDateTime.format("HH:mm"));
            }}
            onMove={(appointmentUlid) => {
              this.setState({
                isMoving: true,
                movingAppointmentUlid: appointmentUlid,
              });
            }}
            onCopy={(appointmentUlid) => {
              this.setState({
                isCopying: true,
                copyingAppointmentUlid: appointmentUlid,
              });
            }}
          />
        )}
      </div>
    );
  }
}

Calendar.propTypes = {
  intl: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  fetchWeekDispatch: PropTypes.func.isRequired,
  moveDispatch: PropTypes.func.isRequired,
  copyDispatch: PropTypes.func.isRequired,
  fetchEventsDispatch: PropTypes.func.isRequired,
  getInfoDispatch: PropTypes.func.isRequired,
  appointmentTypeBudgetings: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  fetchClientDispatch: PropTypes.func.isRequired,
};

Calendar.defaultProps = {
  appointmentTypeBudgetings: [],
};

const mapStateToProps = (state) => {
  const {
    appointmentTypeBudgeting,
    appointmentType,
    appointment,
  } = state;

  return {
    appointmentTypeBudgetings: appointmentTypeBudgeting.appointmentTypeBudgetings,
    appointmentTypes: appointmentType.appointmentTypes,
    appointment: appointment.appointment,
    editLoading: appointment.editLoading,
    editSuccess: appointment.editSuccess,
    editFail: appointment.editFail,
  };
};

const mapDispatch = {
  moveDispatch: move,
  copyDispatch: copy,
  fetchEventsDispatch: fetchEvents,
  getInfoDispatch: getInfo,
  fetchWeekDispatch: fetchWeek,
  fetchClientDispatch: fetch,
};

export default connect(mapStateToProps, mapDispatch)(injectIntl(Calendar));
