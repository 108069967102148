import React, { useContext } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Logo from "../Logo/Logo";
import { ThemeContext } from "../ThemeContext/ThemeContext";

import "./Navigation.scss";

const Navigation = () => {
  const {
    theme,
  } = useContext(ThemeContext);

  return (
    <div className="navigation">
      <Container fluid="xxl">
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <div className="d-flex justify-content-between align-items-center">
              <Logo brand={theme === "dubivax" ? "dubidoc" : "noventi-dubidoc"} />
              <div />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Navigation;
