import React, { memo } from "react";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Images from "../../images";

import "./ClientCard.scss";

const ClientCard = (props) => {
  const {
    logo,
    name,
    street,
    houseNumber,
    zip,
    city,
  } = props;

  const getAddress = () => `${street}+${houseNumber},+${zip}+${city},de`;

  return (
    <div className="client-card">
      <div className="text-center">
        <div className="client-card__logo" style={{ backgroundImage: `url(${logo || Images.placeholderLogo})` }} />
      </div>

      <h3 className="mb-16">{name}</h3>

      <div className="mb-24">
        {street} {houseNumber}<br />
        {zip} {city}
      </div>

      <div className="d-none d-md-block">
        <h5>Hier finden Sie uns</h5>
        <div className="client-card__address mb-16">
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${getAddress()},de&zoom=14&size=370x256&scale=2&key=AIzaSyCK2JIF8bLuGTsT23ZeCs_YSgZuZPlT1zU`}
            alt="Adresse"
            className="img-fluid"
          />
          <img className="client-card__address-marker" src={Images.mapMarker} alt="Adressmarkierung" />
        </div>
      </div>

      <div className="text-md-center">
        <a
          href={`https://www.google.de/maps/dir//${getAddress()}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-secondary btn-sm"
        >
          <FormattedMessage id="client_card.routing" />
        </a>
      </div>
    </div>
  );
};

ClientCard.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  houseNumber: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
};

ClientCard.defaultProps = {
  logo: null,
};

export default memo(ClientCard);
