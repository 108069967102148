import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestLoading: false,
  requestSuccess: false,
  requestFail: false,
  resetLoading: false,
  resetSuccess: false,
  resetFail: false,
};

const ForgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    request: (state) => ({
      ...state,
      requestLoading: true,
      requestSuccess: false,
      requestFail: false,
    }),
    request_SUCCESS: (state) => ({
      ...state,
      requestLoading: false,
      requestSuccess: true,
      requestFail: false,
    }),
    request_FAIL: (state) => ({
      ...state,
      requestLoading: false,
      requestSuccess: false,
      requestFail: true,
    }),
    reset: (state) => ({
      ...state,
      resetLoading: true,
      resetSuccess: false,
      resetFail: false,
    }),
    reset_SUCCESS: (state) => ({
      ...state,
      resetLoading: false,
      resetSuccess: true,
      resetFail: false,
    }),
    reset_FAIL: (state) => ({
      ...state,
      resetLoading: false,
      resetSuccess: false,
      resetFail: true,
    }),
  },
});

export default ForgotPasswordSlice.reducer;

export const request = (email) => ({
  type: "forgotPassword/request",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        email,
      },
      url: "portal/forgot-password/request",
    },
  },
});

export const reset = (ulid, resetToken, password) => ({
  type: "forgotPassword/request",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        resetToken,
        password,
      },
      url: `portal/forgot-password/reset/${ulid}`,
    },
  },
});
