import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faExclamationTriangle, faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { Formik } from "formik";
import * as yup from "yup";
import { Alert } from "react-bootstrap";
import { useIntl } from "react-intl";

import Panel from "../../../components/Panel/Panel";
import IconButton from "../../../components/IconButton/IconButton";
import Loading from "../../../components/Loading/Loading";
import FormRowText from "../../../components/FormRow/FormRowText";
import {
  changePassword,
  fetch,
  reset,
} from "../../../state/features/ProfileSlice";
import TotpModal from "./TotpModal";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { notificationService } from "../../../services/NotificationService";

const Profile = (props) => {
  const {
    fetchDispatch,
    changePasswordDispatch,
    resetDispatch,
    user,
    userLoading,
    changePasswordLoading,
    changePasswordSuccess,
    changePasswordFail,
  } = props;

  useEffect(() => {
    resetDispatch();
    fetchDispatch();
  }, [resetDispatch, fetchDispatch]);

  const intl = useIntl();

  if (userLoading || user === null) {
    return <Loading />;
  }

  const validationSchema = yup.object().shape({
    oldPassword: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    password: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    passwordRepeat: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    totp: user.totp
      ? yup.string()
        .required(intl.formatMessage({ id: "form.error.required" }))
        .length(6, intl.formatMessage({ id: "form.error.exact_length" }, { value: 6 }))
      : yup.string(),
  });

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/profile", name: "client_navigation.profile" },
        ]}
      />

      <h3 className="mb-16">Profil</h3>

      <Formik
        initialValues={{
          oldPassword: "",
          password: "",
          passwordRepeat: "",
          totp: "",
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          const {
            password,
            passwordRepeat,
          } = values;

          if (password !== "" && /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*\-_"§/()=?;,:.<>'+]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(password) === false) {
            errors.password = intl.formatMessage({ id: "form.error.password_complexity" });
          }

          if (password !== "" && password !== passwordRepeat) {
            errors.passwordRepeat = intl.formatMessage({ id: "form.error.password_repeat" });
          }

          return errors;
        }}
        onSubmit={(values, { setFieldValue }) => {
          const tmpValues = { ...values };
          delete tmpValues.passwordRepeat;

          changePasswordDispatch(tmpValues)
            .then((action) => {
              if (action.type.endsWith("SUCCESS")) {
                notificationService.success("Erfolgreich gespeichert.");
                setFieldValue("oldPassword", "");
                setFieldValue("password", "");
                setFieldValue("passwordRepeat", "");
                setFieldValue("totp", "");
              }
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Hinweis:</strong><br />
                Bitte beachten Sie, dass Ihr Passwort aus mindestens acht Zeichen
                bestehen und Klein- und Großbuchstaben, mindestens eine Zahl
                sowie ein Sonderzeichen enthalten muss.
              </Col>
              <Col md={9}>
                <Panel headline="Passwort ändern">
                  <FormRowText
                    id="oldPassword"
                    name="oldPassword"
                    label="Altes Passwort*"
                    type="password"
                    className="mb-24"
                    defaultValue={values.oldPassword}
                    errors={errors.oldPassword}
                    touched={touched.oldPassword}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="password"
                    name="password"
                    label="Neues Passwort*"
                    type="password"
                    className="mb-24"
                    defaultValue={values.password}
                    errors={errors.password}
                    touched={touched.password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  <FormRowText
                    id="passwordRepeat"
                    name="passwordRepeat"
                    label="Neues Passwort wiederholen*"
                    type="password"
                    className={user.totp ? "mb-24" : ""}
                    defaultValue={values.passwordRepeat}
                    errors={errors.passwordRepeat}
                    touched={touched.passwordRepeat}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />

                  {user.totp && (
                    <FormRowText
                      id="totp"
                      name="totp"
                      label="Einmal Passwort / Code*"
                      type="text"
                      maxLength={6}
                      defaultValue={values.totp}
                      errors={errors.totp}
                      touched={touched.totp}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  )}
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={{ span: 9, offset: 3 }}>
                <Panel small className="text-end">
                  <IconButton
                    disabled={changePasswordLoading}
                    type="submit"
                  >
                    Speichern
                    {changePasswordLoading && (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                    {changePasswordSuccess && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    {changePasswordFail && (
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    )}
                  </IconButton>
                </Panel>
              </Col>
            </Row>

          </form>
        )}
      </Formik>

      <Row className="mb-24">
        <Col md={3} className="pt-105">
          <strong>Hinweis:</strong><br />
          Die 2-Faktor-Authentifizierung sichert Ihr Konto stärker ab, da
          Unbefugte neben Ihrem Passwort noch eine weitere Sicherheitsstufe
          überwinden müssen.
        </Col>
        <Col md={9}>
          <Panel headline="2-Faktor Authentifizierung">
            <Row>
              <Col md={4}>
                Status
              </Col>
              <Col md={7}>
                {user.totp ? (
                  <Alert variant="success" className="mb-0">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    2FA ist aktiviert.
                  </Alert>
                ) : (
                  <Alert variant="danger" className="mb-0">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    2FA ist deaktiviert.
                  </Alert>
                )}

                <TotpModal
                  isEnabled={user.totp}
                  className="mt-8"
                />
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>
    </>
  );
};

Profile.propTypes = {
  fetchDispatch: PropTypes.func.isRequired,
  changePasswordDispatch: PropTypes.func.isRequired,
  resetDispatch: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  userLoading: PropTypes.bool,
  changePasswordLoading: PropTypes.bool,
  changePasswordSuccess: PropTypes.bool,
  changePasswordFail: PropTypes.bool,
};

Profile.defaultProps = {
  user: null,
  userLoading: false,
  changePasswordLoading: false,
  changePasswordSuccess: false,
  changePasswordFail: false,
};

const mapStateToProps = ({ profile }) => ({
  user: profile.user,
  userLoading: profile.userLoading,
  changePasswordLoading: profile.changePasswordLoading,
  changePasswordSuccess: profile.changePasswordSuccess,
  changePasswordFail: profile.changePasswordFail,
});

const mapDispatch = {
  fetchDispatch: fetch,
  changePasswordDispatch: changePassword,
  resetDispatch: reset,
};

export default connect(mapStateToProps, mapDispatch)(Profile);
