import React, { memo } from "react";

import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";

import "./AppointmentWarningModal.scss";

const AppointmentWarningModal = (props) => {
  const {
    show,
    onHide,
    title,
    message,
    action,
  } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="appointment-warning-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body className="d-flex flex-column align-items-center text-center">
        <FontAwesomeIcon icon={faExclamationCircle} />
        <h3 className="mt-24 mb-16">{title}</h3>
        {message}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="secondary"
          onClick={onHide}
        >
          Abbrechen
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onHide();
            action();
          }}
        >
          Ja, weiter
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AppointmentWarningModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  action: PropTypes.func,
};

AppointmentWarningModal.defaultProps = {
  show: false,
  onHide: () => {},
  title: "",
  message: "",
  action: () => {},
};

export default memo(AppointmentWarningModal);
