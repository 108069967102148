import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  emailAttachements: [],
  loading: false,
  addLoading: false,
  addSuccess: false,
  addFail: false,
  removeLoading: false,
  removeSuccess: false,
  removeFail: false,
};

const EmailAttachement = createSlice({
  name: "emailAttachement",
  initialState,
  reducers: {
    fetchAll: (state) => ({
      ...state,
      loading: true,
      emailAttachements: [],
    }),
    fetchAll_SUCCESS: (state, { payload }) => ({
      ...state,
      loading: false,
      emailAttachements: payload.data.emailAttachements,
    }),
    fetchAll_FAIL: (state) => ({
      ...state,
      loading: false,
      emailAttachements: [],
    }),
    add: (state) => ({
      ...state,
      addLoading: true,
      addSuccess: false,
      addFail: false,
    }),
    add_SUCCESS: (state) => ({
      ...state,
      addLoading: false,
      addSuccess: true,
      addFail: false,
    }),
    add_FAIL: (state) => ({
      ...state,
      addLoading: false,
      addSuccess: false,
      addFail: true,
    }),
    remove: (state) => ({
      ...state,
      removeLoading: true,
      removeSuccess: false,
      removeFail: false,
    }),
    remove_SUCCESS: (state) => ({
      ...state,
      removeLoading: false,
      removeSuccess: true,
      removeFail: false,
    }),
    remove_FAIL: (state) => ({
      ...state,
      removeLoading: false,
      removeSuccess: false,
      removeFail: true,
    }),
  },
});

export default EmailAttachement.reducer;

export const fetchAll = (ulid) => ({
  type: "emailAttachement/fetchAll",
  payload: {
    client: "default",
    request: {
      url: `portal/email-attachement/${ulid}`,
    },
  },
});

export const add = (ulid, data) => {
  const formData = new FormData();

  if (data.file !== null) {
    formData.append("file", data.file);
    // eslint-disable-next-line no-param-reassign
    delete data.file;
  }

  formData.append("data", JSON.stringify(data));

  return {
    type: "emailAttachement/add",
    payload: {
      client: "default",
      request: {
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
        url: `portal/email-attachement/add/${ulid}`,
      },
    },
  };
};

export const remove = (ulid) => ({
  type: "emailAttachement/remove",
  payload: {
    client: "default",
    request: {
      method: "delete",
      url: `portal/email-attachement/remove/${ulid}`,
    },
  },
});
