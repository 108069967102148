import React from "react";

import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import * as yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

import IconButton from "../IconButton/IconButton";
import FormRowMobilePhone from "../FormRow/FormRowMobilePhone";
import { changeMobilePhone } from "../../state/features/AppointmentBookingSlice";
import { notificationService } from "../../services/NotificationService";

import "./ChangeMobilePhoneModal.scss";

const ChangeMobilePhoneModal = (props) => {
  const {
    changeMobilePhoneDispatch,
    show,
    onHide,
    appointmentUlid,
    mobilePhone,
    editLoading,
  } = props;

  const intl = useIntl();

  if (appointmentUlid === null && mobilePhone === null) {
    return null;
  }

  const validationSchema = yup.object().shape({
    mobilePhone: yup
      .string()
      .max(20, intl.formatMessage({ id: "form.error.max_length" }, { value: 20 })),
  });

  return (
    <Formik
      initialValues={{
        mobilePhone,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        changeMobilePhoneDispatch(appointmentUlid || null, values)
          .then((action) => {
            if (action.type.endsWith("SUCCESS")) {
              onHide(values.mobilePhone);
            }

            if (action.type.endsWith("FAIL")) {
              onHide();
              notificationService.error("Die Handynummer konnte nicht geändert werden.");
            }
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Modal
          show={show}
          onHide={onHide}
          className="change-mobile-phone-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="change_mobile_phone_modal.headline" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <Row className="my-24">
                <Col md={12}>
                  <FormRowMobilePhone
                    id="mobilePhone"
                    label="Handynummer:"
                    size={8}
                    defaultValue={values.mobilePhone}
                    errors={errors.mobilePhone}
                    touched={touched.mobilePhone}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>

              <div className="text-end">
                <IconButton
                  disabled={editLoading}
                  type="submit"
                >
                  {intl.formatMessage({ id: "appointment_modal.save" })}
                  {editLoading && (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  )}
                </IconButton>
              </div>

            </form>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

ChangeMobilePhoneModal.propTypes = {
  changeMobilePhoneDispatch: PropTypes.func.isRequired,
  mobilePhone: PropTypes.bool.isRequired,
  editLoading: PropTypes.bool,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  appointmentUlid: PropTypes.oneOfType([PropTypes.object]),
};

ChangeMobilePhoneModal.defaultProps = {
  show: false,
  onHide: () => {},
  appointmentUlid: null,
  editLoading: false,
};

const mapStateToProps = ({ appointmentBooking }) => ({
  editLoading: appointmentBooking.changeMobilePhone,
  editSuccess: appointmentBooking.changeMobilePhoneSuccess,
  editFail: appointmentBooking.changeMobilePhoneFail,
});

const mapDispatch = {
  changeMobilePhoneDispatch: changeMobilePhone,
};

export default connect(mapStateToProps, mapDispatch)(ChangeMobilePhoneModal);
