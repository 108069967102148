import React, { memo } from "react";

import PropTypes from "prop-types";
import Datetime from "react-datetime";

import FormRow from "./FormRow";

const FormRowTime = (props) => {
  const {
    id,
    label,
    defaultValue,
    handleChange,
    handleBlur,
    errors,
    touched,
    hint,
    className,
    size,
    labelSize,
    ...otherProps
  } = props;

  return (
    <FormRow
      id={id}
      label={label}
      errors={errors}
      touched={touched}
      hint={hint}
      size={size}
      labelSize={labelSize}
      className={className}
      {...otherProps}
    >
      <Datetime
        timeFormat="HH:mm"
        dateFormat={false}
        initialValue={new Date(defaultValue)}
        timeConstraints={{ minutes: { step: 10 } }}
        onChange={(moment) => {
          handleChange(typeof moment === "object" ? moment.toISOString() : "");
        }}
        inputProps={{
          onBlur: handleBlur,
          placeholder: "--:--",
          name: id,
          autoComplete: "off",
          className: errors && touched ? "form-control is-invalid" : "form-control",
          ...otherProps,
        }}
      />
    </FormRow>
  );
};

FormRowTime.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.any]).isRequired,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  hint: PropTypes.string,
  className: PropTypes.string,
  labelSize: PropTypes.number,
  size: PropTypes.number,
};

FormRowTime.defaultProps = {
  errors: null,
  touched: false,
  hint: null,
  className: "",
  labelSize: 4,
  size: 7,
};

export default memo(FormRowTime);
