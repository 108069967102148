import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  user: null,
  usersLoading: false,
  userLoading: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
  removeLoading: false,
  removeSuccess: false,
  removeFail: false,
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetch: (state) => ({
      ...state,
      userLoading: true,
    }),
    fetch_SUCCESS: (state, { payload }) => ({
      ...state,
      user: payload.data.user,
      userLoading: false,
    }),
    fetch_FAIL: (state) => ({
      ...state,
      user: null,
      userLoading: false,
    }),
    fetchAll: (state) => ({
      ...state,
      users: [],
      usersLoading: true,
    }),
    fetchAll_SUCCESS: (state, { payload }) => ({
      ...state,
      users: payload.data.users,
      usersLoading: false,
    }),
    fetchAll_FAIL: (state) => ({
      ...state,
      users: [],
      usersLoading: false,
    }),
    edit: (state) => ({
      ...state,
      editLoading: true,
      editSuccess: false,
      editFail: false,
    }),
    edit_SUCCESS: (state) => ({
      ...state,
      editLoading: false,
      editSuccess: true,
      editFail: false,
    }),
    edit_FAIL: (state) => ({
      ...state,
      editLoading: false,
      editSuccess: false,
      editFail: true,
    }),
    remove: (state) => ({
      ...state,
      removeLoading: true,
      removeSuccess: false,
      removeFail: false,
    }),
    remove_SUCCESS: (state) => ({
      ...state,
      removeLoading: false,
      removeSuccess: true,
      removeFail: false,
    }),
    remove_FAIL: (state) => ({
      ...state,
      removeLoading: false,
      removeSuccess: false,
      removeFail: true,
    }),
    reset: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const { reset } = UserSlice.actions;

export default UserSlice.reducer;

export const fetch = (ulid) => ({
  type: "user/fetch",
  payload: {
    client: "default",
    request: {
      url: `portal/user/details/${ulid}`,
    },
  },
});

export const fetchAll = () => ({
  type: "user/fetchAll",
  payload: {
    client: "default",
    request: {
      url: "portal/user",
    },
  },
});

export const checkEmail = (email, ulid) => ({
  type: "user/checkEmail",
  payload: {
    client: "default",
    request: {
      url: `portal/user/check-email/${email}${ulid ? `/${ulid}` : ""}`,
    },
  },
});

export const edit = (ulid, data) => ({
  type: "user/edit",
  payload: {
    client: "default",
    request: {
      method: "post",
      data,
      url: ulid ? `portal/user/edit/${ulid}` : "portal/user/new",
    },
  },
});

export const remove = (ulid) => ({
  type: "user/remove",
  payload: {
    client: "default",
    request: {
      method: "delete",
      url: `portal/user/remove/${ulid}`,
    },
  },
});
