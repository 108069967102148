import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faTimes, faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faDotCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { faExclamationTriangle as faExclamationTriangleSolid } from "@fortawesome/pro-solid-svg-icons";
import Form from "react-bootstrap/Form";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import Dot from "../../../components/Dot/Dot";
import IconButton from "../../../components/IconButton/IconButton";
import { editWeek, fetchWeek, reset } from "../../../state/features/AppointmentTypeBudgetingSlice";
import Panel from "../../../components/Panel/Panel";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { notificationService } from "../../../services/NotificationService";

const AppointmentTypesBudgeting = (props) => {
  const {
    fetchWeekDispatch,
    editWeekDispatch,
    resetDispatch,
    appointmentTypeBudgetings,
    loading,
    editLoading,
    editSuccess,
    editFail,
  } = props;

  const [date, setDate] = useState(moment().day(1)); // initially Monday
  const [budgeting, setBudgeting] = useState({});

  useEffect(() => {
    const initBudgeting = {};

    appointmentTypeBudgetings.forEach(({ appointmentType }) => {
      initBudgeting[appointmentType.ulid] = null;

      if (appointmentType.appointmentTypeBudgetings.length > 0) {
        initBudgeting[appointmentType.ulid] = appointmentType.appointmentTypeBudgetings[0].amount;
      }
    });

    setBudgeting(initBudgeting);
  }, [appointmentTypeBudgetings]);

  useEffect(() => {
    resetDispatch();
    fetchWeekDispatch(date.week(), date.year());
  }, [resetDispatch, fetchWeekDispatch, date]);

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/appointment-type-budgeting", name: "client_navigation.appointment_type_budgetings" },
        ]}
      />

      <h3 className="mb-16">Impfstoffplaner</h3>

      <div className="mb-8 d-flex">
        <IconButton
          variant="white"
          className="me-4"
          onClick={() => {
            setDate(
              moment(date.subtract(1, "weeks")),
            );
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </IconButton>
        <Button
          variant="white"
          className="me-4"
        >
          KW{date.isoWeek()} {moment().year() !== date.year() && date.year()}
        </Button>
        <IconButton
          variant="white"
          className="me-8"
          onClick={() => {
            setDate(
              moment(date.add(1, "weeks")),
            );
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </IconButton>
        <IconButton
          variant="white"
          onClick={() => {
            setDate(moment().weekday(1));
          }}
        >
          <FontAwesomeIcon icon={faDotCircle} />
          Aktuelle&nbsp;Kalenderwoche
        </IconButton>
      </div>

      <table className="table mb-24">
        <thead>
          <tr>
            <th scope="col" className="w-100">Impfstoff</th>
            <th scope="col">Für&nbsp;Buchung<br />freigegeben</th>
            <th scope="col">Max.&nbsp;zugelassene<br />Termine</th>
            <th scope="col">Bedarf&nbsp;laut<br />Terminbuchungen</th>
            <th scope="col">Zugeteilte<br />Impfdosen</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={4} className="text-center py-24">
                <FontAwesomeIcon icon={faSpinner} spin />
              </td>
            </tr>
          )}
          {!loading && [...appointmentTypeBudgetings]
            .sort((a, b) => a.appointmentType.index - b.appointmentType.index)
            .map(({ appointmentType, count }) => (
              <tr key={appointmentType.ulid}>
                <td>
                  <div className="d-flex align-items-center h-100">
                    <Dot color={appointmentType.color} className="me-8" />
                    <div>
                      <FormattedMessage id={appointmentType.name} />
                      {appointmentType.publicName && (
                        <>
                          <br />
                          <small className="text-muted">{appointmentType.publicName}</small>
                        </>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  {appointmentType.active ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} />
                  )}
                </td>
                <td>
                  <Form.Control
                    type="text"
                    style={{ width: 80 }}
                    readOnly
                    value={appointmentType.appointmentCount ?? "∞"}
                    className="text-center"
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    style={{ width: 80 }}
                    readOnly
                    value={count}
                    className="text-center"
                  />
                </td>
                <td className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    style={{ width: 80 }}
                    className="text-center"
                    readOnly={!appointmentType.active}
                    defaultValue={
                      appointmentType.appointmentTypeBudgetings.length > 0
                        ? appointmentType.appointmentTypeBudgetings[0].amount
                        : null
                    }
                    onChange={(event) => {
                      const tmpBudgeting = budgeting;
                      const value = parseInt(event.target.value, 10);
                      tmpBudgeting[appointmentType.ulid] = isNaN(value) ? null : value;

                      setBudgeting(tmpBudgeting);
                    }}
                  />

                  {
                    budgeting[appointmentType.ulid] !== null
                    && count > budgeting[appointmentType.ulid]
                    && (
                      <OverlayTrigger
                        placement="top"
                        overlay={(
                          <Tooltip className="tooltip-warning">
                            Es sind mehr Termine gebucht als Impfdosen vorhanden.<br />
                            Es ist ratsam einige Termine zu stornieren.
                          </Tooltip>
                        )}
                      >
                        <span className="ms-16 text-warning">
                          <FontAwesomeIcon
                            icon={faExclamationTriangleSolid}
                          />
                        </span>
                      </OverlayTrigger>
                    )
                  }
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={5} />
          </tr>
        </tfoot>
      </table>

      <Panel small className="text-end">
        <IconButton
          disabled={editLoading}
          onClick={() => {
            editWeekDispatch(date.week(), date.year(), budgeting)
              .then((action) => {
                if (action.type.endsWith("SUCCESS")) {
                  notificationService.success("Impfstoffplanung erfolgreich gespeichert.");
                }
              });
          }}
        >
          Speichern
          {editLoading && (
            <FontAwesomeIcon icon={faSpinner} spin />
          )}
          {editSuccess && (
            <FontAwesomeIcon icon={faCheck} />
          )}
          {editFail && (
            <FontAwesomeIcon icon={faExclamationTriangle} />
          )}
        </IconButton>
      </Panel>
    </>
  );
};

AppointmentTypesBudgeting.propTypes = {
  fetchWeekDispatch: PropTypes.func.isRequired,
  editWeekDispatch: PropTypes.func.isRequired,
  resetDispatch: PropTypes.func.isRequired,
  appointmentTypeBudgetings: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  loading: PropTypes.bool,
  editLoading: PropTypes.bool,
  editSuccess: PropTypes.bool,
  editFail: PropTypes.bool,
};

AppointmentTypesBudgeting.defaultProps = {
  appointmentTypeBudgetings: [],
  loading: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
};

const mapStateToProps = ({ appointmentTypeBudgeting }) => ({
  appointmentTypeBudgetings: appointmentTypeBudgeting.appointmentTypeBudgetings,
  loading: appointmentTypeBudgeting.loading,
  editLoading: appointmentTypeBudgeting.editLoading,
  editSuccess: appointmentTypeBudgeting.editSuccess,
  editFail: appointmentTypeBudgeting.editFail,
});

const mapDispatch = {
  fetchWeekDispatch: fetchWeek,
  editWeekDispatch: editWeek,
  resetDispatch: reset,
};

export default connect(mapStateToProps, mapDispatch)(AppointmentTypesBudgeting);
