import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appointmentTypeBudgetings: [],
  loading: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
};

const AppointmentTypeBudgetingSlice = createSlice({
  name: "appointmentTypeBudgeting",
  initialState,
  reducers: {
    fetchWeek: (state) => ({
      ...state,
      loading: true,
    }),
    fetchWeek_SUCCESS: (state, { payload }) => ({
      ...state,
      loading: false,
      appointmentTypeBudgetings: payload.data.appointmentTypeBudgetings,
    }),
    fetchWeek_FAIL: (state) => ({
      ...state,
      loading: false,
    }),
    editWeek: (state) => ({
      ...state,
      editLoading: true,
      editSuccess: false,
      editFail: false,
    }),
    editWeek_SUCCESS: (state) => ({
      ...state,
      editLoading: false,
      editSuccess: true,
      editFail: false,
    }),
    editWeek_FAIL: (state) => ({
      ...state,
      editLoading: false,
      editSuccess: false,
      editFail: true,
    }),
    reset: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const { reset } = AppointmentTypeBudgetingSlice.actions;

export default AppointmentTypeBudgetingSlice.reducer;

export const fetchWeek = (week, year) => ({
  type: "appointmentTypeBudgeting/fetchWeek",
  payload: {
    client: "default",
    request: {
      url: `portal/appointment-type-budgeting/week-details/${week}/${year}`,
    },
  },
});

export const editWeek = (week, year, budgeting) => ({
  type: "appointmentTypeBudgeting/editWeek",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        week,
        year,
        budgeting,
      },
      url: "portal/appointment-type-budgeting/edit-week",
    },
  },
});
