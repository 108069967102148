import React, { useState } from "react";

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { FormattedMessage } from "react-intl";

import "./MultiSelect.scss";

const MultiSelect = ({ options, defaultOptions, onChange }) => {
  const [selection, setSelection] = useState(defaultOptions);

  return (
    <div className="multi-select">
      <div className="options">
        {options.map(({ label, value }) => (
          <Button
            size="sm"
            key={value}
            variant={selection.includes(value) ? "primary" : "tertiary"}
            onClick={() => {
              const tmpSelection = selection.includes(value)
                ? selection.filter((selectionValue) => selectionValue !== value)
                : [...selection, value];

              setSelection(tmpSelection);
              onChange(tmpSelection);
            }}
          >
            {label}
          </Button>
        ))}
      </div>

      <div className="text-end mt-32">
        <Button
          variant="secondary"
          size="sm"
          className="me-8"
          onClick={() => {
            setSelection([]);
            onChange([]);
          }}
        >
          <FormattedMessage id="multiselect.select_none" />
        </Button>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => {
            setSelection(options.map(({ value }) => value));
            onChange(options.map(({ value }) => value));
          }}
        >
          <FormattedMessage id="multiselect.select_all" />
        </Button>
      </div>
    </div>
  );
};

MultiSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  defaultOptions: PropTypes.oneOfType([PropTypes.array]),
};

MultiSelect.defaultProps = {
  defaultOptions: [],
};

export default MultiSelect;
