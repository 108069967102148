import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { Formik } from "formik";
import * as yup from "yup";
import { useIntl } from "react-intl";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import Panel from "../../../components/Panel/Panel";
import IconButton from "../../../components/IconButton/IconButton";
import Loading from "../../../components/Loading/Loading";
import { edit, fetch, reset } from "../../../state/features/WorkingTimeSlice";
import FormRowText from "../../../components/FormRow/FormRowText";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { fetchAll } from "../../../state/features/AppointmentTypeSlice";
import FormRowTime from "../../../components/FormRow/FormRowTime";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { notificationService } from "../../../services/NotificationService";

const WorkingTimeEdit = (props) => {
  const {
    fetchDispatch,
    fetchAllAppointmentTypesDispatch,
    editDispatch,
    resetDispatch,
    workingTime,
    appointmentTypes,
    loading,
    editLoading,
    editSuccess,
    editFail,
    editSet,
  } = props;

  const {
    ulid,
  } = useParams();

  const intl = useIntl();

  const navigate = useNavigate();

  useEffect(() => {
    resetDispatch();
    fetchDispatch(ulid);
    fetchAllAppointmentTypesDispatch();
  }, [
    resetDispatch,
    fetchAllAppointmentTypesDispatch,
    fetchDispatch,
    ulid,
  ]);

  const validationSchema = yup.object().shape({
    startTime: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    endTime: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    appointmentCount: yup
      .number(intl.formatMessage({ id: "form.error.numeric" }))
      .required(intl.formatMessage({ id: "form.error.required" })),
    appointmentTypes: yup
      .array()
      .min(1, intl.formatMessage({ id: "form.error.required" })),
  });

  if (loading || workingTime === null) {
    return <Loading />;
  }

  const currentDay = moment();

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/working-time", name: "client_navigation.working_time_calendar" },
          { to: `/portal/working-time/edit/${workingTime.ulid}`, name: "client_navigation.working_time_edit" },
        ]}
      />

      <h3 className="mb-16">Arbeitszeit bearbeiten</h3>

      <Formik
        initialValues={{
          startTime: moment(workingTime.startTime).set({
            year: currentDay.year(),
            month: currentDay.month(),
            day: currentDay.day(),
          }).toISOString(),
          endTime: moment(workingTime.endTime).set({
            year: currentDay.year(),
            month: currentDay.month(),
            day: currentDay.day(),
          }).toISOString(),
          appointmentCount: workingTime.appointmentCount,
          appointmentTypes: workingTime.appointmentTypes
            .map((appointmentType) => appointmentType.ulid),
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          const {
            startTime,
            endTime,
          } = values;

          if (moment(startTime).isSameOrAfter(endTime)) {
            errors.endTime = intl.formatMessage({ id: "form.error.time_after" });
          }

          return errors;
        }}
        onSubmit={(values) => {
          editDispatch(workingTime.ulid, values, editSet)
            .then((action) => {
              if (action.type.endsWith("SUCCESS")) {
                notificationService.success("Arbeitszeit(en) erfolgreich gespeichert.");
                navigate("/portal/working-time", { replace: true });
              }
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Wählen die Startzeit und die Endzeit der geplanten Arbeitzeit.
              </Col>
              <Col md={9}>
                <Panel headline="An welchen Stunden soll die Arbeitszeit gelten?">
                  <FormRowTime
                    id="startTime"
                    name="startTime"
                    label="Beginn*"
                    className="mb-24"
                    defaultValue={values.startTime}
                    errors={errors.startTime}
                    touched={touched.startTime}
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setFieldValue("startTime", value);
                    }}
                  />

                  <FormRowTime
                    id="endTime"
                    name="endTime"
                    label="Ende*"
                    defaultValue={values.endTime}
                    errors={errors.endTime}
                    touched={touched.endTime}
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setFieldValue("endTime", value);
                    }}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Es sind beliebig viele gleichzeitige Termine möglich. 1 = Ein Termin pro Startzeit
              </Col>
              <Col md={9}>
                <Panel headline="Wie viele Termine möchten Sie parallel anbieten?">
                  <FormRowText
                    id="appointmentCount"
                    name="appointmentCount"
                    label="Anzahl gleichzeitiger Termine"
                    defaultValue={values.appointmentCount}
                    errors={errors.appointmentCount}
                    touched={touched.appointmentCount}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={{ span: 9, offset: 3 }}>
                <Panel headline="Welche Terminarten sollen in dieser Arbeitszeit angeboten werden?">
                  <MultiSelect
                    onChange={(options) => { setFieldValue("appointmentTypes", options); }}
                    options={[...appointmentTypes]
                      .sort((a, b) => a.index - b.index)
                      .map((appointmentType) => ({
                        value: appointmentType.ulid,
                        label: intl.formatMessage({ id: appointmentType.name }),
                      }))}
                    defaultOptions={values.appointmentTypes}
                  />

                  {errors.appointmentTypes && touched.appointmentTypes && (
                    <Form.Control.Feedback type="invalid">
                      {errors.appointmentTypes}
                    </Form.Control.Feedback>
                  )}
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={{ span: 9, offset: 3 }}>
                <Panel small className="text-end">
                  <IconButton
                    disabled={editLoading}
                    type="submit"
                  >
                    Speichern
                    {editLoading && (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                    {editSuccess && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    {editFail && (
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    )}
                  </IconButton>
                </Panel>
              </Col>
            </Row>

          </form>
        )}
      </Formik>
    </>
  );
};

WorkingTimeEdit.propTypes = {
  fetchAllAppointmentTypesDispatch: PropTypes.func.isRequired,
  fetchDispatch: PropTypes.func.isRequired,
  editDispatch: PropTypes.func.isRequired,
  resetDispatch: PropTypes.func.isRequired,
  workingTime: PropTypes.oneOfType([PropTypes.object]),
  appointmentTypes: PropTypes.oneOfType([PropTypes.array]),
  loading: PropTypes.bool,
  editLoading: PropTypes.bool,
  editSuccess: PropTypes.bool,
  editFail: PropTypes.bool,
  editSet: PropTypes.bool,
};

WorkingTimeEdit.defaultProps = {
  workingTime: null,
  appointmentTypes: [],
  loading: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
  editSet: false,
};

const mapStateToProps = ({ appointmentType, workingTime }) => ({
  appointmentTypes: appointmentType.appointmentTypes,
  workingTime: workingTime.workingTime,
  loading: workingTime.loading,
  editLoading: workingTime.editLoading,
  editSuccess: workingTime.editSuccess,
  editFail: workingTime.editFail,
});

const mapDispatch = {
  fetchAllAppointmentTypesDispatch: fetchAll,
  fetchDispatch: fetch,
  editDispatch: edit,
  resetDispatch: reset,
};

export default connect(mapStateToProps, mapDispatch)(WorkingTimeEdit);
