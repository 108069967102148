import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: null,
  loading: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
};

const ClientBaseDataSlice = createSlice({
  name: "clientBaseData",
  initialState,
  reducers: {
    fetch: (state) => ({
      ...state,
      loading: true,
      client: null,
    }),
    fetch_SUCCESS: (state, { payload }) => ({
      ...state,
      loading: false,
      client: payload.data.client,
    }),
    fetch_FAIL: (state, { payload }) => ({
      ...state,
      loading: false,
      client: null,
    }),
    edit: (state) => ({
      ...state,
      editLoading: true,
      editSuccess: false,
      editFail: false,
    }),
    edit_SUCCESS: (state, { payload }) => ({
      ...state,
      editLoading: false,
      editSuccess: true,
      editFail: false,
    }),
    edit_FAIL: (state, { payload }) => ({
      ...state,
      editLoading: false,
      editSuccess: false,
      editFail: true,
    }),
    reset: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const { reset } = ClientBaseDataSlice.actions;

export default ClientBaseDataSlice.reducer;

export const fetch = () => ({
  type: "clientBaseData/fetch",
  payload: {
    client: "default",
    request: {
      url: "portal/base-data/details",
    },
  },
});

export const edit = (data) => {
  const formData = new FormData();

  if (data.logo !== null) {
    formData.append("logo", data.logo);
    // eslint-disable-next-line no-param-reassign
    delete data.logo;
  }

  formData.append("data", JSON.stringify(data));

  return {
    type: "clientBaseData/edit",
    payload: {
      client: "default",
      request: {
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
        url: "portal/base-data/edit",
      },
    },
  };
};
