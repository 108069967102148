import React, { useEffect } from "react";

import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import * as yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  faHourglassStart,
  faTrash,
  faCopy,
  faExchange,
  faClock,
  faUserSlash,
  faSyringe,
  faShieldCheck,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import moment from "moment";
import classNames from "classnames";

import {
  changeState,
  edit,
  fetch,
  reset,
  remove,
} from "../../state/features/AppointmentSlice";
import FormRowText from "../FormRow/FormRowText";
import FormRowDate from "../FormRow/FormRowDate";
import FormRowSelect from "../FormRow/FormRowSelect";
import IconButton from "../IconButton/IconButton";
import { fetchAll } from "../../state/features/AppointmentTypeSlice";
import AppointmentTimer from "../AppointmentTimer/AppointmentTimer";
import {
  APPOINTMENT_STATE_ABSENT,
  APPOINTMENT_STATE_DONE,
  APPOINTMENT_STATE_EXPIRED,
  APPOINTMENT_STATE_STARTED,
  APPOINTMENT_STATE_WAITING,
  COUNTRIES,
  GENDERS,
} from "../../constants";
import ConfirmButton from "../ConfirmButton/ConfirmButton";
import FormRowMobilePhone from "../FormRow/FormRowMobilePhone";
import { notificationService } from "../../services/NotificationService";

import "./AppointmentModal.scss";

const AppointmentModal = (props) => {
  const {
    fetchDispatch,
    editDispatch,
    fetchAllAppointmentTypesDispatch,
    resetDispatch,
    changeStateDispatch,
    show,
    onHide,
    onMove,
    onCopy,
    appointmentUlid,
    dateTime,
    appointment,
    appointmentTypes,
    overbookedAppointmentTypes,
    editLoading,
    editSuccess,
    editFail,
    removeDispatch,
  } = props;

  const intl = useIntl();

  // fetch appointment if ulid is provided
  useEffect(() => {
    fetchAllAppointmentTypesDispatch();

    if (appointmentUlid !== null) {
      fetchDispatch(appointmentUlid);
    }
  }, [
    fetchAllAppointmentTypesDispatch,
    fetchDispatch,
    appointmentUlid,
  ]);

  if (!appointmentTypes || !Array.isArray(appointmentTypes) || appointmentTypes.length === 0) {
    return null;
  }

  if (appointmentUlid === null && dateTime === null) {
    return null;
  }

  if (appointmentUlid !== null && appointment === null) {
    return null;
  }

  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    lastname: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    birthdate: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    gender: yup
      .number()
      .typeError(intl.formatMessage({ id: "form.error.required" }))
      .required(intl.formatMessage({ id: "form.error.required" })),
    email: yup
      .string()
      .email(intl.formatMessage({ id: "form.error.email" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    mobilePhone: yup
      .string()
      .max(20, intl.formatMessage({ id: "form.error.max_length" }, { value: 20 })),
    street: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    houseNumber: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(12, intl.formatMessage({ id: "form.error.max_length" }, { value: 12 })),
    zip: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(5, intl.formatMessage({ id: "form.error.max_length" }, { value: 5 })),
    city: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    country: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    appointmentType: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    batchNumber: yup
      .string()
      .max(10, intl.formatMessage({ id: "form.error.max_length" }, { value: 10 })),
    explained: yup
      .boolean()
      .required(intl.formatMessage({ id: "form.error.required" })),
    previousIllness: yup
      .boolean()
      .required(intl.formatMessage({ id: "form.error.required" })),
    comment: yup
      .string()
      .max(500, intl.formatMessage({ id: "form.error.max_length" }, { value: 500 })),
  });

  const setAppointmenState = (setFieldValue, newState) => {
    setFieldValue("state", newState);

    if (appointment !== null) {
      changeStateDispatch(appointment.ulid, newState);
    }
  };

  const appointmentDateTime = dateTime !== null
    ? moment(dateTime)
    : moment(appointment.dateTime);

  const hide = () => {
    resetDispatch();
    onHide(appointmentDateTime);
  };

  return (
    <Formik
      initialValues={{
        lastname: appointment?.lastname || "",
        firstname: appointment?.firstname || "",
        birthdate: appointment?.birthdate ? moment(appointment?.birthdate).format("YYYY-MM-DD") : "",
        gender: typeof appointment?.gender !== "undefined" ? appointment.gender : "",
        email: appointment?.email || "",
        mobilePhone: appointment?.mobilePhone || "",
        street: appointment?.street || "",
        houseNumber: appointment?.houseNumber || "",
        zip: appointment?.zip || "",
        city: appointment?.city || "",
        country: appointment?.country || "",
        appointmentType: appointment?.appointmentType.ulid || "",
        explained: appointment?.explained ?? "",
        previousIllness: appointment?.previousIllness ?? "",
        batchNumber: appointment?.batchNumber || "",
        comment: appointment?.comment || "",
        dateTime: appointmentDateTime.toISOString(),
        state: appointment?.state || 0,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        editDispatch(appointment?.ulid || null, values)
          .then((action) => {
            if (action.type.endsWith("SUCCESS")) {
              hide();
            }
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Modal
          show={show}
          fullscreen
          onHide={hide}
          className="appointment-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {appointment === null && dateTime
                ? intl.formatMessage({
                  id: "appointment_modal.headline_new_appointment",
                }, {
                  dateTime: dateTime.format("LLLL"),
                })
                : intl.formatMessage({
                  id: "appointment_modal.appointment_details_for",
                }, {
                  firstname: appointment.firstname,
                  lastname: appointment.lastname,
                  dateTime: moment(appointment.dateTime).format("LLLL"),
                })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="appointment-modal__main-row g-0">
              <Col md={9}>

                <form onSubmit={handleSubmit}>

                  <h5 className="mb-24">
                    <FormattedMessage id="appointment_modal.personal_data" />
                  </h5>

                  <Row className="mb-24">
                    <Col md={6}>
                      <FormRowText
                        id="lastname"
                        label={intl.formatMessage({ id: "appointment_modal.lastname" })}
                        size={8}
                        defaultValue={values.lastname}
                        errors={errors.lastname}
                        touched={touched.lastname}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col md={6}>
                      <FormRowText
                        id="firstname"
                        label={intl.formatMessage({ id: "appointment_modal.firstname" })}
                        size={8}
                        defaultValue={values.firstname}
                        errors={errors.firstname}
                        touched={touched.firstname}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-24">
                    <Col md={6}>
                      <FormRowDate
                        id="birthdate"
                        label={intl.formatMessage({ id: "appointment_modal.birthdate" })}
                        size={8}
                        defaultValue={values.birthdate}
                        initialViewMode="years"
                        initialViewDate={new Date("1990-01-01")}
                        errors={errors.birthdate}
                        touched={touched.birthdate}
                        handleBlur={handleBlur}
                        handleChange={(value) => {
                          setFieldValue("birthdate", value);
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <FormRowSelect
                        id="gender"
                        label={intl.formatMessage({ id: "appointment_modal.gender" })}
                        options={GENDERS.map(({ value, label }) => ({
                          value,
                          label: intl.formatMessage({ id: label }),
                        }))}
                        size={8}
                        defaultValue={values.gender}
                        errors={errors.gender}
                        touched={touched.gender}
                        handleBlur={handleBlur}
                        handleChange={(event) => {
                          setFieldValue("gender", parseInt(event.target.value, 10));
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-24">
                    <Col md={6}>
                      <FormRowText
                        id="email"
                        label={intl.formatMessage({ id: "appointment_modal.email" })}
                        size={8}
                        defaultValue={values.email}
                        errors={errors.email}
                        touched={touched.email}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col md={6}>
                      <FormRowMobilePhone
                        id="mobilePhone"
                        label={intl.formatMessage({ id: "appointment_modal.mobile_number" })}
                        size={8}
                        defaultValue={values.mobilePhone}
                        errors={errors.mobilePhone}
                        touched={touched.mobilePhone}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-24">
                    <Col md={6}>
                      <FormRowText
                        id="street"
                        label={intl.formatMessage({ id: "appointment_modal.street" })}
                        size={8}
                        defaultValue={values.street}
                        errors={errors.street}
                        touched={touched.street}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col md={6}>
                      <FormRowText
                        id="houseNumber"
                        label={intl.formatMessage({ id: "appointment_modal.house_number" })}
                        size={8}
                        defaultValue={values.houseNumber}
                        errors={errors.houseNumber}
                        touched={touched.houseNumber}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-24">
                    <Col md={6}>
                      <FormRowText
                        id="zip"
                        label={intl.formatMessage({ id: "appointment_modal.zip" })}
                        size={8}
                        defaultValue={values.zip}
                        errors={errors.zip}
                        touched={touched.zip}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col md={6}>
                      <FormRowText
                        id="city"
                        label={intl.formatMessage({ id: "appointment_modal.city" })}
                        size={8}
                        defaultValue={values.city}
                        errors={errors.city}
                        touched={touched.city}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-32">
                    <Col md={6}>
                      <FormRowSelect
                        id="country"
                        label={intl.formatMessage({ id: "appointment_modal.country" })}
                        options={COUNTRIES.map(({ value, label }) => ({
                          value,
                          label: intl.formatMessage({ id: label }),
                        }))}
                        size={8}
                        defaultValue={values.country}
                        errors={errors.country}
                        touched={touched.country}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <h5 className="mb-24">
                    <FormattedMessage id="appointment_modal.appointment_data" />
                  </h5>

                  <div className="mb-24">
                    <FormRowSelect
                      id="appointmentType"
                      label={intl.formatMessage({ id: "appointment_modal.appointment_type" })}
                      options={[...appointmentTypes]
                        .sort((a, b) => a.index - b.index)
                        .map((appointmentType) => ({
                          value: appointmentType.ulid,
                          label: intl.formatMessage({ id: appointmentType.name }),
                        }))}
                      labelSize={2}
                      size={10}
                      defaultValue={values.appointmentType}
                      errors={
                        overbookedAppointmentTypes
                          .map(({ appointmentType }) => appointmentType)
                          .filter(({ ulid }) => (ulid === values.appointmentType))
                          .length > 0 ? intl.formatMessage({ id: "appointment_modal.appointment_type_overbooked" }) : errors.appointmentType
                      }
                      touched={touched.appointmentType}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>

                  <Row className="mb-24">
                    <Col md={6}>
                      <FormRowSelect
                        id="explained"
                        label={intl.formatMessage({ id: "appointment_modal.explained" })}
                        size={8}
                        options={[
                          { value: true, label: "ja" },
                          { value: false, label: "nein" },
                        ]}
                        defaultValue={values.explained}
                        errors={errors.explained}
                        touched={touched.explained}
                        handleBlur={handleBlur}
                        handleChange={(event) => {
                          setFieldValue("explained", event.target.value);
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <FormRowSelect
                        id="previousIllness"
                        label={intl.formatMessage({ id: "appointment_modal.previous_illness" })}
                        size={8}
                        options={[
                          { value: true, label: "ja" },
                          { value: false, label: "nein" },
                        ]}
                        defaultValue={values.previousIllness}
                        errors={errors.previousIllness}
                        touched={touched.previousIllness}
                        handleBlur={handleBlur}
                        handleChange={(event) => {
                          setFieldValue("previousIllness", event.target.value);
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-24">
                    <Col md={6}>
                      <FormRowText
                        id="id"
                        label={intl.formatMessage({ id: "appointment_modal.unique_id" })}
                        size={8}
                        readOnly
                        defaultValue={appointment?.ulid}
                      />
                    </Col>
                    <Col md={6}>
                      <FormRowText
                        id="batchNumber"
                        label={intl.formatMessage({ id: "appointment_modal.batch_number" })}
                        size={8}
                        placeholder={intl.formatMessage({ id: "appointment_modal.please_fill" })}
                        defaultValue={values.batchNumber}
                        errors={errors.batchNumber}
                        touched={touched.batchNumber}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <div className="mb-24">
                    <FormRowText
                      id="comment"
                      label={intl.formatMessage({ id: "appointment_modal.comment" })}
                      labelSize={2}
                      size={10}
                      as="textarea"
                      defaultValue={values.comment}
                      errors={errors.comment}
                      touched={touched.comment}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </div>

                  <div className="text-end">
                    <IconButton
                      disabled={editLoading}
                      type="submit"
                    >
                      {intl.formatMessage({ id: "appointment_modal.save" })}
                      {editLoading && (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      )}
                      {editSuccess && (
                        <FontAwesomeIcon icon={faCheck} />
                      )}
                      {editFail && (
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      )}
                    </IconButton>
                  </div>
                </form>
              </Col>
              <Col
                md={3}
                className="appointment-modal__sidebar"
              >
                {appointmentUlid && (
                  <>
                    <small className="appointment-modal__sidebar-headline">Terminaktionen</small>
                    <ConfirmButton
                      onClick={() => {
                        removeDispatch(appointmentUlid)
                          .then((action) => {
                            if (action.type.endsWith("SUCCESS")) {
                              notificationService.success(intl.formatMessage({ id: "notification.appointment_deleted" }));
                              hide();
                            }
                          });
                      }}
                    >
                      <button
                        type="button"
                        className="appointment-modal__sidebar-button navbar-button"
                      >
                        <FontAwesomeIcon icon={faTrash} fixedWidth />
                        {intl.formatMessage({ id: "appointment_modal.delete_appointment" })}
                      </button>
                    </ConfirmButton>
                    <button
                      type="button"
                      onClick={() => {
                        onCopy(appointment.ulid);
                        hide();
                      }}
                      className="appointment-modal__sidebar-button navbar-button"
                    >
                      <FontAwesomeIcon icon={faCopy} fixedWidth />
                      {intl.formatMessage({ id: "appointment_modal.copy_appointment" })}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        onMove(appointment.ulid);
                        hide();
                      }}
                      className="appointment-modal__sidebar-button navbar-button mb-40"
                    >
                      <FontAwesomeIcon icon={faExchange} fixedWidth />
                      {intl.formatMessage({ id: "appointment_modal.move_appointment" })}
                    </button>
                  </>
                )}

                <small className="appointment-modal__sidebar-headline">
                  {intl.formatMessage({ id: "appointment_modal.change_appointment_state" })}
                </small>
                <button
                  type="button"
                  className={classNames(
                    "appointment-modal__sidebar-button navbar-button",
                    { "appointment-modal__sidebar-button--active": values.state === APPOINTMENT_STATE_ABSENT },
                  )}
                  onClick={() => { setAppointmenState(setFieldValue, APPOINTMENT_STATE_ABSENT); }}
                >
                  <FontAwesomeIcon icon={faClock} fixedWidth />
                  {intl.formatMessage({ id: "appointment_modal.appointment_state_absent" })}
                </button>
                <button
                  type="button"
                  className={classNames(
                    "appointment-modal__sidebar-button navbar-button",
                    { "appointment-modal__sidebar-button--active": values.state === APPOINTMENT_STATE_WAITING },
                  )}
                  onClick={() => { setAppointmenState(setFieldValue, APPOINTMENT_STATE_WAITING); }}
                >
                  <FontAwesomeIcon icon={faHourglassStart} fixedWidth />
                  {intl.formatMessage({ id: "appointment_modal.appointment_state_waiting" })}
                </button>
                <button
                  type="button"
                  className={classNames(
                    "appointment-modal__sidebar-button navbar-button",
                    { "appointment-modal__sidebar-button--active": values.state === APPOINTMENT_STATE_EXPIRED },
                  )}
                  onClick={() => { setAppointmenState(setFieldValue, APPOINTMENT_STATE_EXPIRED); }}
                >
                  <FontAwesomeIcon icon={faUserSlash} fixedWidth />
                  {intl.formatMessage({ id: "appointment_modal.appointment_state_expired" })}
                </button>
                <button
                  type="button"
                  className={classNames(
                    "appointment-modal__sidebar-button navbar-button",
                    { "appointment-modal__sidebar-button--active": values.state === APPOINTMENT_STATE_STARTED },
                  )}
                  onClick={() => { setAppointmenState(setFieldValue, APPOINTMENT_STATE_STARTED); }}
                >
                  <FontAwesomeIcon icon={faSyringe} fixedWidth />
                  {intl.formatMessage({ id: "appointment_modal.appointment_state_started" })}
                </button>
                <button
                  type="button"
                  className={classNames(
                    "appointment-modal__sidebar-button navbar-button",
                    { "appointment-modal__sidebar-button--active": values.state === APPOINTMENT_STATE_DONE },
                  )}
                  onClick={() => { setAppointmenState(setFieldValue, APPOINTMENT_STATE_DONE); }}
                >
                  <FontAwesomeIcon icon={faShieldCheck} />
                  {intl.formatMessage({ id: "appointment_modal.appointment_state_done" })}
                </button>

                {appointment?.appointmentStarted && (
                  <AppointmentTimer
                    dateTime={appointment.appointmentStarted}
                    className="mx-16 mt-40"
                  />
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

AppointmentModal.propTypes = {
  fetchDispatch: PropTypes.func.isRequired,
  editDispatch: PropTypes.func.isRequired,
  fetchAllAppointmentTypesDispatch: PropTypes.func.isRequired,
  changeStateDispatch: PropTypes.func.isRequired,
  resetDispatch: PropTypes.func.isRequired,
  removeDispatch: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onMove: PropTypes.func,
  onCopy: PropTypes.func,
  appointmentUlid: PropTypes.string,
  dateTime: PropTypes.oneOfType([PropTypes.object]),
  appointment: PropTypes.oneOfType([PropTypes.object]),
  appointmentTypes: PropTypes.oneOfType([PropTypes.array]),
  overbookedAppointmentTypes: PropTypes.oneOfType([PropTypes.array]),
  editLoading: PropTypes.bool,
  editSuccess: PropTypes.bool,
  editFail: PropTypes.bool,
};

AppointmentModal.defaultProps = {
  show: false,
  onHide: () => {},
  onMove: () => {},
  onCopy: () => {},
  appointmentTypes: [],
  overbookedAppointmentTypes: [],
  appointmentUlid: null,
  dateTime: null,
  appointment: null,
  editLoading: false,
  editSuccess: false,
  editFail: false,
};

const mapStateToProps = ({ appointmentType, appointment }) => ({
  appointmentTypes: appointmentType.appointmentTypes,
  appointment: appointment.appointment,
  editLoading: appointment.editLoading,
  editSuccess: appointment.editSuccess,
  editFail: appointment.editFail,
});

const mapDispatch = {
  fetchAllAppointmentTypesDispatch: fetchAll,
  fetchDispatch: fetch,
  editDispatch: edit,
  resetDispatch: reset,
  changeStateDispatch: changeState,
  removeDispatch: remove,
};

export default connect(mapStateToProps, mapDispatch)(AppointmentModal);
