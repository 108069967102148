import React, { memo } from "react";

import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import Hint from "../Hint/Hint";

const FormRow = (props) => {
  const {
    children,
    id,
    label,
    errors,
    touched,
    hint,
    className,
    size,
    labelSize,
    ...otherProps
  } = props;

  return (
    <Form.Group as={Row} {...otherProps} className={className} controlId={id}>
      <Form.Label column md={labelSize}>
        {label}
      </Form.Label>
      <Col md={size}>
        {children}

        {errors && touched && (
          <Form.Control.Feedback type="invalid">
            {errors}
          </Form.Control.Feedback>
        )}
      </Col>
      {hint && (
        <Col md={1} className="d-flex align-items-center">
          <Hint text={hint} />
        </Col>
      )}
    </Form.Group>
  );
};

FormRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  hint: PropTypes.string,
  className: PropTypes.string,
  labelSize: PropTypes.number,
  size: PropTypes.number,
};

FormRow.defaultProps = {
  errors: null,
  touched: false,
  hint: null,
  className: "",
  labelSize: 4,
  size: 7,
};

export default memo(FormRow);
