import { createSlice } from "@reduxjs/toolkit";

const CalendarSlice = createSlice({
  name: "calendar",
  initialState: {},
  reducers: {
  },
});

export default CalendarSlice.reducer;

export const fetchEvents = (startDate, endDate) => ({
  type: "calendar/fetchEvents",
  payload: {
    client: "default",
    request: {
      url: `portal/calendar/${startDate}/${endDate}`,
    },
  },
});

export const getInfo = (dateTime) => ({
  type: "calendar/getInfo",
  payload: {
    client: "default",
    request: {
      url: `portal/calendar/info/${dateTime}`,
    },
  },
});
