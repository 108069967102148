import React, { useState } from "react";

import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCalendarCheck,
} from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "react-bootstrap/Button";

import "./AppointmentBookingCard.scss";

const AppointmentBookingCard = (props) => {
  const {
    appointmentDateTime,
    appointmentType,
    clientName,
    reservationExpireTime,
    onChange,
    onExpired,
    success,
    className,
  } = props;

  const intl = useIntl();

  const [timer, setTimer] = useState("00:00");

  if (!success) {
    const timeout = setTimeout(() => {
      const seconds = -1 * moment().diff(reservationExpireTime, "seconds");

      if (seconds <= 0) {
        onExpired();
        setTimer("00:00");
        clearTimeout(timeout);

        return;
      }

      setTimer(
        `${Math.floor(seconds / 60).toString().padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`,
      );
    }, 1000);
  }

  return (
    <div className={classNames("appointment-reservation-timer", className)}>
      <FontAwesomeIcon icon={success ? faCalendarCheck : faClock} />
      <div>
        <div className="mb-8 navbar-button">
          {clientName}
        </div>
        <h3 className="mb-8">
          {appointmentDateTime.format("dd., DD.MM.YYYY, HH:mm")} <FormattedMessage id="appointment_booking_card.oclock" />
        </h3>
        <div className="navbar-button">
          {appointmentType.publicName
            ? appointmentType.publicName
            : intl.formatMessage({ id: appointmentType.name })}
        </div>
        {!success && (
          <div
            className="mt-8"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "appointment_booking_card.reservation_timer" }, { timer: `<b>${timer}</b>` }),
            }}
          />
        )}
      </div>
      {!success && (
        <Button variant="secondary" onClick={onChange}>
          <FormattedMessage id="appointment_booking_card.back" />
        </Button>
      )}
    </div>
  );
};

AppointmentBookingCard.propTypes = {
  appointmentDateTime: PropTypes.oneOfType([PropTypes.object]).isRequired,
  appointmentType: PropTypes.oneOfType([PropTypes.object]).isRequired,
  clientName: PropTypes.string.isRequired,
  reservationExpireTime: PropTypes.string,
  onChange: PropTypes.func,
  onExpired: PropTypes.func,
  success: PropTypes.bool,
  className: PropTypes.string,
};

AppointmentBookingCard.defaultProps = {
  className: "",
  onExpired: () => {},
  onChange: () => {},
  reservationExpireTime: null,
  success: false,
};

export default AppointmentBookingCard;
