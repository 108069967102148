import { createSlice } from "@reduxjs/toolkit";

const AppointmentDeleteSlice = createSlice({
  name: "appointmentDelete",
  initialState: {
    deleteAppointmentLoading: false,
    deleteAppointmentSuccess: false,
    deleteAppointmentFail: false,
  },
  reducers: {
    deleteAppointment: (state) => ({
      ...state,
      deleteAppointmentLoading: true,
      deleteAppointmentSuccess: false,
      deleteAppointmentFail: false,
    }),
    deleteAppointment_SUCCESS: (state, { payload }) => ({
      ...state,
      deleteAppointmentLoading: false,
      deleteAppointmentSuccess: true,
      deleteAppointmentFail: false,
    }),
    deleteAppointment_FAIL: (state) => ({
      ...state,
      deleteAppointmentLoading: false,
      deleteAppointmentSuccess: false,
      deleteAppointmentFail: true,
    }),
  },
});

export default AppointmentDeleteSlice.reducer;

export const deleteAppointment = (appointmentUlid, birthdate) => ({
  type: "appointmentDelete/deleteAppointment",
  payload: {
    client: "default",
    request: {
      method: "delete",
      data: {
        birthdate,
      },
      url: `/appointment/delete/${appointmentUlid}`,
    },
  },
});
