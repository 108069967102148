import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  faDownload,
  faGlobe,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Panel from "../../../components/Panel/Panel";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

const Help = () => (
  <>
    <BreadCrumbs
      crumbs={[
        { to: "/portal/dashboard", name: "client_navigation.dashboard" },
        { to: "/portal/help", name: "client_navigation.help" },
      ]}
    />

    <h1 className="mb-24">Hilfe</h1>
    <h3 className="mb-16">Wichtige Dokumente</h3>
    <Row>
      <Col md={6} className="mb-24">
        <Panel
          headline="Aufklärungsmerkblatt mRNA-Impfstoff"
        >
          <div className="d-flex align-items-baseline mb-16">
            <FontAwesomeIcon icon={faDownload} fixedWidth className="me-8" />
            <div>
              <a
                href="https://www.rki.de/DE/Content/Infekt/Impfen/Materialien/Downloads-COVID-19/Aufklaerungsbogen-de.pdf?__blob=publicationFile"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                Aufklärungsmerkblatt zur Schutzimpfung gegen COVID-19 mit mRNA-Impfstoff
              </a>
              <div className="text-tertiary">PDF, 89 KB, Datei ist nicht barrierefrei</div>
            </div>
          </div>

          <div className="d-flex align-items-baseline mb-16">
            <FontAwesomeIcon icon={faDownload} fixedWidth className="me-8" />
            <div>
              <a
                href="https://www.rki.de/DE/Content/Infekt/Impfen/Materialien/Downloads-COVID-19/Einwilligung-de.pdf?__blob=publicationFile"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                Anamnese- und Einwilligungsbogen zur Schutzimpfung gegen COVID-19 mit mRNA-Impfstoff
              </a>
              <div className="text-tertiary">PDF, 989 KB, Datei ist nicht barrierefrei</div>
            </div>
          </div>

          <div className="d-flex align-items-baseline">
            <FontAwesomeIcon icon={faGlobe} fixedWidth className="me-8" />
            <div>
              <a
                href="https://www.rki.de/DE/Content/Infekt/Impfen/Materialien/COVID-19-Aufklaerungsbogen-Tab.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                Dokumente in anderen Sprachen
              </a>
            </div>
          </div>
        </Panel>
      </Col>

      <Col md={6} className="mb-24">
        <Panel
          headline="Aufklärungsmerkblatt Vektorimpfstoff"
        >
          <div className="d-flex align-items-baseline mb-16">
            <FontAwesomeIcon icon={faDownload} fixedWidth className="me-8" />
            <div>
              <a
                href="https://www.rki.de/DE/Content/Infekt/Impfen/Materialien/Downloads-COVID-19-Vektorimpfstoff/Aufklaerungsbogen-de.pdf?__blob=publicationFile"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                Aufklärungsmerkblatt zur Schutzimpfung gegen COVID-19 mit Vektor-Impfstoff
              </a>
              <div className="text-tertiary">PDF, 86 KB, Datei ist nicht barrierefrei</div>
            </div>
          </div>

          <div className="d-flex align-items-baseline mb-16">
            <FontAwesomeIcon icon={faDownload} fixedWidth className="me-8" />
            <div>
              <a
                href="https://www.rki.de/DE/Content/Infekt/Impfen/Materialien/Downloads-COVID-19-Vektorimpfstoff/Einwilligung-de.pdf?__blob=publicationFile"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                Anamnese- und Einwilligungsbogen zur Schutzimpfung gegen COVID-19
                mit Vektor-Impfstoff
              </a>
              <div className="text-tertiary">PDF, 927 KB, Datei ist nicht barrierefrei</div>
            </div>
          </div>

          <div className="d-flex align-items-baseline">
            <FontAwesomeIcon icon={faGlobe} fixedWidth className="me-8" />
            <div>
              <a
                href="https://www.rki.de/DE/Content/Infekt/Impfen/Materialien/COVID-19-Vektorimpfstoff-Tab.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                Dokumente in anderen Sprachen
              </a>
            </div>
          </div>
        </Panel>
      </Col>

      <Col md={6} className="mb-24">
        <Panel
          headline="Sonstiges"
        >
          <div className="d-flex align-items-baseline mb-16">
            <FontAwesomeIcon icon={faDownload} fixedWidth className="me-8" />
            <div>
              <a
                href="https://www.rki.de/DE/Content/Infekt/Impfen/Materialien/Faktenblaetter/COVID-19-Kinder-Jugendliche.pdf?__blob=publicationFile"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                Faktenblatt: COVID19-Impfung für Kinder und Jugendliche
              </a>
              <div className="text-tertiary">PDF, 137 KB, Datei ist nicht barrierefrei</div>
            </div>
          </div>

          <div className="d-flex align-items-baseline mb-16">
            <FontAwesomeIcon icon={faDownload} fixedWidth className="me-8" />
            <div>
              <a
                href="https://assets.zusammengegencorona.de/eaae45wp4t29/KdOdK0VcajYoVxGZRtz3K/9f273d286609ccf7af71cc35d3a19edc/211130_BMG_Ersatzbescheinigung_Impfung.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                Ersatzformular zur Dokumentation der durchgeführten Impfungen
              </a>
              <div className="text-tertiary">PDF, 989 KB, Datei ist nicht barrierefrei</div>
            </div>
          </div>

          <div className="d-flex align-items-baseline">
            <FontAwesomeIcon icon={faGlobe} fixedWidth className="me-8" />
            <div>
              <a
                href="https://www.rki.de/DE/Content/Infekt/Impfen/Materialien/COVID-19-Aufklaerungsbogen-Tab.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                Dokumente in anderen Sprachen
              </a>
            </div>
          </div>
        </Panel>
      </Col>
    </Row>
  </>
);

export default Help;
