import React, { useMemo } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Layout from "./components/Layout/Layout";
import Login from "./pages/Login/Login";
import Portal from "./pages/Portal/Portal";
import Start from "./pages/Start/Start";
import RequireAuth from "./utils/RequireAuth";
import ClientRegister from "./pages/ClientRegister/ClientRegister";
import Help from "./pages/Help/Help";
import Contact from "./pages/Contact/Contact";
import DataProtection from "./pages/DataProtection/DataProtection";
import Imprint from "./pages/Imprint/Imprint";
import Appointment from "./pages/Appointment/Appointment";
import { DefaultThemeContextValue, ThemeContext } from "./components/ThemeContext/ThemeContext";
import AppointmentDelete from "./pages/Appointment/AppointmentDelete";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ResetPassword from "./pages/Login/ResetPassword";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";

import "./App.scss";

const App = () => {
  const theme = window.top.location.hostname === "noventi.dubidoc.de"
    ? "noventi"
    : "dubivax";

  const themeContextValue = useMemo(() => ({
    ...DefaultThemeContextValue,
    theme,
  }), [theme]);

  document.title = theme === "dubivax"
    ? "dubivax - dubidoc"
    : "Noventi - dubidoc";

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Start />} />
            <Route exact path="appointments/:ulid" element={<Appointment />} />
            <Route exact path="appointment/delete/:ulid" element={<AppointmentDelete />} />
            <Route exact path="register/:ulid" element={<ClientRegister />} />
            <Route exact path="help" element={<Help />} />
            <Route exact path="contact" element={<Contact />} />
            <Route exact path="data-protection" element={<DataProtection />} />
            <Route exact path="imprint" element={<Imprint />} />
            <Route exact path="terms-and-conditions" element={<TermsAndConditions />} />

            <Route exact path="login" element={<Login />} />
            <Route exact path="forgot-password" element={<ForgotPassword />} />
            <Route exact path="reset-password/:ulid/:resetToken" element={<ResetPassword />} />

          </Route>
          <Route path="portal/*" element={<RequireAuth><Portal /></RequireAuth>} />
        </Routes>
      </BrowserRouter>
    </ThemeContext.Provider>
  );
};

export default App;
