import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blockingTime: null,
  loading: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
  wizardLoading: false,
  wizardSuccess: false,
  wizardFail: false,
  removeLoading: false,
  removeSuccess: false,
  removeFail: false,
};

const BlockingTimeSlice = createSlice({
  name: "blockingTime",
  initialState,
  reducers: {
    fetch: (state) => ({
      ...state,
      loading: true,
      blockingTime: null,
    }),
    fetch_SUCCESS: (state, { payload }) => ({
      ...state,
      loading: false,
      blockingTime: payload.data.blockingTime,
    }),
    fetch_FAIL: (state) => ({
      ...state,
      loading: false,
      blockingTime: null,
    }),
    edit: (state) => ({
      ...state,
      editLoading: true,
      editSuccess: false,
      editFail: false,
    }),
    edit_SUCCESS: (state) => ({
      ...state,
      editLoading: false,
      editSuccess: true,
      editFail: false,
    }),
    edit_FAIL: (state) => ({
      ...state,
      editLoading: false,
      editSuccess: false,
      editFail: true,
    }),
    wizard: (state) => ({
      ...state,
      wizardLoading: true,
      wizardSuccess: false,
      wizardFail: false,
    }),
    wizard_SUCCESS: (state) => ({
      ...state,
      wizardLoading: false,
      wizardSuccess: true,
      wizardFail: false,
    }),
    wizard_FAIL: (state) => ({
      ...state,
      wizardLoading: false,
      wizardSuccess: false,
      wizardFail: true,
    }),
    remove: (state) => ({
      ...state,
      removeLoading: false,
      removeSuccess: false,
      removeFail: false,
    }),
    remove_SUCCESS: (state) => ({
      ...state,
      removeLoading: false,
      removeSuccess: true,
      removeFail: false,
    }),
    remove_FAIL: (state) => ({
      ...state,
      removeLoading: false,
      removeSuccess: false,
      removeFail: true,
    }),
    reset: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const { reset } = BlockingTimeSlice.actions;

export default BlockingTimeSlice.reducer;

export const fetch = (ulid) => ({
  type: "blockingTime/fetch",
  payload: {
    client: "default",
    request: {
      url: `portal/blocking-time/details/${ulid}`,
    },
  },
});

export const wizard = (data) => ({
  type: "blockingTime/wizard",
  payload: {
    client: "default",
    request: {
      method: "post",
      data,
      url: "portal/blocking-time/wizard",
    },
  },
});

export const edit = (ulid, data, editSet = false) => ({
  type: "blockingTime/edit",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        ...data,
        editSet,
      },
      url: `portal/blocking-time/edit/${ulid}`,
    },
  },
});

export const remove = (ulid, removeSet = false) => ({
  type: "blockingTime/remove",
  payload: {
    client: "default",
    request: {
      method: "delete",
      data: {
        removeSet,
      },
      url: `portal/blocking-time/remove/${ulid}`,
    },
  },
});
