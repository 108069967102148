import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faPlus,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { Formik } from "formik";
import * as yup from "yup";
import { Alert } from "react-bootstrap";
import { useIntl } from "react-intl";
import Modal from "react-bootstrap/Modal";

import { edit, fetchOne, reset } from "../../../state/features/AppointmentTypeSlice";
import Panel from "../../../components/Panel/Panel";
import Hint from "../../../components/Hint/Hint";
import IconButton from "../../../components/IconButton/IconButton";
import Loading from "../../../components/Loading/Loading";
import FormRowText from "../../../components/FormRow/FormRowText";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { notificationService } from "../../../services/NotificationService";
import FormRowSelect from "../../../components/FormRow/FormRowSelect";
import ConfirmButton from "../../../components/ConfirmButton/ConfirmButton";
import FileInput from "../../../components/FileInput/FileInput";
import { add, remove, fetchAll } from "../../../state/features/EmailAttachementSlice";

const AppointmentTypeEdit = (props) => {
  const {
    fetchOneDispatch,
    editDispatch,
    resetDispatch,
    appointmentType,
    loading,
    editLoading,
    editSuccess,
    editFail,
  } = props;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    emailAttachements,
    loading: emailAttachementsLoading,
    addLoading,
    removeLoading,
  } = useSelector((state) => state.emailAttachement);

  const {
    ulid,
  } = useParams();

  const [showFileModal, setShowFileModal] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    resetDispatch();

    if (ulid) {
      fetchOneDispatch(ulid);
      dispatch(fetchAll(ulid));
    }
  }, [resetDispatch, fetchOneDispatch, ulid, dispatch]);

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    publicName: yup
      .string()
      .nullable()
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    color: yup
      .string()
      .nullable()
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    searchHint: yup
      .string()
      .nullable()
      .max(300, intl.formatMessage({ id: "form.error.max_length" }, { value: 300 })),
    emailHint: yup
      .string()
      .nullable()
      .max(300, intl.formatMessage({ id: "form.error.max_length" }, { value: 300 })),
    emailHint2: yup
      .string()
      .nullable()
      .max(1000, intl.formatMessage({ id: "form.error.max_length" }, { value: 1000 })),
    appointmentCount: yup
      .number(intl.formatMessage({ id: "form.error.numeric" }))
      .nullable(),
    duration: yup
      .number(intl.formatMessage({ id: "form.error.numeric" }))
      .required(intl.formatMessage({ id: "form.error.required" })),
  });

  const fileValidationSchema = yup.object().shape({
    description: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(100, intl.formatMessage({ id: "form.error.max_length" }, { value: 100 })),
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/appointment-type", name: "client_navigation.appointment_types" },
          { to: "/portal/appointment-type/edit", name: "client_navigation.appointment_type_edit" },
        ]}
      />

      <h3 className="mb-16">{appointmentType ? "Terminart bearbeiten" : "Neue Terminart"}</h3>

      <Formik
        initialValues={{
          name: (ulid && appointmentType?.name) || "",
          publicName: (ulid && appointmentType?.publicName) || "",
          color: (ulid && appointmentType?.color) || `#${Math.floor(Math.random() * 16777215).toString(16)}`,
          active: (ulid && appointmentType?.active) || true,
          searchHint: (ulid && appointmentType?.searchHint) || "",
          emailHint: (ulid && appointmentType?.emailHint) || "",
          emailHint2: (ulid && appointmentType?.emailHint2) || "",
          appointmentCount: (ulid && appointmentType?.appointmentCount) || "",
          duration: (ulid && appointmentType?.duration) || 5,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const tmpValues = {
            ...values,
            appointmentCount: values.appointmentCount === "" ? null : values.appointmentCount,
          };

          editDispatch(ulid, tmpValues)
            .then((action) => {
              if (action.type.endsWith("SUCCESS")) {
                notificationService.success("Terminart erfolgreich gespeichert.");
                navigate("/portal/appointment-type", { replace: true });
              }
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Termine werden im Kalender farblich markiert. So können Sie
                schnell erkennen, zu welcher Terminart der Termin gehört.
              </Col>
              <Col md={9}>
                <Panel headline="Allgemeines">
                  {appointmentType && appointmentType.managed && (
                    <Form.Group as={Row} className="mb-24" controlId="name">
                      <Form.Label column md={4}>
                        Bezeichnung
                      </Form.Label>
                      <Col md={7}>
                        <Form.Control
                          type="text"
                          disabled
                          defaultValue={intl.formatMessage({ id: appointmentType.name })}
                        />
                      </Col>
                      <Col md={1} className="d-flex align-items-center">
                        <Hint text="Der Name der Terminart ist nicht änderbar." />
                      </Col>
                    </Form.Group>
                  )}

                  {(!appointmentType || !appointmentType.managed) && (
                    <FormRowText
                      id="name"
                      name="name"
                      label="Bezeichnung"
                      defaultValue={values.name}
                      errors={errors.name}
                      touched={touched.name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      className="mb-24"
                    />
                  )}

                  <FormRowText
                    id="color"
                    name="color"
                    label="Farbe"
                    defaultValue={values.color}
                    errors={errors.color}
                    touched={touched.color}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    type="color"
                    className="mb-24"
                  />

                  <FormRowText
                    id="appointmentCount"
                    name="appointmentCount"
                    label="Max. Termine pro Woche"
                    defaultValue={values.appointmentCount}
                    errors={errors.appointmentCount}
                    touched={touched.appointmentCount}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    hint="Sie können auf Wunsch, die Anzahl der max. Online-Terminbuchungen pro Woche für diese Terminart festlegen. Kein Eintrag = unbegrenzte Terminbuchungen"
                    className="mb-24"
                  />

                  <FormRowSelect
                    id="duration"
                    name="duration"
                    label="Termindauer (Minuten)"
                    defaultValue={values.duration}
                    options={[
                      { label: 5, value: 5 },
                      { label: 10, value: 10 },
                      { label: 15, value: 15 },
                      { label: 20, value: 20 },
                      { label: 25, value: 25 },
                      { label: 30, value: 30 },
                      { label: 35, value: 35 },
                      { label: 40, value: 40 },
                      { label: 45, value: 45 },
                      { label: 50, value: 50 },
                      { label: 55, value: 55 },
                      { label: 60, value: 60 },
                    ]}
                    errors={errors.duration}
                    touched={touched.duration}
                    handleBlur={handleBlur}
                    handleChange={(event) => {
                      setFieldValue("duration", parseInt(event.target.value, 10));
                    }}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Hier können Sie die Bezeichnung der Terminart in der Online-Suche
                anpassen sowie spezifische Hinweise geben, die während der der
                Online-Terminbuchung angezeigt werden sollen.
              </Col>
              <Col md={9}>
                <Panel headline="Online-Terminierung">
                  <FormRowText
                    id="publicName"
                    name="publicName"
                    label="Bezeichnung in Online-Suche"
                    defaultValue={values.publicName}
                    errors={errors.publicName}
                    touched={touched.publicName}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    hint="Geben Sie hier eine Bezeichnung für die Terminart ein, wie Sie Ihren Kunden/Patienten bei der Terminbuchung angezeigt werden soll."
                    maxLength={180}
                    className="mb-24"
                  />

                  <Form.Group as={Row} controlId="searchHint">
                    <Form.Label column md={4}>
                      Hinweis Online-Suche
                    </Form.Label>
                    <Col md={7}>
                      <Form.Control
                        as="textarea"
                        name="searchHint"
                        style={{ height: "100px" }}
                        placeholder="Max. 300 Zeichen"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.searchHint}
                      />

                      {errors.searchHint && touched.searchHint && errors.searchHint}
                    </Col>
                  </Form.Group>
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Wenn Sie in der Bestätigungs-E-Mail einen Hinweis geben möchten,
                tragen Sie diesen in das Eingabefeld ein. Die E-Mail wird nach
                jeder Terminbuchung automatisch verschickt.<br />
                Wenn Sie in der Bestätigungs-E-Mail einen weiteren Hinweis geben möchten,
                tragen Sie diesen in das zweite Eingabefeld ein. Dieser Hinweis wird unterhalb
                der Dokumente ausgegeben.
              </Col>
              <Col md={9}>
                <Panel headline="Termin-E-Mails">
                  <Form.Group as={Row} controlId="emailHint" className="mb-24">
                    <Form.Label column md={4}>
                      Hinweis in E-Mail
                    </Form.Label>
                    <Col md={7}>
                      <Form.Control
                        as="textarea"
                        name="emailHint"
                        style={{ height: "100px" }}
                        placeholder="Max. 300 Zeichen"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.emailHint}
                      />

                      {errors.emailHint && touched.emailHint && errors.emailHint}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="emailHint2" className="mb-24">
                    <Form.Label column md={4}>
                      Weitere Hinweise in E-Mail
                    </Form.Label>
                    <Col md={7}>
                      <Form.Control
                        as="textarea"
                        name="emailHint2"
                        style={{ height: "100px" }}
                        placeholder="Max. 1000 Zeichen"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.emailHint2}
                      />

                      {errors.emailHint2 && touched.emailHint2 && errors.emailHint2}
                    </Col>
                  </Form.Group>

                  {(appointmentType?.managed === false || !ulid) && (
                    <Form.Group as={Row} controlId="emailAttachements">
                      <Form.Label column md={4}>
                        E-Mail Anhänge
                      </Form.Label>
                      <Col md={7}>
                        {ulid ? (
                          <>
                            {(emailAttachementsLoading || removeLoading) && (
                              <Loading withoutPanel />
                            )}

                            {emailAttachements?.length > 0 && !removeLoading && (
                              <ul className="list-group mb-24">
                                {emailAttachements.map((emailAttachement) => (
                                  <li
                                    className="list-group-item d-flex justify-content-between align-items-center text-break"
                                    key={emailAttachement.ulid}
                                  >
                                    {emailAttachement.description}
                                    <span className="text-nowrap ms-8">
                                      <IconButton
                                        size="sm"
                                        href={emailAttachement.file}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="me-4"
                                      >
                                        <FontAwesomeIcon icon={faSearch} />
                                      </IconButton>
                                      <ConfirmButton
                                        onClick={() => {
                                          dispatch(remove(emailAttachement.ulid))
                                            .then(() => {
                                              dispatch(fetchAll(ulid));
                                            });
                                        }}
                                      >
                                        <IconButton size="sm">
                                          <FontAwesomeIcon icon={faTrash} />
                                        </IconButton>
                                      </ConfirmButton>
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            )}

                            <div className="text-center">
                              <IconButton size="sm" onClick={() => setShowFileModal(true)}>
                                <FontAwesomeIcon icon={faPlus} /> Dokument hinzufügen
                              </IconButton>
                            </div>
                          </>
                        ) : (
                          <div className="alert alert-warning" role="alert">
                            Bitte speichern Sie den Datensatz ab,
                            um Dateianhänge hochladen zu können.
                          </div>
                        )}
                      </Col>
                    </Form.Group>
                  )}
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Wenn Sie eine Terminart nicht anbieten möchten, können Sie diese
                hier deaktivieren.
              </Col>
              <Col md={9}>
                <Panel headline="Status der Terminart">
                  <Form.Group as={Row} controlId="active">
                    <Form.Label column md={4}>
                      Terminart aktivieren
                    </Form.Label>
                    <Col md={7}>
                      <div className="text-end">
                        <Form.Check
                          type="switch"
                          name="active"
                          defaultChecked={values.active}
                          onChange={(event) => {
                            setFieldValue("active", event.target.checked);
                          }}
                        />
                      </div>

                      {errors.active && touched.active && errors.active}
                    </Col>
                  </Form.Group>
                  <Row>
                    <Col md={{ span: 7, offset: 4 }}>
                      {!values.active && (
                        <Alert variant="danger" className="mt-24 mb-0">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                          Terminart kann derzeit nicht gebucht werden.
                        </Alert>
                      )}
                    </Col>
                  </Row>
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={{ span: 9, offset: 3 }}>
                <Panel small className="text-end">
                  <IconButton
                    disabled={editLoading}
                    type="submit"
                  >
                    Speichern
                    {editLoading && (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                    {editSuccess && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    {editFail && (
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    )}
                  </IconButton>
                </Panel>
              </Col>
            </Row>

          </form>
        )}
      </Formik>

      <Modal
        show={showFileModal}
        onHide={() => setShowFileModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Anhang hinzufügen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              description: "",
              file: null,
            }}
            validationSchema={fileValidationSchema}
            validate={(values) => {
              const errors = {};

              if (values.file === null) {
                errors.file = intl.formatMessage({ id: "form.error.required" });
              } else if (values.file.size > 5242880) {
                errors.file = intl.formatMessage({ id: "form.error.file_size" });
              } else if (!["application/pdf"].includes(values.file.type)) {
                errors.file = intl.formatMessage({ id: "form.error.file_type" });
              }

              return errors;
            }}
            onSubmit={(values) => {
              dispatch(add(ulid, values))
                .then((action) => {
                  if (action.type.endsWith("SUCCESS")) {
                    notificationService.success("Anhang erfolgreich gespeichert.");
                    dispatch(fetchAll(ulid));
                    setShowFileModal(false);
                  }
                })
                .catch((error) => {
                  notificationService.error(`Anhang konnte nicht gespeichert werden. (${error})`);
                });
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Form.Group as={Row} controlId="description" className="mb-24">
                  <Form.Label column md={4}>
                    Beschreibung/Text
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      name="description"
                      placeholder="Max. 100 Zeichen"
                      isInvalid={!!(errors.description && touched.description)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.description && touched.description && (
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-24">
                  <Form.Label column md={4}>
                    Anhang auswählen (max. 5 MB)
                  </Form.Label>
                  <Col md={8}>
                    <FileInput
                      id="file"
                      mimeType="application/pdf"
                      isInvalid={!!(errors.file && touched.file)}
                      onChange={(file) => {
                        setFieldValue("file", file);
                      }}
                    >
                      Anhang hier ablegen oder <u>Computer durchsuchen</u>
                    </FileInput>

                    {errors.file && touched.file && (
                      <Form.Control.Feedback type="invalid">
                        {errors.file}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Form.Group>

                <div className="text-end">
                  <IconButton
                    disabled={editLoading}
                    type="submit"
                  >
                    Hinzufügen
                    {addLoading && (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                  </IconButton>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

AppointmentTypeEdit.propTypes = {
  fetchOneDispatch: PropTypes.func.isRequired,
  editDispatch: PropTypes.func.isRequired,
  resetDispatch: PropTypes.func.isRequired,
  appointmentType: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  loading: PropTypes.bool,
  editLoading: PropTypes.bool,
  editSuccess: PropTypes.bool,
  editFail: PropTypes.bool,
};

AppointmentTypeEdit.defaultProps = {
  appointmentType: null,
  loading: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
};

const mapStateToProps = ({ appointmentType }) => ({
  appointmentType: appointmentType.appointmentType,
  loading: appointmentType.loading,
  editLoading: appointmentType.editLoading,
  editSuccess: appointmentType.editSuccess,
  editFail: appointmentType.editFail,
});

const mapDispatch = {
  fetchOneDispatch: fetchOne,
  editDispatch: edit,
  resetDispatch: reset,
};

export default connect(mapStateToProps, mapDispatch)(AppointmentTypeEdit);
