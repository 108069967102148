import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dateTime: null,
  appointmentType: null,
  reservedAppointmentUlid: null,
  reservationExpireTime: null,

  reserveLoading: false,
  mobilePhone: null,

  appointmentReserved: false,
  appointmentBooked: false,
  appointmentConfirmed: false,
  appointmentExpired: false,

  bookAppointmentLoading: false,
  bookAppointmentSuccess: false,
  bookAppointmentFail: false,

  confirmAppointmentLoading: false,
  confirmAppointmentSuccess: false,
  confirmAppointmentFail: false,

  resendSmsLoading: false,
  resendSmsSuccess: false,
  resendSmsFail: false,

  changeMobilePhoneLoading: false,
  changeMobilePhoneSuccess: false,
  changeMobilePhoneFail: false,
};

const AppointmentBookingSlice = createSlice({
  name: "appointmentBooking",
  initialState,
  reducers: {
    reserveAppointment: (state) => ({
      ...state,
      reservedAppointmentUlid: null,
      reserveLoading: true,
      appointmentReserved: false,
    }),
    reserveAppointment_SUCCESS: (state, { payload }) => ({
      ...state,
      reservedAppointmentUlid: payload.data.appointmentUlid,
      reserveLoading: false,
      appointmentReserved: true,
    }),
    reserveAppointment_FAIL: (state) => ({
      ...state,
      reservedAppointmentUlid: null,
      reserveLoading: false,
      appointmentReserved: false,
    }),
    unreserveAppointment: (state, { expired }) => {
      if (expired) {
        return {
          ...state,
          appointmentExpired: true,
        };
      }

      return {
        ...state,
        ...initialState,
      };
    },
    bookAppointment: (state) => ({
      ...state,
      appointmentBooked: false,
      bookAppointmentLoading: true,
      bookAppointmentSuccess: false,
      bookAppointmentFail: false,
    }),
    bookAppointment_SUCCESS: (state, { payload }) => ({
      ...state,
      appointmentBooked: true,
      bookAppointmentLoading: false,
      bookAppointmentSuccess: true,
      bookAppointmentFail: false,

      // early confirm appointment if backend says so :)
      appointmentConfirmed: payload.data.confirmed,
    }),
    bookAppointment_FAIL: (state) => ({
      ...state,
      appointmentBooked: false,
      bookAppointmentLoading: false,
      bookAppointmentSuccess: false,
      bookAppointmentFail: true,
    }),
    confirmAppointment: (state) => ({
      ...state,
      appointmentConfirmed: false,
      confirmAppointmentLoading: true,
      confirmAppointmentSuccess: false,
      confirmAppointmentFail: false,
    }),
    confirmAppointment_SUCCESS: (state) => ({
      ...state,
      appointmentConfirmed: true,
      confirmAppointmentLoading: false,
      confirmAppointmentSuccess: true,
      confirmAppointmentFail: false,
    }),
    confirmAppointment_FAIL: (state) => ({
      ...state,
      appointmentConfirmed: false,
      confirmAppointmentLoading: false,
      confirmAppointmentSuccess: false,
      confirmAppointmentFail: true,
    }),
    setBookingData: (state, { payload }) => ({
      ...state,
      dateTime: payload.dateTime,
      appointmentType: payload.appointmentType,
      reservationExpireTime: payload.reservationExpireTime,
      mobilePhone: payload?.mobilePhone || null,
    }),
    resendSms: (state) => ({
      ...state,
      resendSmsLoading: true,
      resendSmsSuccess: false,
      resendSmsFail: false,
    }),
    resendSms_SUCCESS: (state) => ({
      ...state,
      resendSmsLoading: false,
      resendSmsSuccess: true,
      resendSmsFail: false,
    }),
    resendSms_FAIL: (state) => ({
      ...state,
      resendSmsLoading: false,
      resendSmsSuccess: false,
      resendSmsFail: true,
    }),
    changeMobilePhone: (state) => ({
      ...state,
      changeMobilePhoneLoading: true,
      changeMobilePhoneSuccess: false,
      changeMobilePhoneFail: false,
    }),
    changeMobilePhone_SUCCESS: (state) => ({
      ...state,
      changeMobilePhoneLoading: false,
      changeMobilePhoneSuccess: true,
      changeMobilePhoneFail: false,
    }),
    changeMobilePhone_FAIL: (state) => ({
      ...state,
      changeMobilePhoneLoading: false,
      changeMobilePhoneSuccess: false,
      changeMobilePhoneFail: true,
    }),
    resetBookingData: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const { setBookingData, resetBookingData } = AppointmentBookingSlice.actions;

export default AppointmentBookingSlice.reducer;

export const reserveAppointment = (appointmentTypeUlid, dateTime) => ({
  type: "appointmentBooking/reserveAppointment",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        appointmentTypeUlid,
        dateTime,
      },
      url: "/appointment/reserve",
    },
  },
});

export const unreserveAppointment = (appointmentUlid) => ({
  type: "appointmentBooking/unreserveAppointment",
  payload: {
    client: "default",
    request: {
      method: "post",
      url: `/appointment/unreserve/${appointmentUlid}`,
    },
  },
});

export const bookAppointment = (appointmentUlid, data) => ({
  type: "appointmentBooking/bookAppointment",
  payload: {
    client: "default",
    request: {
      method: "post",
      data,
      url: `/appointment/book/${appointmentUlid}`,
    },
  },
});

export const confirmAppointment = (appointmentUlid, data) => ({
  type: "appointmentBooking/confirmAppointment",
  payload: {
    client: "default",
    request: {
      method: "post",
      data,
      url: `/appointment/confirm/${appointmentUlid}`,
    },
  },
});

export const resendSms = (appointmentUlid) => ({
  type: "appointmentBooking/resendSms",
  payload: {
    client: "default",
    request: {
      method: "put",
      url: `/appointment/resendSms/${appointmentUlid}`,
    },
  },
});

export const changeMobilePhone = (appointmentUlid, data) => ({
  type: "appointmentBooking/changeMobilePhone",
  payload: {
    client: "default",
    request: {
      method: "put",
      data,
      url: `/appointment/changeMobilePhone/${appointmentUlid}`,
    },
  },
});
