import { createStore, applyMiddleware, combineReducers } from "redux";
import axiosMiddleware from "redux-axios-middleware";
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import axiosInstance from "../utils/AxiosHelper";
import AuthenticationSlice, { logout } from "./features/AuthenticationSlice";
import AppointmentTypeSlice from "./features/AppointmentTypeSlice";
import AppointmentTypeBudgetingSlice from "./features/AppointmentTypeBudgetingSlice";
import ClientBaseDataSlice from "./features/ClientBaseDataSlice";
import WorkingTimeSlice from "./features/WorkingTimeSlice";
import ProfileSlice from "./features/ProfileSlice";
import ClientSlice from "./features/ClientSlice";
import BlockingTimeSlice from "./features/BlockingTimeSlice";
import AppointmentSearchSlice from "./features/AppointmentSearchSlice";
import UserSlice from "./features/UserSlice";
import AppointmentSlice from "./features/AppointmentSlice";
import AppointmentBookingSlice from "./features/AppointmentBookingSlice";
import AppointmentTypeStatisticSlice from "./features/AppointmentTypeStatisticSlice";
import AppointmentStatisticSlice from "./features/AppointmentStatisticSlice";
import DashboardSlice from "./features/DashboardSlice";
import AppointmentDeleteSlice from "./features/AppointmentDeleteSlice";
import CalendarSlice from "./features/CalendarSlice";
import ForgotPasswordSlice from "./features/ForgotPasswordSlice";
import EmailAttachementSlice from "./features/EmailAttachementSlice";

const composeEnhancers = composeWithDevTools({ name: "dubvax" });

const keyPrefix = "dubivax";

const axiosMiddlewareConfig = {
  interceptors: {
    response: [{
      success({ getState, dispatch, getSourceAction }, res) {
        return Promise.resolve(res);
      },
      error({ getState, dispatch, getSourceAction }, error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }

        return Promise.reject(error);
      },
    }],
  },
};

function ConfigureStore(initialState = {}) {
  const store = createStore(
    combineReducers({
      authentication: persistReducer({
        key: `${keyPrefix}authentication`,
        debug: true,
        storage,
      }, AuthenticationSlice),
      appointmentType: AppointmentTypeSlice,
      appointmentTypeBudgeting: AppointmentTypeBudgetingSlice,
      emailAttachement: EmailAttachementSlice,
      clientBaseData: ClientBaseDataSlice,
      workingTime: WorkingTimeSlice,
      profile: ProfileSlice,
      blockingTime: BlockingTimeSlice,
      client: ClientSlice,
      appointmentSearch: AppointmentSearchSlice,
      user: UserSlice,
      appointment: AppointmentSlice,
      appointmentTypeStatistic: AppointmentTypeStatisticSlice,
      appointmentStatistic: AppointmentStatisticSlice,
      dashboard: DashboardSlice,
      calendar: CalendarSlice,
      appointmentDelete: AppointmentDeleteSlice,
      forgotPassword: ForgotPasswordSlice,
      appointmentBooking: persistReducer({
        key: `${keyPrefix}appointmentBooking`,
        debug: true,
        storage,
      }, AppointmentBookingSlice),
    }),
    initialState,
    composeEnhancers(applyMiddleware(axiosMiddleware(
      axiosInstance(),
      axiosMiddlewareConfig,
    ))),
  );

  const persistor = persistStore(store);

  return { store, persistor };
}

export default ConfigureStore;
