import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  faPen,
  faSpinner,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

import ConfirmButton from "../../../components/ConfirmButton/ConfirmButton";
import IconButton from "../../../components/IconButton/IconButton";
import { fetchAll, remove } from "../../../state/features/UserSlice";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

const Users = (props) => {
  const {
    fetchAllDispatch,
    removeDispatch,
    users,
    usersLoading,
  } = props;

  useEffect(() => {
    fetchAllDispatch();
  }, [fetchAllDispatch]);

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/user", name: "client_navigation.user" },
        ]}
      />

      <div className="d-flex align-items-center justify-content-between mb-16">
        <h3>Benutzerverwaltung</h3>
        <IconButton
          size="sm"
          variant="secondary"
          as={NavLink}
          to="/portal/user/new"
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth />
          Neuer Benutzer
        </IconButton>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Benutzername</th>
            <th scope="col">Vorname</th>
            <th scope="col">Nachname</th>
            <td />
          </tr>
        </thead>
        <tbody>
          {usersLoading && (
            <tr>
              <td colSpan={4} className="text-center py-24">
                <FontAwesomeIcon icon={faSpinner} spin />
              </td>
            </tr>
          )}
          {users.map((user) => (
            <tr key={user.ulid}>
              <td>
                {user.username}
              </td>
              <td>
                {user.firstname}
              </td>
              <td>
                {user.lastname}
              </td>
              <td className="text-end">
                <IconButton
                  size="sm"
                  variant="secondary"
                  as={NavLink}
                  to={`/portal/user/edit/${user.ulid}`}
                  className="me-8"
                >
                  <FontAwesomeIcon icon={faPen} fixedWidth />
                </IconButton>
                <ConfirmButton
                  onClick={() => {
                    removeDispatch(user.ulid)
                      .then((action) => {
                        if (action.type.endsWith("SUCCESS")) {
                          fetchAllDispatch();
                        }
                      });
                  }}
                >
                  <IconButton size="sm" variant="danger">
                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                  </IconButton>
                </ConfirmButton>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4} />
          </tr>
        </tfoot>
      </table>
    </>
  );
};

Users.propTypes = {
  fetchAllDispatch: PropTypes.func.isRequired,
  removeDispatch: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([PropTypes.array]),
  usersLoading: PropTypes.bool,
};

Users.defaultProps = {
  users: [],
  usersLoading: false,
};

const mapStateToProps = ({ user }) => ({
  users: user.users,
  usersLoading: user.usersLoading,
});

const mapDispatch = {
  fetchAllDispatch: fetchAll,
  removeDispatch: remove,
};

export default connect(mapStateToProps, mapDispatch)(Users);
