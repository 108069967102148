import React from "react";

import Container from "react-bootstrap/Container";
import { Outlet } from "react-router-dom";

import PortalNavigation from "../PortalNavigation/PortalNavigation";
import Notification from "../Notification/Notification";

import "./PortalLayout.scss";

const PortalLayout = () => (
  <div className="client-layout">
    <PortalNavigation />

    <Container fluid="xxl">
      <Outlet />
    </Container>

    <Notification />
  </div>
);

export default PortalLayout;
