import React, { memo } from "react";

import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import FormRow from "./FormRow";

const FormRowText = (props) => {
  const {
    id,
    label,
    defaultValue,
    handleChange,
    handleBlur,
    errors,
    touched,
    hint,
    type,
    className,
    size,
    labelSize,
    ...otherProps
  } = props;

  return (
    <FormRow
      id={id}
      label={label}
      errors={errors}
      touched={touched}
      hint={hint}
      size={size}
      labelSize={labelSize}
      className={className}
    >
      <Form.Control
        type={type}
        name={id}
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={defaultValue}
        isInvalid={!!(errors && touched)}
        {...otherProps}
      />
    </FormRow>
  );
};

FormRowText.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
  errors: PropTypes.string,
  touched: PropTypes.bool,
  hint: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  labelSize: PropTypes.number,
  size: PropTypes.number,
};

FormRowText.defaultProps = {
  errors: null,
  touched: false,
  hint: null,
  type: "text",
  className: "",
  labelSize: 4,
  size: 7,
  defaultValue: null,
  handleChange: () => {},
  handleBlur: () => {},
};

export default memo(FormRowText);
