import React, { useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik } from "formik";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import Datetime from "react-datetime";
import moment from "moment";

import Panel from "../../components/Panel/Panel";
import IconButton from "../../components/IconButton/IconButton";
import { COUNTRIES, GENDERS } from "../../constants";
import AppointmentBookingCard from "../../components/AppointmentBookingCard/AppointmentBookingCard";
import { bookAppointment, setBookingData, unreserveAppointment } from "../../state/features/AppointmentBookingSlice";
import MobilePhoneInput from "../../components/MobilePhoneInput/MobilePhoneInput";

const AppointmentBooking = (props) => {
  const {
    client,
    bookAppointmentDispatch,
    unreserveAppointmentDispatch,
    setBookingDataDispatch,
    dateTime,
    appointmentType,
    reservedAppointmentUlid,
    reservationExpireTime,
    bookAppointmentLoading,
    bookAppointmentSuccess,
    bookAppointmentFail,
  } = props;

  const intl = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validationSchema = yup.object().shape({
    gender: yup
      .number()
      .typeError(intl.formatMessage({ id: "form.error.required" }))
      .required(intl.formatMessage({ id: "form.error.required" })),
    birthdate: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    street: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    houseNumber: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(12, intl.formatMessage({ id: "form.error.max_length" }, { value: 12 })),
    zip: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(5, intl.formatMessage({ id: "form.error.max_length" }, { value: 5 })),
    city: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    country: yup
      .string()
      .typeError(intl.formatMessage({ id: "form.error.required" }))
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    firstname: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    lastname: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(180, intl.formatMessage({ id: "form.error.max_length" }, { value: 180 })),
    mobilePhone: yup
      .string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(20, intl.formatMessage({ id: "form.error.max_length" }, { value: 20 })),
    email: yup
      .string()
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 }))
      .email(intl.formatMessage({ id: "form.error.email" })),
    emailRepeat: yup.string()
      .oneOf([yup.ref("email"), null], intl.formatMessage({ id: "form.error.email_repeat" }, { value: 255 })),
    dataProtection: yup
      .bool()
      .oneOf([true], intl.formatMessage({ id: "form.error.required" })),
    dataProcessing: yup
      .bool()
      .oneOf([true], intl.formatMessage({ id: "form.error.required" })),
  });

  return (
    <Formik
      initialValues={{
        gender: null,
        firstname: "",
        lastname: "",
        birthdate: "",
        street: "",
        houseNumber: "",
        zip: "",
        city: "",
        country: null,
        email: "",
        emailRepeat: "",
        mobilePhone: "",
        dataProtection: false,
        dataProcessing: false,
      }}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors = {};

        const {
          birthdate,
        } = values;

        if (moment().isBefore(birthdate)) {
          errors.birthdate = intl.formatMessage({ id: "form.error.birthdate" });
        }

        return errors;
      }}
      onSubmit={(values) => {
        const tmpValues = { ...values };
        delete tmpValues.dataProtection;
        delete tmpValues.dataProcessing;
        delete tmpValues.emailRepeat;

        setBookingDataDispatch({
          dateTime,
          appointmentType,
          reservationExpireTime,
          mobilePhone: tmpValues.mobilePhone,
        });
        bookAppointmentDispatch(reservedAppointmentUlid, tmpValues)
          .then(() => {
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={{ span: 6, offset: 3 }} className="pt-40">
              <h1 className="mb-24">
                <FormattedMessage id="appointment_booking.headline" />
              </h1>

              <AppointmentBookingCard
                clientName={client.name}
                appointmentDateTime={moment(dateTime)}
                reservationExpireTime={reservationExpireTime}
                onChange={() => {
                  unreserveAppointmentDispatch(reservedAppointmentUlid);
                }}
                onExpired={() => {
                  unreserveAppointmentDispatch(reservedAppointmentUlid);
                }}
                appointmentType={appointmentType}
                className="mb-32"
              />

              <h3 className="mb-16">Geschlecht</h3>
              <Form.Group className="mb-32">
                <Form.Select
                  name="gender"
                  placeholder="Geschlecht*"
                  onChange={(event) => {
                    setFieldValue("gender", parseInt(event.target.value, 10));
                  }}
                  onBlur={handleBlur}
                  value={values.gender}
                >
                  <option selected disabled>Bitte auswählen*</option>
                  {GENDERS.map(({ value, label }) => (
                    <option
                      key={value}
                      value={value}
                      aria-label="Save"
                    >
                      {intl.formatMessage({ id: label })}
                    </option>
                  ))}
                </Form.Select>

                {errors.gender && touched.gender && (
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <h3 className="mb-16">Name</h3>
              <Row className="mb-32">
                <Form.Group as={Col} md="6" className="mb-24 mb-md-0">
                  <Form.Control
                    name="firstname"
                    type="text"
                    placeholder="Vorname*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstname}
                  />
                  {errors.firstname && touched.firstname && (
                    <Form.Control.Feedback type="invalid">
                      {errors.firstname}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Control
                    name="lastname"
                    type="text"
                    placeholder="Nachname*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastname}
                  />
                  {errors.lastname && touched.lastname && (
                    <Form.Control.Feedback type="invalid">
                      {errors.lastname}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <h3 className="mb-16">Geburtsdatum</h3>
              <Row className="mb-32">
                <Form.Group as={Col} md="6">
                  <Datetime
                    timeFormat={false}
                    initialViewDate={new Date("1990-01-01")}
                    initialValue={values.birthdate}
                    initialViewMode="years"
                    closeOnSelect
                    onChange={(value) => {
                      setFieldValue("birthdate", typeof value === "object"
                        ? value.format("YYYY-MM-DD")
                        : "");
                    }}
                    inputProps={{
                      onBlur: handleBlur,
                      name: "birthdate",
                      placeholder: "Ihr Geburtsdatum*",
                      autoComplete: "off",
                      className: errors.birthdate && touched.birthdate
                        ? "form-control is-invalid"
                        : "form-control",
                    }}
                    isValidDate={(current) => (moment().isSameOrAfter(current))}
                  />

                  {errors.birthdate && touched.birthdate && (
                    <Form.Control.Feedback type="invalid">
                      {errors.birthdate}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <h3 className="mb-16">Adresse</h3>
              <Row className="mb-32">
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="street"
                    type="text"
                    placeholder="Straße*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.street}
                  />
                  {errors.street && touched.street && (
                    <Form.Control.Feedback type="invalid">
                      {errors.street}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="houseNumber"
                    type="text"
                    placeholder="Hausnummer*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.houseNumber}
                  />
                  {errors.houseNumber && touched.houseNumber && (
                    <Form.Control.Feedback type="invalid">
                      {errors.houseNumber}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="zip"
                    type="text"
                    placeholder="PLZ*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zip}
                  />
                  {errors.zip && touched.zip && (
                    <Form.Control.Feedback type="invalid">
                      {errors.zip}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="city"
                    type="text"
                    placeholder="Ort*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                  {errors.city && touched.city && (
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Select
                    name="country"
                    placeholder="Land*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                  >
                    <option selected disabled>Bitte auswählen*</option>
                    {COUNTRIES.map(({ value, label }) => (
                      <option
                        key={value}
                        value={value}
                        aria-label="Save"
                      >
                        {intl.formatMessage({ id: label })}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.country && touched.country && (
                    <Form.Control.Feedback type="invalid">
                      {errors.country}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <h3 className="mb-16">Sicherheit & Kontakt</h3>
              <Panel small className="mb-24">
                <strong>Hinweis:</strong>
                <p>
                  Zu Ihrer Sicherheit senden wir Ihnen einen persönlichen
                  SMS-Verifizierungscode. Ihre E-Mail-Adresse wird lediglich für
                  Termin-Mitteilungen genutzt.
                </p>

                Ihre Daten werden nicht zu Werbezwecken verwendet.
              </Panel>
              <Row className="mb-32">
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="E-Mail-Adresse*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-24">
                  <Form.Control
                    name="emailRepeat"
                    type="emailRepeat"
                    placeholder="E-Mail Adresse wiederholen*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailRepeat}
                  />
                  {errors.emailRepeat && touched.emailRepeat && (
                    <Form.Control.Feedback type="invalid">
                      {errors.emailRepeat}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} xs={12}>
                  <MobilePhoneInput
                    name="mobilePhone"
                    placeholder="Ihre Handynummer*"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    defaultValue={values.mobilePhone}
                  />
                  {errors.mobilePhone && touched.mobilePhone && (
                    <Form.Control.Feedback type="invalid">
                      {errors.mobilePhone}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <Form.Group className="mb-16">
                <Form.Check
                  type="checkbox"
                  id="dataProtection"
                  onChange={(event) => {
                    setFieldValue("dataProtection", event.target.checked);
                  }}
                  onBlur={handleBlur}
                  label={(
                    <>
                      Ich akzeptiere die <a href="https://www.dubidoc.de/content/agb" target="_blank" rel="noopener noreferrer">AGB</a>
                      &nbsp;und <a href="https://www.dubidoc.de/content/datenschutz" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>*
                    </>
                  )}
                />

                {errors.dataProtection && touched.dataProtection && (
                  <Form.Control.Feedback type="invalid">
                    {errors.dataProtection}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-24">
                <Form.Check
                  type="checkbox"
                  id="dataProcessing"
                  onChange={(event) => {
                    setFieldValue("dataProcessing", event.target.checked);
                  }}
                  onBlur={handleBlur}
                  label="Ich willige ausdrücklich in die Verarbeitung der Termindaten (ink. Ort und Zeit) ein.*"
                />

                {errors.dataProcessing && touched.dataProcessing && (
                  <Form.Control.Feedback type="invalid">
                    {errors.dataProcessing}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <div className="d-flex justify-content-between align-items-center">
                <IconButton
                  type="submit"
                  disabled={bookAppointmentLoading}
                >
                  Weiter
                  {bookAppointmentLoading && (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  )}
                  {bookAppointmentSuccess && (
                    <FontAwesomeIcon icon={faCheck} />
                  )}
                  {bookAppointmentFail && (
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  )}
                </IconButton>

                <span>* Pflichtfelder</span>
              </div>

            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};

AppointmentBooking.propTypes = {
  client: PropTypes.oneOfType([PropTypes.object]),
  bookAppointmentDispatch: PropTypes.func.isRequired,
  unreserveAppointmentDispatch: PropTypes.func.isRequired,
  setBookingDataDispatch: PropTypes.func.isRequired,
  dateTime: PropTypes.string.isRequired,
  appointmentType: PropTypes.oneOfType([PropTypes.array]).isRequired,
  reservedAppointmentUlid: PropTypes.string.isRequired,
  reservationExpireTime: PropTypes.string.isRequired,
  bookAppointmentLoading: PropTypes.bool,
  bookAppointmentSuccess: PropTypes.bool,
  bookAppointmentFail: PropTypes.bool,
};

AppointmentBooking.defaultProps = {
  client: null,
  bookAppointmentLoading: false,
  bookAppointmentSuccess: false,
  bookAppointmentFail: false,
};

const mapStateToProps = ({ appointmentBooking }) => ({
  dateTime: appointmentBooking.dateTime,
  appointmentType: appointmentBooking.appointmentType,
  reservedAppointmentUlid: appointmentBooking.reservedAppointmentUlid,
  reservationExpireTime: appointmentBooking.reservationExpireTime,
  bookAppointmentLoading: appointmentBooking.bookAppointmentLoading,
  bookAppointmentSuccess: appointmentBooking.bookAppointmentSuccess,
  bookAppointmentFail: appointmentBooking.bookAppointmentFail,
});

const mapDispatch = {
  bookAppointmentDispatch: bookAppointment,
  unreserveAppointmentDispatch: unreserveAppointment,
  setBookingDataDispatch: setBookingData,
};

export default connect(mapStateToProps, mapDispatch)(AppointmentBooking);
