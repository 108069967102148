import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const DataProtection = () => (
  <Row>
    <Col md={{ span: 10, offset: 1 }}>
      <h1>Datenschutzerklärung</h1>
      <p>
        Der Schutz Ihrer Daten ist uns besonders wichtig. Aus diesem Grund haben
        wir etliche Sicherheitsvorkehrungen,
        technische und organisatorische Maßnahmen zum Schutz Ihrer Daten getroffen
        und arbeiten kontinuierlich an deren
        Ausbau und Verbesserung. dubidoc ist so designt, dass es personenbezogenen
        Daten nur in dem Umfang erhebt, wie
        zur Nutzung des Dienstes erforderlich. Sobald weitere personenbezogene
        Daten benötigt werden, erfolgt dies nur
        mit Ihrer ausdrücklichen Einwilligung. Wir halten es für wesentlich, dass
        Sie über den Umgang mit Ihren
        personenbezogenen Daten umfassend informiert sind, damit Sie selbst
        entscheiden können, ob, in welchem Umfang
        und von wem diese Daten verwendet werden.
      </p>

      <p>
        <strong>1. Verantwortliche Stelle</strong>
      </p>

      <p>
        Diese Website und das Leistungsangebot von dubidoc werden betrieben von der
      </p>

      <p>
        Takuta GmbH <br />
        Rellinghauser Straße 111,<br />
        45128 Essen
      </p>

      <p>
        E-Mail: <a href="mailto:support@dubidoc.de">support@dubidoc.de</a><br />
        Web:{" "}
        <a
          href="https://www.dubidoc.de"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://www.dubidoc.de
        </a>
      </p>

      <p>
        Geschäftsführer: Dr. med. Shabnam Fahimi-Weber<br />
        Registergericht: Amtsgericht Essen - HRB 26990
      </p>

      <p>
        <strong>Datenschutzbeauftragter: </strong><br />
        Vossenkuhl Consulting UG (haftungsbeschränkt) <br />
        Herr Dr. Kai Seidensticker <br />
        Otto-Beyer-Straße 8 <br />
        45356 Essen
      </p>

      <p>
        Telefon: <strong>+49 (0) 201 379 280 27</strong><br />
        E-Mail:{" "}
        <a
          href="mailto:datenschutz@vossenkuhl-gruppe.de"
        >
          datenschutz@vossenkuhl-gruppe.de
        </a><br />
        Web:{" "}
        <a
          href="https://www.vossenkuhl-gruppe.de"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://www.vossenkuhl-gruppe.de
        </a>
      </p>

      <p>
        <strong>
          2. Erhebung und Speicherung personenbezogener Daten, Art und Zweck
          ihrer Verwendung sowie Auskunft über etwaige Empfänger oder Kategorien
          von Empfängern der personenbezogenen Daten
        </strong>
      </p>

      <p>
        Soweit auf unseren Seiten
        personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen)
        erhoben werden, erfolgt dies soweit
        möglich stets auf freiwilliger Basis. Die Nutzung der Angebote und Dienste
        ist, soweit möglich, stets ohne Angabe
        personenbezogener Daten möglich. Ihre personenbezogenen Daten werden bei uns
        sicher durch Verschlüsselung
        übertragen. Wir bedienen uns dabei des Codierungssystems SSL (Secure Socket Layer).
        Des Weiteren sichern wir
        unsere Websites und sonstige Systeme durch technische und organisatorische
        Maßnahmen gegen Verlust, Zerstörung,
        Zugriff, Veränderung oder Verbreitung Ihrer Daten durch unbefugte Personen ab.
      </p>

      <p>
        2.1 Verarbeitung von IP-Adresse und Zugriffsdaten <br />
        Beim Besuch unserer Websites werden von uns
        grundsätzlich keine personenbezogenen Daten verarbeitet. Aus technischen
        Gründen werden jedoch beim Besuch von
        Websites bestimmte Informationen mitgeteilt (sog. Protokolldaten). Dabei
        handelt es sich insbesondere um die
        genutzte IP-Adresse, Datum und Uhrzeit des Zugriffs, Browsertyp und -version,
        Betriebssystem und die vorherige
        Website. Auch wenn es sich dabei unter Umständen um personenbezogene Daten
        handeln kann, sind diese Informationen
        für uns keiner bestimmten Person zuordenbar. Rechtsgrundlage der Verarbeitung
        ist unser berechtigtes Interesse an
        der Aufrechterhaltung und Verbesserung der Stabilität und Funktionalität unserer
        Website gemäß Art. 6 Abs. 1 S. 1
        lit. f) DSGVO. Wenn Sie bei der Suche nach dem passenden Arzt die Symptomsuche
        verwenden, wird die Ihnen
        zugewiesene IP-Adresse zwar gespeichert, aber nur buchstaben-, nicht wortbezogen.
        Wir können auch keine
        Rückschlüsse auf Ihre Person ziehen, da wir nicht wissen, welche IP-Adresse
        Ihnen jeweils vom Provider zugewiesen
        wurde. Im Rahmen dieser Abfrage sind auch andere Mechanismen zur Wiedererkennung
        zum Schutz Ihrer Daten
        deaktiviert.<br /><br />
        Wir verwenden Cookies. Cookies sind kleine Datendateien, die von uns oder
        Dritten beim Besuch
        unserer Website auf Ihr Endgerät übertragen werden. Bei jedem erneuten Aufruf
        unserer Website werden diese
        Cookies wieder an uns zurückübermittelt. Hierdurch können wir Sie beispielsweise
        wiedererkennen oder Ihnen
        mithilfe der Informationen in den Cookies die Navigation erleichtern.<br />
        Sie werden auf unseren Webseiten an
        den notwendigen Stellen auf die Verwendung von Cookies und den jeweiligen
        Zweck der Speicherung hingewiesen
        und können dort in die Speicherung von Cookies ausdrücklich einwilligen.
        Weitere Informationen zu den von uns
        verwendeten Cookies finden Sie unter{" "}
        <a href="https://www.dubidoc.de/cookies-info">
          https://www.dubidoc.de/cookies-info
        </a>.<br />
        Ohne Ihre ausdrückliche Einwilligung geben wir die Informationen in den Cookies
        nicht an Dritte weiter. Sie können
        unsere Website auch ohne Cookies betrachten. Internet-Browser sind regelmäßig
        so eingestellt, dass sie
        Cookies akzeptieren. Wenn Sie nicht möchten, dass Ihr Computer wiedererkannt
        wird und unter Umständen
        Nutzungsprofile erstellt werden, können Sie die Verwendung von Cookies ablehnen,
        indem Sie die Einstellungen
        in Ihrem Webbrowser auf „Cookies ablehnen“ ändern. Die jeweilige Vorgehensweise
        finden Sie in der
        Bedienungsanleitung Ihres verwendeten Webbrowsers. Wenn Sie die Verwendung von
        Cookies ablehnen, kann es zu
        Einschränkungen in der Nutzung mancher Bereiche unserer Website kommen. Um die
        Funktionsfähigkeit unserer
        Website zu gewährleisten, genügt es, wenn Sie in den Einstellungen Ihres
        Browsers „temporäre Cookies“
        zulassen.<br /><br /> Die Daten speichern wir grundsätzlich bis zum Ende
        der Laufzeit eines jeweiligen Cookies
        oder bis die Cookies von Ihnen gelöscht werden. Die Verarbeitung erfolgt
        aufgrund unseres Berechtigten
        Interesses gemäß Art. 6 Abs. 1 S. 1 lit. f) DSGVO zum Zweck der laufenden
        Optimierung der Website und der
        Verbesserung der Benutzerfreundlichkeit oder aber aufgrund Ihrer ausdrücklichen
        Einwilligung gemäß Art. 6
        Abs. 1 S. 1 lit. a) DSGVO.
      </p>
      <p>
        2.2 Anmeldung und Anlegen eines Benutzerkontos <br />
        Wenn Sie sich bei dubidoc anmelden und ein Benutzerkonto anlegen, erheben und verarbeiten
        wir in der Regel folgende personenbezogene Daten: Geschlecht, Nachname, Vorname,
        Geburtsdatum, Wohnanschrift, Krankenkassenstatus, Mobilfunknummer, E-Mail-Adresse, vorherige
        Besuche einer bestimmten Praxis. Diese Daten erhalten wir direkt von Ihnen und verwenden sie
        ausschließlich zur Durchführung unserer Dienstleistung der Vereinbarung und Verwaltung von
        Impfterminen sowie der Erinnerung an Termine gemäß Art. 6 Abs. 1 S. 1 lit. b) DSGVO. Nach
        Löschung Ihres Benutzerkontos werden Ihre Daten bei uns gelöscht, sofern Sie nicht
        ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder eine gesetzlich
        erlaubte weitere Datenverwendung von unserer Seite vorbehalten wurde, über die wir Sie
        nachstehend entsprechend informieren.
      </p>
      <p>
        2.3 Nutzung unserer Dienstleistungen<br />
        Je nach Nutzung von dubidoc werden weitere personenbezogene Daten erhoben und verarbeitet.
        Wenn Sie beispielsweise einen Impftermin wünschen, werden diese Informationen von uns
        verwendet. Dabei können wir auch erfahren, an welchem Tag der Termin stattfindet, ob der
        Termin abgesagt wurde oder ob Sie einen weiteren Termin ausmachen. Auch diese Daten werden
        von uns gemäß Art. 6 Abs. 1 S. 1 lit. b) DSGVO ausschließlich dafür verwendet, Ihnen unseren
        Service der Vereinbarung und Verwaltung von Impfterminen sowie der Erinnerung an Termine
        anzubieten. Dies umfasst auch die Weitergabe der Informationen an die von Ihnen ausgewählten
        Ärzte oder Therapeuten.<br />
        <br />
        Wenn Sie über dubidoc Impftermine vereinbaren möchten, werden darüber hinaus weitere
        Kategorien personenbezogener Daten von uns verarbeitet. Diese Daten werden von uns gemäß
        Art. 6 Abs. 1 S. 1 lit. c) DSGVO aufgrund rechtlicher Verpflichtungen aus der Verordnung zum
        Anspruch auf Schutzimpfung gegen das Coronavirus SARS-CoV-2 (CoronaImpfV) oder gemäß Art. 6
        Abs. 1 S. 1 lit. a) DSGVO bzw. Art. 9 Abs. 2 S. 1 lit. h) DSGVO aufgrund Ihrer ausdrücklich
        erteilten Einwilligung und ausschließlich zum Zweck der Vereinbarung von Impfterminen
        verarbeitet.<br />
        <br />
        Weitere personenbezogene Daten werden nicht erhoben und verarbeitet. Sollten wir im Laufe
        des Betriebs oder aufgrund von Funktionserweiterungen weitere personenbezogene Daten zum
        Zwecke der optimalen und vertragsgemäßen Erbringung unserer Dienstleistung verwenden wollen,
        werden wir Sie rechtzeitig darüber informieren und Sie um Ihre Einwilligung zur
        Datenverwendung gemäß Art. 6 Abs. 1 S. 1 lit. a) DSGVO bitten.
      </p>

      <p>
        2.4 Besondere Kategorien personenbezogene Daten<br />
        Alle zu Ihrer Person gespeicherten Daten werden selbstverständlich vertraulich behandelt und
        sind gegen Zugriffe Dritter besonders gesichert. Bei bestimmten personenbezogenen Daten kann
        es sich um besondere Kategorien personenbezogener Daten gemäß Art. 9 Abs. 1 DSGVO handeln,
        die einem erhöhten Schutz unterliegen. Dazu gehören insbesondere Gesundheitsdaten. Es ist
        uns sehr wichtig, dass dubidoc zwar keinerlei Informationen über Ihren Gesundheitszustand,
        die Behandlung oder sonstige Daten erhält, aber nach dem Gesetz kann schon die Tatsache,
        dass Sie einen Impftermin vereinbart haben ein Gesundheitsdatum im Sinne des
        Datenschutzrechts sein. Deswegen werden wir solche Informationen nur mit Ihrer
        ausdrücklichen Einwilligung gemäß Art. 9 Abs. 2 S. 1 lit. a) DSGVO und nur zum Zwecke der
        Durchführung unserer Dienstleistung verwenden.
      </p>

      <p>
        2.5 Nutzung des Kontaktformulars<br />
        Bei Nutzung unseres Kontaktformulars ist die Angabe einer gültigen E-Mail-Adresse
        erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu
        können. Weitere Angaben können freiwillig getätigt werden. Die Datenverarbeitung zum Zwecke
        der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a) DSGVO auf Grundlage
        Ihrer freiwillig erteilten Einwilligung. Die für die Benutzung des Kontaktformulars von uns
        erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage
        automatisch, spätestens nach 2 Monaten gelöscht.<br />
        <br />
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
        E-Mail) dennoch Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
        Zugriff durch Dritte ist nicht möglich.
      </p>

      <p>
        2.6 Newsletter<br />
        Dubidoc bietet Ihnen zur regelmäßigen Information über Neuigkeiten und weiter Entwicklungen
        einen Newsletter an, den Sie abonnieren können. Zum Zwecke des Versands unseres Newsletters
        verarbeiten wir Ihre personenbezogenen Daten (Name, Vorname, E-Mail-Adresse). Unser
        Newsletter wird Ihnen regelmäßig per Mail zugesandt, wenn Sie in die Verarbeitung Ihrer
        personenbezogenen Daten und den Empfang unseres Newsletters gem. Art. 6 S. 1 Abs. 1 lit. a)
        DSGVO aktiv eingewilligt haben. Sie können dem Empfang unseres Newsletters jederzeit ohne
        Angabe von Gründen und ohne, dass Ihnen daraus Nachteile in der Nutzung unserer
        Dienstleistungen entstehen formlos (z. B. per E-Mail) widersprechen.
      </p>

      <p>
        <strong>2.7 Sentry</strong><br />
        Wir verwenden den Dienst Sentry (Functional Software Inc., 132 Hawthorne
        Street, San Francisco, California 94107), um die technische Stabilität unseres Dienstes
        durch Überwachung der
        Systemstabilität und Ermittlung von Codefehlern zu verbessern. Sentry dient alleine
        diesen Zielen und wertet
        keine Daten zu Werbezwecken aus. Die Daten der Nutzer*innen, wie z.B. Angaben zum
        Gerät oder Fehlerzeitpunkt
        werden anonym erhoben und nicht personenbezogen genutzt sowie anschließend gelöscht.
        Weitere Informationen
        hierzu finden Sie in der Datenschutzerklärung von Sentry:{" "}
        <a
          href="https://sentry.io/privacy/"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://sentry.io/privacy/
        </a>.
        Wir haben ein berechtigtes Interesse nach Art. 6 Abs. 1 S. 1 lit. f) DSGVO an der Nutzung
        von Sentry, welches in der
        nutzergerechten Gestaltung unserer Angebote begründet liegt.
      </p>
      <p>
        <strong>3. Dauer der Speicherung &amp; Löschung personenbezogener Daten</strong><br />
        Die unter Ziffer 2
        beschriebenen personenbezogenen Daten werden von uns grundsätzlich dann gelöscht,
        wenn der Zweck, zu dem wir die
        Daten erhoben haben, weggefallen ist oder wenn sich die Speicherung der personenbezogenen
        Daten nach geltendem
        Recht als unzulässig erweist.<br />
        An die Stelle einer Datenlöschung tritt die Einschränkung der Verarbeitung
        personenbezogener Daten, soweit <br />
        - einer Löschung gesetzliche, satzungsmäßige oder vertragliche
        Aufbewahrungsfristen entgegenstehen (vgl. oben Ziffer 1) <br />
        oder<br /> - Grund zu der Annahme besteht, dass
        durch eine Löschung schutzwürdige Interessen des Betroffenen beeinträchtigt würden<br />
        oder<br />
        - eine
        Löschung wegen der besonderen Art der Speicherung nicht oder nur mit unverhältnismäßig
        hohem Aufwand möglich ist<br />
        oder<br />
        - der Seitennutzer bzw. Kunde die Richtigkeit der zu ihm gespeicherten
        personenbezogenen Daten bestreitet und sich weder die Richtigkeit noch die Unrichtigkeit
        feststellen lässt.
      </p>
      <p>
        <strong>4. Berichtigung von personenbezogenen Daten</strong><br />
        Sie können von uns jederzeit die
        Berichtigung diesseitig zu Ihrer Person gespeicherter Daten verlangen,
        wenn diese unrichtig sind (Art. 16 DSGVO).<br /><br />
        Sollten Sie eine solche Berichtigung wünschen, bitten wir Sie darum, einen
        diesbezüglichen Antrag
        schriftlich oder per E-Mail an{" "}
        <a href="mailto:datenschutz@dubidoc.de">
          datenschutz@dubidoc.de
        </a> zu richten.
      </p>
      <p>
        <strong>5. Widerspruchsrecht</strong><br />
        Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
        Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f) DSGVO verarbeitet werden, haben Sie das
        Recht, gemäß Art. 21 DSGVO
        Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit
        dafür Gründe vorliegen, die
        sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung
        reichtet. Im letzteren
        Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation
        von uns umgesetzt wird.<br />
        Für den Fall, dass Sie uns Ihre Einwilligung für die Verwendung von personenbezogenen Daten
        erteilen,
        können Sie diese jederzeit und ohne Angabe von Gründen mit Wirkung für die Zukunft – auch
        bezogen auf einzelne
        personenbezogene Daten – widerrufen. Ihr Widerruf hat unter Umständen zur Folge, dass wir
        bestimmte
        Dienstleistungen nicht oder nur noch eingeschränkt erbringen können. Schreiben Sie uns
        einfach eine
        E-Mail an <a href="mailto:datenschutz@dubidoc.de">datenschutz@dubidoc.de</a>.
      </p>
      <p>
        <strong>6. Betroffenenrechte</strong><br />
        Gemäß Art. 15 DSGVO haben Sie das Recht Auskunft über Ihre von uns
        verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft
        über die Verarbeitungszwecke,
        die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber
        denen Ihre Daten offengelegt
        wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf
        Berichtigung, Löschung,
        Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts,
        die Herkunft ihrer Daten,
        sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer
        automatisierten Entscheidungsfindung
        einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten
        verlangen.<br /><br />
        Gemäß
        Art. 18 DSGVO können Sie die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
        verlangen, soweit die
        Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie
        aber deren Löschung
        ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung,
        Ausübung oder Verteidigung
        von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die
        Verarbeitung eingelegt haben.<br /><br />
        Gemäß Art. 20 DSGVO können Sie verlangen, Ihre personenbezogenen Daten, die Sie uns
        bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format
        zu erhalten oder die
        Übermittlung an einen anderen Verantwortlichen verlangen.<br /><br /> Gemäß Art. 7 Abs. 3
        DSGVO können Sie Ihre
        einmal erteilte Einwilligung jederzeit gegenüber uns widerrufen. Dies hat zur Folge,
        dass wir die
        Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr
        fortführen dürfen.<br /><br />
        Gemäß Art. 77 DSGVO können Sie sich bei einer Aufsichtsbehörde beschweren. In der Regel
        können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder
        Arbeitsplatzes oder unseres Firmensitzes wenden.
      </p>

      <p>
        <strong>7. Änderungsvorbehalt hinsichtlich der Datenschutzerklärung</strong><br />
        Um den jeweils geltenden
        rechtlichen Bestimmungen in Ihrem Interesse und dem unseren gerecht werden zu können,
        behalten wir uns Änderungen
        bzw. Aktualisierungen der vorliegenden Datenschutzerklärung ausdrücklich vor. Aus diesem
        Grunde legen wir Ihnen
        unverbindlich nahe, die auf unserer Website bereit gehaltene Datenschutzerklärung in
        regelmäßigen Abständen in
        Augenschein zu nehmen und sorgfältig zu lesen. Bei Fragen oder Anmerkungen zum Datenschutz
        helfen wir Ihnen gerne
        weiter unter <a href="mailto:datenschutz@dubidoc.de">datenschutz@dubidoc.de</a>.
      </p>

      <p>
        <strong>8. Sonstige Hinweise</strong><br />
        Wir bemühen uns, die personenbezogenen Daten im Rahmen des
        technisch wie organisatorisch Möglichen vor Zugriffen durch Dritte zu schützen. Eine
        vollständige Datensicherheit
        kann bei der Kommunikation mittels elektronischer Post (E-Mails) von uns nicht gewährleistet
        werden, weshalb wir
        Ihnen nahelegen, uns vertrauliche Informationen auf dem Postweg zukommen zu lassen.
      </p>

    </Col>
  </Row>
);

export default DataProtection;
