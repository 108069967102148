import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { Formik } from "formik";
import * as yup from "yup";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import Panel from "../../../components/Panel/Panel";
import IconButton from "../../../components/IconButton/IconButton";
import { edit, fetch, reset } from "../../../state/features/BlockingTimeSlice";
import FormRowText from "../../../components/FormRow/FormRowText";
import FormRowTime from "../../../components/FormRow/FormRowTime";
import Loading from "../../../components/Loading/Loading";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { notificationService } from "../../../services/NotificationService";

const BlockingTimeEdit = (props) => {
  const {
    fetchDispatch,
    editDispatch,
    resetDispatch,
    blockingTime,
    loading,
    editLoading,
    editSuccess,
    editFail,
    editSet,
  } = props;

  const {
    ulid,
  } = useParams();

  const intl = useIntl();

  const navigate = useNavigate();

  useEffect(() => {
    resetDispatch();
    fetchDispatch(ulid);
  }, [resetDispatch, fetchDispatch, ulid]);

  const validationSchema = yup.object().shape({
    title: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" }))
      .max(255, intl.formatMessage({ id: "form.error.max_length" }, { value: 255 })),
    dateFrom: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    dateTo: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    days: yup.array()
      .min(1, intl.formatMessage({ id: "form.error.required" })),
    startTime: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
    endTime: yup.string()
      .required(intl.formatMessage({ id: "form.error.required" })),
  });

  if (loading || blockingTime === null) {
    return <Loading />;
  }

  return (
    <>
      <BreadCrumbs
        crumbs={[
          { to: "/portal/dashboard", name: "client_navigation.dashboard" },
          { to: "/portal/working-time", name: "client_navigation.working_time_calendar" },
          { to: `/portal/blocking-time/edit/${blockingTime.ulid}`, name: "client_navigation.blocking_time_edit" },
        ]}
      />

      <h3 className="mb-16">Sperrzeit bearbeiten</h3>

      <Formik
        initialValues={{
          title: blockingTime.title,
          startTime: moment(blockingTime.startTime).toISOString(),
          endTime: moment(blockingTime.endTime).toISOString(),
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          const {
            startTime,
            endTime,
          } = values;

          if (moment(startTime).isSameOrAfter(endTime)) {
            errors.endTime = intl.formatMessage({ id: "form.error.time_after" });
          }

          return errors;
        }}
        onSubmit={(values) => {
          editDispatch(blockingTime.ulid, values, editSet)
            .then((action) => {
              if (action.type.endsWith("SUCCESS")) {
                notificationService.success("Sperrzeit(en) erfolgreich gespeichert.");
                navigate("/portal/working-time", { replace: true });
              }
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Die Bezeichnung wird nicht öffentlich angezeigt.
              </Col>
              <Col md={9}>
                <Panel headline="Name der Sperrzeit">
                  <FormRowText
                    id="title"
                    name="title"
                    label="Bezeichnung*"
                    className="mb-24"
                    defaultValue={values.title}
                    errors={errors.title}
                    touched={touched.title}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={3} className="pt-105">
                <strong>Erläuterung:</strong><br />
                Wählen die Startzeit und die Endzeit der geplanten Sperrzeit.
              </Col>
              <Col md={9}>
                <Panel headline="An welchen Stunden soll die Sperrzeit gelten?">
                  <FormRowTime
                    id="startTime"
                    name="startTime"
                    label="Beginn*"
                    className="mb-24"
                    defaultValue={values.startTime}
                    errors={errors.startTime}
                    touched={touched.startTime}
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setFieldValue("startTime", value);
                    }}
                  />

                  <FormRowTime
                    id="endTime"
                    name="endTime"
                    label="Ende*"
                    defaultValue={values.endTime}
                    errors={errors.endTime}
                    touched={touched.endTime}
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setFieldValue("endTime", value);
                    }}
                  />
                </Panel>
              </Col>
            </Row>

            <Row className="mb-24">
              <Col md={{ span: 9, offset: 3 }}>
                <Panel small className="text-end">
                  <IconButton
                    disabled={editLoading}
                    type="submit"
                  >
                    Speichern
                    {editLoading && (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )}
                    {editSuccess && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    {editFail && (
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    )}
                  </IconButton>
                </Panel>
              </Col>
            </Row>

          </form>
        )}
      </Formik>
    </>
  );
};

BlockingTimeEdit.propTypes = {
  fetchDispatch: PropTypes.func.isRequired,
  editDispatch: PropTypes.func.isRequired,
  resetDispatch: PropTypes.func.isRequired,
  blockingTime: PropTypes.oneOfType([PropTypes.object]),
  loading: PropTypes.bool,
  editLoading: PropTypes.bool,
  editSuccess: PropTypes.bool,
  editFail: PropTypes.bool,
  editSet: PropTypes.bool,
};

BlockingTimeEdit.defaultProps = {
  blockingTime: null,
  loading: false,
  editLoading: false,
  editSuccess: false,
  editFail: false,
  editSet: false,
};

const mapStateToProps = ({ blockingTime }) => ({
  blockingTime: blockingTime.blockingTime,
  loading: blockingTime.loading,
  editLoading: blockingTime.editLoading,
  editSuccess: blockingTime.editSuccess,
  editFail: blockingTime.editFail,
});

const mapDispatch = {
  fetchDispatch: fetch,
  editDispatch: edit,
  resetDispatch: reset,
};

export default connect(mapStateToProps, mapDispatch)(BlockingTimeEdit);
