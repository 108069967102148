import React, { memo } from "react";

import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import FormRow from "./FormRow";

const FormRowSwitch = (props) => {
  const {
    id,
    label,
    defaultValue,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
    hint,
    className,
    size,
    labelSize,
    ...otherProps
  } = props;

  return (
    <FormRow
      id={id}
      label={label}
      errors={errors}
      touched={touched}
      hint={hint}
      size={size}
      labelSize={labelSize}
      className={className}
    >
      <div className="d-flex align-items-center justify-content-end h-100">
        <Form.Check
          type="switch"
          name={id}
          defaultChecked={defaultValue}
          onChange={(event) => {
            setFieldValue(id, event.target.checked);
          }}
          {...otherProps}
        />
      </div>
    </FormRow>
  );
};

FormRowSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.any]).isRequired,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  hint: PropTypes.string,
  className: PropTypes.string,
  labelSize: PropTypes.number,
  size: PropTypes.number,
};

FormRowSwitch.defaultProps = {
  errors: null,
  touched: false,
  hint: null,
  className: "",
  labelSize: 4,
  size: 7,
};

export default memo(FormRowSwitch);
